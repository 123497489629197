.loadjs {
	overflow: hidden;
}

img {
	width: 100%;
	height: auto;
	transition: opacity 0.5s;
	display: block;
}

.animate {
	opacity: 0;
}

a {
	text-decoration: none;
}

.wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	position: relative;
	padding: 0;
	z-index: 2;
}

.sec__wrapper {
	flex-direction: column;
}

/* !Section */
.section {
	padding: 5em 0;
}

/* !Container */
.container {
	max-width: 100%;
	padding: 0 9%;
}

.img__observer__one .element {
	overflow: hidden;
	clip-path: inset(0 100% 0 0);
}

.img__observer__one.active .element {
	transition: clip-path 2s;
	transition-delay: 0.5s;
	clip-path: inset(0 0 0 0);
}

.img__observer__two .element {
	overflow: hidden;
	clip-path: inset(0 0 0 100%);
}

.img__observer__two.active .element {
	transition: clip-path 2s;
	transition-delay: 0.75s;
	clip-path: inset(0 0 0 0);
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-hidden .mbl-view {
	display: none;
}

.text-center {
	text-align: center;
}

.m-auto {
	margin: 0 auto;
}

body {
	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow-y: scroll;
	cursor: none !important;
}



body::-webkit-scrollbar {
	display: none;
}

.clippath-item {
	clip-path: inset(0 100% 0 0);
}





.g-recaptcha {
	display: none;
}

.generic-navigation-wrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 8em;
	height: 3.75em;
}

.generic-navigation-wrapper .swiper-button-next,
.generic-navigation-wrapper .swiper-button-prev {
	width: 3.75em;
	height: 3.75em;
	background: #033780;
	border: 1px solid #033780;
	border-radius: 100%;
	top: 0;
	margin-top: 0;
	transition: 0.25s;
}

.generic-navigation-wrapper .swiper-button-next img,
.generic-navigation-wrapper .swiper-button-next svg,
.generic-navigation-wrapper .swiper-button-prev img,
.generic-navigation-wrapper .swiper-button-prev svg {
	width: 1.5em;
}

.generic-navigation-wrapper .swiper-button-next::after,
.generic-navigation-wrapper .swiper-button-prev::after {
	display: none;
}

.generic-navigation-wrapper .swiper-button-next:hover,
.generic-navigation-wrapper .swiper-button-prev:hover {
	opacity: 0.8;
	transition: 0.25s;
}

.generic-navigation-wrapper.large-btn .swiper-button-next,
.generic-navigation-wrapper.large-btn .swiper-button-prev {
	width: 5.9375em;
	height: 5.9375em;
	background: 0 0;
	transition: 0.25s;
}

.generic-navigation-wrapper.large-btn .swiper-button-next img,
.generic-navigation-wrapper.large-btn .swiper-button-next svg,
.generic-navigation-wrapper.large-btn .swiper-button-prev img,
.generic-navigation-wrapper.large-btn .swiper-button-prev svg {
	width: 0.4375em;
}

.generic-navigation-wrapper.large-btn .swiper-button-next img path,
.generic-navigation-wrapper.large-btn .swiper-button-next svg path,
.generic-navigation-wrapper.large-btn .swiper-button-prev img path,
.generic-navigation-wrapper.large-btn .swiper-button-prev svg path {
	transition: 0.25s;
}

.generic-navigation-wrapper.large-btn .swiper-button-next:hover,
.generic-navigation-wrapper.large-btn .swiper-button-prev:hover {
	background: #033780;
	border-color: transparent;
	transition: 0.25s;
}

.generic-navigation-wrapper.large-btn .swiper-button-next:hover img path,
.generic-navigation-wrapper.large-btn .swiper-button-next:hover svg path,
.generic-navigation-wrapper.large-btn .swiper-button-prev:hover img path,
.generic-navigation-wrapper.large-btn .swiper-button-prev:hover svg path {
	stroke: #fff;
	transition: 0.25s;
}

.generic-navigation-wrapper.large-btn .swiper-button-next.swiper-button-disabled,
.generic-navigation-wrapper.large-btn .swiper-button-prev.swiper-button-disabled {
	opacity: 0;
	visibility: hidden;
	transition: 0.25s;
}



.generic-navigation-wrapper.white-bg .swiper-button-next,
.generic-navigation-wrapper.white-bg .swiper-button-prev {
	background: #fff;
	border: 1px solid #fff;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
	transition: 0.25s;
}


.generic-navigation-wrapper.white-bg .swiper-button-next svg path,
.generic-navigation-wrapper.white-bg .swiper-button-prev svg path {
	fill: #033780;
	transition: 0.25s;
}

.generic-navigation-wrapper.white-bg .swiper-button-next:hover,
.generic-navigation-wrapper.white-bg .swiper-button-prev:hover {
	background: #033780;
	border-color: transparent;
	transition: 0.25s;
}

.generic-navigation-wrapper.white-bg .swiper-button-next:hover img path,
.generic-navigation-wrapper.white-bg .swiper-button-next:hover svg path,
.generic-navigation-wrapper.white-bg .swiper-button-prev:hover img path,
.generic-navigation-wrapper.white-bg .swiper-button-prev:hover svg path {
	stroke: #fff;
	fill: #fff;
	transition: 0.25s;
}

.generic-navigation-wrapper .swiper-button-next {
	right: 0;
}

.generic-navigation-wrapper .swiper-button-next img,
.generic-navigation-wrapper .swiper-button-next svg {
	transform: rotate(180deg);
}

.generic-navigation-wrapper .swiper-button-prev {
	left: 0;
}

.p-lr-4 {
	padding: 0 4em;
}



@-moz-keyframes marquee {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes scroll {
	100% {
		transform: translateX(-1672px);
	}
}

.d-flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.f-coloum {
	flex-direction: column;
}

.justify-content-center {
	justify-content: center;
}

.reversecol {
	margin-top: 0 !important;
}

.reversecol .twocol-row .first-col {
	order: 1;
}



button {
	font-family: Montserrat, sans-serif;
	-webkit-appearance: button;
	width: auto;
	border: 0;
	box-shadow: none;
	background: 0 0;
	outline: 0;
	background: 0 0;
	border-radius: 0;
	cursor: pointer;
}

button:focus {
	outline: 0;
}

.dg-a-wrap {
	display: flex;
	font-size: 1.12rem;
}

.d-flex-center {
	justify-content: center;
}



.uline {
	color: #fff;
	position: relative;
	text-decoration: none;
}

.uline::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0.75em;
	width: 100%;
	height: 1px;
	background: #fff;
	transform: scaleX(1);
	transition: transform 0.5s ease;
}

.uline.active::before,
.uline:hover::before {
	transition: transform 0.5s ease;
	transform: scaleX(0);
}

.uline.black-col {
	color: #000;
}

.uline.black-col::before {
	background: #000;
}

.uline.yellow-col {
	color: #033780;
}

.uline.yellow-col::before {
	background: #033780;
}

.dg-line {
	color: #fff;
	position: relative;
	text-decoration: none;
}

.dg-line::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -0.3em;
	width: 100%;
	height: 2px;
	background: #fff;
	transform: scaleX(0);
	transition: transform 0.5s ease;
}

.dg-line.active::before,
.dg-line:hover::before {
	transition: transform 0.5s ease;
	transform: scaleX(1);
}

.dg-line.primary-col {
	color: #033780;
}

.dg-line.primary-col::before {
	background: #033780;
}

.dg-line.black-col {
	color: #000;
}

.dg-line.black-col::before {
	background: #000;
}




.fill-btn {
	background: #033780;
	padding: 1.125em 4.8125em;
	color: #fff;
	border-radius: 18.75em;
	border: 1px solid #033780;
	transition: 0.5s;
}


.fill-btn:hover {
	color: #033780;
	background: 0 0;
}










.generic--head__subhead.left-element {
	position: relative;
	padding: 0 0 0 2.8125em;
}

.generic--head__subhead.left-element::before {
	content: "";
	position: absolute;
	width: 1.5em;
	height: 1px;
	left: 0;
	top: 50%;
	background: #fff;
}


.generic--head__subhead.left-element.primary-col::before {
	background: #033780;
}

.generic--head.center-head {
	text-align: center;
	margin: 0 auto;
}

/* !Font Weights */
.fw-400 {
	font-weight: 400;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

.capitalize {
	text-transform: capitalize;
}

.letter-spacing {
	letter-spacing: -0.045em;
}

.animate {
	opacity: 0;
}

.animate.fadein-Left {
	transform: translate(50px, 0);
}

.animate.fadein-Right {
	transform: translate(-50px, 0);
}

.animate.fadein-Up {
	transform: translate(0, 50px);
}

.animate.fadein-Up-Fast {
	transform: translate(0, 50px);
}

.animate.fadein-Down {
	transform: translate(0, -50px);
}



@keyframes anim {
	to {
		transform: translate(0, 0);
		opacity: 1;
	}
}

.twocol-row.justify-space {
	justify-content: space-between;
}

.twocol-row.align-center {
	align-items: center;
}

/* !Columns */
.col-100 {
	width: 100%;
}

.col-90 {
	width: 90%;
}

.col-80 {
	width: 80%;
}


.col-75 {
	width: 75%;
}


.col-70 {
	width: 70%;
}

.col-65 {
	width: 65%;
}


.col-67 {
	width: 67%;
}



.col-60 {
	width: 60%;
}



.col-57 {
	width: 57%;
}



.col-59 {
	width: 59%;
}



.col-56 {
	width: 56%;
}



.col-55 {
	width: 55%;
}



.col-53 {
	width: 53%;
}



.col-50 {
	width: 50%;
}


.col-45 {
	width: 45%;
}

.col-44 {
	width: 44%;
}


.col-42 {
	width: 42%;
}


.col-48 {
	width: 48%;
}


.col-47 {
	width: 47%;
}


.col-43 {
	width: 43%;
}


.col-40 {
	width: 40%;
}


.col-37 {
	width: 37%;
}

.col-36 {
	width: 36%;
}


.col-35 {
	width: 35%;
}


.col-33 {
	width: 33%;
}




.col-31 {
	width: 31%;
}

.col-30 {
	width: 30%;
}


.col-25 {
	width: 25%;
}


.col-18 {
	width: 18%;
}


.col-20 {
	width: 20%;
}


.col-10 {
	width: 10%;
}


.col-54 {
	width: 54%;
}

.col-46 {
	width: 46%;
}


.col-52 {
	width: 52%;
}


/* !Navigation Start */
.navigation-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.navigation-wrapper .dg-social-wrapper {
	display: flex;
}

.dg-flotaing-icons {
	display: flex;
	position: fixed;
	bottom: 3rem;
	left: 2rem;
	flex-direction: column;
	z-index: 9;
}

.dg-flotaing-icons .img-box {
	margin-bottom: 1rem;
	padding: 0.5rem;
	background: #033780;
	border-radius: 100%;
	width: 2rem;
	height: 2rem;
	transition: 800ms ease-in-out;
}

.dg-flotaing-icons .img-box a {
	display: flex;
	height: 100%;
	width: 100%;
}

.dg-flotaing-icons .img-box a svg {
	height: 100%;
	width: 100%;
	display: flex;
}

.dg-flotaing-icons .img-box a img {
	height: 100%;
	width: 100%;
	display: flex;
}

.whatsapp:hover {
	background: #25d366;
}

.phone:hover {
	background: #17aef3;
}

.email:hover {
	background: #dcdcdc;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap a {
	position: relative;
	width: 4em;
	background: #033780;
	display: flex;
	align-items: center;
	justify-content: center;
}



.navigation-wrapper .dg-social-wrapper .dg-a-wrap a::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background: #fff;
	left: auto;
	transition: 0.5s;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap a img {
	width: 1.625em;
	z-index: 1;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap a svg {
	width: 1.625em;
	z-index: 1;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap a svg path {
	transition: 0.5s;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap a svg path {
	fill: #ffffff;
	transition: 0.5s;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap a:hover svg path {
	fill: #033780;
	transition: 0.5s;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap a:hover::before {
	left: 0;
	width: 100%;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap.project-a a {
	min-width: 13.875em;
	padding: 0 1.75em;
	background: #fff;
	color: #033780;
	font-size: 1em;
	font-weight: 700;
	transition: 0.5s;
	cursor: pointer;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap.project-a a span {
	width: 22ch;
	white-space: nowrap;
	overflow: hidden;
	z-index: 1;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap.project-a a::before {
	background: #033780;
}

.navigation-wrapper .dg-social-wrapper .dg-a-wrap.project-a a:hover {
	color: #fff;
	transition: 0.5s;
	cursor: pointer;
}

.navigation-wrapper .dg-social-wrapper.mob-social-wrapper {
	display: none;
	padding: 0;
}

.navigation-wrapper .dg-social-wrapper.mob-social-wrapper .dg-a-wrap {
	height: 100%;
	border-right: 1px solid #033780;
}

@keyframes typing {
	from {
		width: 0;
	}
}

@keyframes blink {
	50% {
		border-color: transparent;
	}
}

.navigation {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.nav-menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	max-width: 100%;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.nav-menu-item {
	height: 100%;
	display: flex;
	align-items: center;
	margin: 0 2rem 0 1rem;
}

.nav-menu-item.active .nav-menu-item-link {
	color: #033780;
}

.nav-menu-item.service-menu-dropdown .nav-menu-item-link::before {
	content: "";
	position: absolute;
	top: 50%;
	right: -1em;
	padding: 0.15em;
	transition: 0.5s;
	display: none;
	border-style: solid;
	border-color: #033780;
	border-width: 2px 2px 0 0;
	transform-origin: 100% 20%;
	transform: rotate(135deg);
	transition: 0.4s;
}

.nav-menu-item.service-menu-dropdown.dropdown-open .nav-menu-item-link {
	color: #033780;
	transition: 0.4s;
}

.nav-menu-item.service-menu-dropdown.dropdown-open .nav-menu-item-link::before {
	top: 35%;
	transform: rotate(-45deg);
	transition: 0.4s;
}

.nav-menu-item .service-menu-dropdown-menu {
	/* display: none; */
	position: absolute;
	width: 100%;
	top: 100%;
	left: 0;
	text-align: left;
	margin: 0;
	list-style-type: none;
	z-index: 2;
	background: linear-gradient(57.3deg, #4f1180 28.3%, #7b22c0 88.2%);
	overflow: hidden;
}

.nav-menu-item-link {
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-weight: 500;
	font-family: Montserrat, sans-serif;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	transition: 0.4s;
	padding: 0.75rem 0 0.75rem 0;
}


.nav-menu-item-link.active,
.nav-menu-item-link:hover {
	color: #033780;
	transition: 0.4s;
}

.nav-button {
	display: none;
	position: relative;
	height: 3.5em;
	width: 3.5em;
	padding: 0;
	background: 0 0;
	transition: all 250ms ease-out;
	z-index: 99;
	background: #fff;
	cursor: pointer;
}



.nav-button::after,
.nav-button::before {
	content: "";
	width: 2em;
	height: 3px;
	position: absolute;
	background: #033780;
	transition: all 250ms ease-out;
	will-change: transform;
	left: 50%;
}

.nav-button::before {
	transform: translate(-50%, -50%);
}

.nav-button::after {
	transform: translate(-50%, 6px);
}

.nav-button.active::before {
	transform: translate(-50%, 0) rotate(45deg);
}

.nav-button.active::after {
	transform: translate(-50%, 0) rotate(-45deg);
}




@media (max-width: 991px) {
	.nav-menu-item .service-menu-dropdown-menu {
		position: relative;
		padding: 0;
		background: 0 0;
	}

	.nav-button {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.nav-menu-item.service-menu-dropdown .nav-menu-item-link::before {
		display: inline-block;
	}

	.nav-menu-item.service-menu-dropdown .nav-menu-item-link {
		position: relative;
	}

	.navigation-wrapper .dg-social-wrapper .dg-a-wrap.project-a a {
		min-width: unset;
		padding: 0 1em;
		width: 8.25em;
	}

	.navigation-wrapper .dg-social-wrapper.mob-social-wrapper {
		display: flex;
		align-items: center;
	}

	.navigation {
		display: none;
	}

	.nav-menu {
		padding: 5em 1em 2em 1em;
		width: 100%;
	}

	.nav-menu-item {
		padding: 0 0 1.5em 0;
		margin: 0;
	}

	.nav-menu-item {
		width: 100%;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
	}
}

@media (max-width: 1024px) {
	.nav-menu-item-link {
		font-size: 1rem;
	}

	.nav-menu-item {
		margin: 0 2em 0 0;
	}
}

@media (max-width: 1200px) {
	.navigation-wrapper .dg-social-wrapper .dg-a-wrap a {
		width: 3.5em;
	}
}

/* !Navigation End */

.menu-slider {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.menu-slider .dropMenu-thumb-wrap {
	width: 30%;
	padding: 3em 4em;
}

@media (max-width: 991px) {
	.menu-slider .dropMenu-thumb-wrap {
		width: 100%;
		padding: 0;
	}
}

.menu-slider .dropMenu-thumb-wrap .dropMenu-thumb {
	list-style-type: none;
	padding: 0;
	max-height: 22em;
	overflow-y: scroll;
}

.menu-slider .dropMenu-thumb-wrap .dropMenu-thumb .dropMenu-thumb-slide {
	margin: 0.5em 0.5em 0.5em 0;
}

.menu-slider .dropMenu-thumb-wrap .dropMenu-thumb .dropMenu-thumb-slide .silde-header {
	color: #fff;
	font-size: 0.675em;
	font-weight: 600;
	text-transform: uppercase;
	padding: 1em 1em;
	border-radius: 5.625em;
	display: flex;
	align-items: center;
	line-height: 1;
	transition: 0.4s;
	cursor: pointer;
}

@media (max-width: 991px) {
	.menu-slider .dropMenu-thumb-wrap .dropMenu-thumb .dropMenu-thumb-slide .silde-header {
		font-size: 1.25em;
		padding: 0.75em 1em;
	}
}

@media (max-width: 576px) {
	.menu-slider .dropMenu-thumb-wrap .dropMenu-thumb .dropMenu-thumb-slide .silde-header {
		font-size: 1em;
		border-radius: 0;
	}
}

.menu-slider .dropMenu-thumb-wrap .dropMenu-thumb .dropMenu-thumb-slide.active .silde-header {
	background: #033780;
	transition: 0.4s;
}

.menu-slider .dropMenu-gallery-wrap {
	width: 70%;
	height: auto;
	border-left: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 991px) {
	.menu-slider .dropMenu-gallery-wrap {
		width: 100%;
		border-left: none;
	}
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery {
	list-style-type: none;
	padding: 0;
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .silde-header {
	color: #fff;
	font-size: 2em;
	font-weight: 600;
	letter-spacing: -0.03em;
	text-transform: uppercase;
	padding: 1.38em 1.82em 0.875em 1.2em;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 991px) {
	.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .silde-header {
		color: #033780;
		font-size: 1.5em;
		border-bottom: none;
		padding: 0.5em 0;
	}
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 3em 2.2em;
}

@media (max-width: 1024px) {
	.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper {
		padding: 3em;
	}
}

@media (max-width: 991px) {
	.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper {
		padding: 1em 0;
	}
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item {
	width: 33.33%;
	margin: 0 0 2em 0;
}

@media (max-width: 991px) {
	.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item {
		width: 100%;
		margin: 0 0 1em 0;
	}
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item a {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	color: #fff;
	font-size: 0.675em;
	font-weight: 600;
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item a {
		font-size: 0.75em;
	}
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item a.active {
	color: #033780;
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item-header {
	position: relative;
	display: flex;
	align-items: center;
	text-decoration: none;
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item-header::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -0.3em;
	width: 100%;
	height: 2px;
	background: #fff;
	transform: scaleX(0);
	transition: transform 0.5s ease;
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item-header:hover::before {
	transition: transform 0.5s ease;
	transform: scaleX(1);
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item-img {
	width: 1.5em;
	height: 1.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: -0.9375em 0.9375em 1.4375em rgba(56, 1, 89, 0.5);
	border-radius: 0.375em;
	margin: 0 1.8125em 0 0;
}

.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide .list-wrapper .list-item-img img {
	width: 60%;
	height: 60%;
}

@media (max-width: 991px) {
	.menu-slider .dropMenu-gallery-wrap .dropMenu-gallery-slide {
		padding: 1em 1em 1em 2em;
	}
}

.dropMenu-thumb::-webkit-scrollbar {
	width: 0;
	border-radius: 5px;
}

.mobNav {
	display: none;
}

@media (max-width: 991px) {
	.mobNav {
		position: fixed;
		right: -100%;
		width: 50vw;
		height: 100vh;
		top: 0;
		transition: 0.7s;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		z-index: 7;
		overflow-y: scroll;
	}

	.mobNav.open {
		right: 0;
	}
}

@media (max-width: 576px) {
	.mobNav {
		width: 100%;
	}
}

.mobNav__menu {
	padding: 5em 1em 2em 1em;
	width: 100%;
	list-style-type: none;
	margin: 0;
}

.mobNav__menu--item {
	position: relative;
	padding: 0.75em 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.mobNav__menu--item .menu-toggleBtn {
	position: absolute;
	top: 0.5em;
	transform: translate(0, -0.25em);
	right: 0;
	width: 2em;
	height: 2em;
}

.mobNav__menu--item .menu-toggleBtn::before {
	content: "";
	position: absolute;
	top: 1em;
	transform: translate(50%, -50%);
	right: 1em;
	width: 1em;
	height: 2px;
	background-color: #000;
}

.mobNav__menu--item .menu-toggleBtn::after {
	content: "";
	position: absolute;
	top: 1em;
	transform: translate(50%, -50%);
	right: 1em;
	width: 2px;
	height: 1em;
	background-color: #000;
	transition: 0.25s;
}

.mobNav__menu--item .menu-toggleBtn.active::after,
.mobNav__menu--item .menu-toggleBtn.active::before {
	transform: translate(50%, -50%) rotate(90deg);
	transition: 0.25s;
}

.mobNav__menu--item .menu-toggleBtn.active::before {
	opacity: 0;
}

.mobNav__menu--item .menu-link {
	color: #000;
	font-size: 1em;
}

.mobNav__menu--item .menu-link.active {
	color: #033780;
}

.mobNav__menu--item .menu-link.active-menu {
	color: #033780;
}

.mobNav__menu--item.active-menu .menu-link {
	color: #033780;
}

.mobNav__menu--item .inner-menu {
	display: none;
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0 0 0 1em;
}

.mobNav__menu--item .inner-menu__item {
	padding: 0.75em 0;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.mobNav__menu--item .inner-menu__item:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.mobNav__menu--item .inner-menu__item--link {
	display: block;
	color: #000;
	font-size: 0.9375em;
	font-weight: 400;
}

.mobNav__menu--item .inner-menu__item--link.active {
	padding: 0 0 0.5em;
	color: #033780;
}

.mobNav__menu--item .inner-menu__item--link.active-menu {
	color: #033780;
}

.mobNav__menu--item .inner-menu__item .sub-menu {
	display: none;
	list-style-type: none;
	margin: 0;
	padding: 0 0 0 1em;
}

.mobNav__menu--item .inner-menu__item .sub-menu-item {
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.mobNav__menu--item .inner-menu__item .sub-menu-item:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.mobNav__menu--item .inner-menu__item .sub-menu-item-link {
	display: block;
	padding: 0.75em 1em;
	color: #000;
	font-size: 0.875em;
	font-weight: 400;
	text-transform: capitalize;
}

.mobNav__menu--item .inner-menu__item .sub-menu-item-link.active-menu {
	background-color: #033780;
	color: #fff;
}

.mobNav__menu--item .inner-menu__item .inner-menu-toggleBtn {
	position: absolute;
	top: 1em;
	transform: translate(0, -0.25em);
	right: 0;
	width: 2em;
	height: 2em;
	transition: 0.25s;
}

.mobNav__menu--item .inner-menu__item .inner-menu-toggleBtn::after {
	content: "";
	position: absolute;
	top: 0.25em;
	right: 0.5em;
	border-color: #000;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	border-right-style: solid;
	border-right-width: 2px;
	display: inline-block;
	height: 0.375em;
	width: 0.375em;
	transform: rotate(45deg);
}

.mobNav__menu--item .inner-menu__item .inner-menu-toggleBtn.active {
	transform: translate(0.5em, -1em) rotate(180deg);
	transition: 0.25s;
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9;
}

.header__container {
	padding: 0;
}

.header__wrapper {
	display: flex;
	justify-content: space-between;
	padding: 0;
}

.header .scroll-text {
	position: absolute;
	top: 50%;
	transform: translate(25%, -50%);
	left: 0;
	display: flex;
	flex-direction: column;
}

@media (max-width: 991px) {
	.header .scroll-text {
		display: none;
	}
}

.header .scroll-text .mouse {
	margin: 0 auto;
	display: block;
	border-radius: 3.125em;
	border: 2px solid #fff;
	height: 3.125em;
	width: 1.5em;
	position: relative;
	opacity: 0.4;
}

.header .scroll-text .move {
	position: absolute;
	background-color: #fff;
	height: 0.25em;
	width: 0.25em;
	border-radius: 50%;
	left: 50%;
	transform: translateX(-50%);
	animation: move 2s linear infinite;
	opacity: 0.8;
}

.header .scroll-text .head {
	font-size: 0.75em;
	letter-spacing: 0.03em;
	margin: 0.75em 0 0 0;
	opacity: 0.4;
}



.header.sticky {
	background-color: rgba(0, 0, 0, 0.75);
	transition: 0.4s;
}

.q-home.sticky {
	background-color: rgba(0, 0, 0, 0.75);
	transition: 0.4s;
}


.links-menu {
	padding: 0;
}

@media (max-width: 576px) {
	.links-menu {
		padding: 0 0 1em 2em;
		display: none;
	}
}

.links-menu-item {
	font-size: 1em;
	font-weight: 400;
	padding: 0 1em 0 0;
	margin-bottom: 1.5em;
}

@media (max-width: 991px) {
	.links-menu-item {
		padding: 0 2em 0 0;
	}
}

@media (max-width: 576px) {
	.links-menu-item {
		padding: 0 1em 0 0;
	}
}


.copy-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2.5625em 0;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 576px) {
	.copy-wrapper {
		align-items: center;
		padding: 1.5em 1em 1.5em 1em;
	}
}

.copy-wrapper .vline {
	padding: 0 1em;
}

.copy-wrapper p {
	color: #fff;
	font-weight: 400;
	margin: 0;
}

@media (max-width: 576px) {
	.copy-wrapper p {
		text-align: center;
	}
}

@media (max-width: 576px) {
	.copy-wrapper {
		flex-flow: column;
	}
}


/* !Home Hero Start */

.home-hero {
	position: relative;
	width: 100%;
	height: 100vh;
}

@media (max-width: 1199px) {
	.home-hero {
		height: 60vh;
	}
}

@media (max-width: 991px) {
	.home-hero {
		height: 75vh;
	}
}

@media (max-width: 767px) {
	.home-hero {
		height: 70vh;
	}
}

.main-hero-img {
	position: relative;
	width: 100%;
	height: 100%;
}

.main-hero-img .img-box {
	position: relative;
	width: 100%;
	height: 100%;
}

.main-hero-img .img-box .video-box {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 11em;
	overflow: hidden;
}

.main-hero-img .img-box .video-box::before {
	position: absolute;
	content: "";
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1;
}

@media (max-width: 576px) {
	.main-hero-img .img-box .video-box::before {
		content: none;
	}
}

.main-hero-img .img-box .video-box iframe,
.main-hero-img .img-box .video-box video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: relative;
	right: 0;
	z-index: 0;
}

@media (min-width: 576px) {

	.main-hero-img .img-box .video-box iframe,
	.main-hero-img .img-box .video-box video {
		width: 1920px;
		height: 1080px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.main-hero-img img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.main-hero-caption {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	z-index: 1;
}

@media (max-width: 576px) {
	.main-hero-caption {
		top: 30%;
	}

	.main-hero-img .img-box .video-box {
		display: flex;
		justify-content: center;
	}
}

.main-hero-caption-box {
	padding: 0 4.5%;
}

@media (max-width: 991px) {
	.main-hero-caption-box {
		padding: 0 5em;
	}
}

@media (max-width: 767px) {
	.main-hero-caption-box {
		padding: 0 3em;
	}
}

@media (max-width: 576px) {
	.main-hero-caption-box {
		padding: 0 1em;
	}
}

.home-hero .head {
	width: 65%;
	margin: 0 0 0 auto;
	color: #fff;
	font-size: 4em;
	font-weight: 700;
	line-height: 0.88;
	letter-spacing: -0.03em;
	text-transform: uppercase;
	text-align: right;
}

@media (max-width: 1199px) {
	.home-hero .head {
		font-size: 4.5em;
	}
}

@media (max-width: 991px) {
	.home-hero .head {
		width: 100%;
		font-size: 3em;
	}
}

@media (max-width: 576px) {
	.home-hero .head {
		margin: 0 auto;
		font-size: 2em;
		text-align: center;
	}
}

@media (max-width: 375px) {
	.home-hero .head {
		font-size: 1.5em;
	}
}

.home-hero .head .head-text-wrapper {
	display: block;
	overflow: hidden;
}

.home-hero .head .head-text-wrapper span {
	display: inline-block;
}

.home-hero .head .head-text-wrapper .gradient-anim {
	background: linear-gradient(200deg, #008ce1, #033780 60%);
	background-size: 200% 200%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: gradientAnimation 5s linear infinite;
	-webkit-animation: gradientAnimation 5s linear infinite;
}

@keyframes gradientAnimation {
	0% {
		background-position: 0 25%;
	}

	50% {
		background-position: 100% 45%;
	}

	to {
		background-position: 0 50%;
	}
}

.main-hero-banner {
	width: 100%;
	position: absolute;
	top: 77.5%;
	left: 0;
	transform: translate(0, -50%);
	z-index: 1;
}

.main-hero-banner-desc {
	font-size: 1rem;
}

@media (max-width: 576px) {
	.main-hero-banner {
		top: 45%;
		bottom: auto;
		transform: translate(0, 0);
		text-align: center;
	}

	.main-hero-banner .dg-a-wrap {
		justify-content: center;
	}
}

.main-hero-banner-box {
	padding: 0 9%;
}

@media (max-width: 991px) {
	.main-hero-banner-box {
		padding: 0 5em;
	}
}

@media (max-width: 767px) {
	.main-hero-banner-box {
		padding: 0 3em;
	}
}

@media (max-width: 576px) {
	.main-hero-banner-box {
		padding: 0 1em;
	}
}

.main-hero-banner-wrap {
	max-width: 42%;
	margin: 0;
}

@media (max-width: 1024px) {
	.main-hero-banner-wrap {
		max-width: 55%;
	}
}

@media (max-width: 576px) {
	.main-hero-banner-wrap {
		max-width: 100%;
		padding: 0 0 0 0;
	}
}

.main-hero-banner-wrap .mob-video {
	display: none;
	margin: 8em auto 0 auto;
	width: 20%;
	border-radius: 100%;
	overflow: hidden;
}

.main-hero-banner-wrap .mob-video .img-box {
	height: 5em;
}

@media (max-width: 576px) {
	.main-hero-banner-wrap .mob-video {
		display: flex;
	}
}

.main-hero-banner-wrap .mob-video .play-icon {
	width: 2.5em;
	height: 2.5em;
}

.mbl-view {
	display: none;
}

@media (max-width: 576px) {
	.mbl-view {
		display: block;
		position: fixed;
		bottom: 0;
		z-index: 1046;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
}

.mbl-view-social-wrap {
	background: #363636;
	width: 50%;
	display: flex;
	justify-content: center;
	text-align: center;
}

.mbl-view-social-wrap a {
	width: 50%;
	z-index: 1;
	display: block;
	padding: 0.75em 0;
}

.mbl-view-social-wrap a:first-child {
	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.mbl-view-social-wrap svg {
	width: 1.52em;
}

.mbl-view-get-quote {
	background: #033780;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mbl-view-get-quote a {
	text-align: center;
	text-transform: capitalize;
	color: #fff;
	font-weight: 600;
	cursor: pointer;
}

.mbl-view .icon-btn-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
}

.main-hero-social-link-wrap {
	position: absolute;
	top: 45%;
	left: 4.5%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	z-index: 1;
}

@media (max-width: 767px) {
	.main-hero-social-link-wrap {
		display: none;
	}
}

.main-hero-social-link {
	position: relative;
	width: 2.2625em;
	height: 2.2625em;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #fff;
	border-radius: 100%;
	z-index: 1;
	margin: 0 0 1.25em 0;
	overflow: hidden;
	transition: 0.4s;
}

.main-hero-social-link::before {
	content: "";
	position: absolute;
	width: 110%;
	height: 110%;
	border-radius: 100%;
	background-color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	z-index: -1;
	transition: 0.4s;
}

.main-hero-social-link .img-box {
	width: 45%;
	height: 45%;
}

.main-hero-social-link .img-box svg {
	width: 100%;
	height: 100%;
	transition: 0.4s;
	margin-top: -8px;
}

.main-hero-social-link:hover {
	transition: 0.4s;
}

.main-hero-social-link:hover::before {
	transform: translate(-50%, -50%) scale(1);
	transition: 0.4s;
}

.main-hero-social-link:hover svg path {
	fill: #363636;
	transition: 0.4s;
}

.hero--section__scrollWrapper {
	position: absolute;
	top: 75%;
	right: 6.78%;
	transform: translate(33%, -50%) rotate(-90deg);
}

@media (max-width: 991px) {
	.hero--section__scrollWrapper {
		display: none;
	}
}

.hero--section__scroll {
	color: #033780;
	font-size: 0.75em;
	font-family: Montserrat, sans-serif;
	letter-spacing: 0.845em;
}

.hero--section__scroll::before {
	content: "";
	position: absolute;
	width: 3.5em;
	height: 1px;
	background-color: #033780;
	left: -6em;
	top: 0.65em;
}

.homepage .mob-social-wrapper {
	display: none !important;
}

/* !Home Hero End */






.scroll-bar {
	position: relative;
	width: 40%;
	margin: 0 auto;
	padding: 1.5em 0 0 0;
}

.scroll-bar .swiper-scrollbar {
	z-index: 99;
	cursor: grab !important;
	top: 1em;
	height: 3px !important;
}

.scroll-bar .swiper-scrollbar .swiper-scrollbar-drag {
	background: #033780;
}



@keyframes draganimate {
	0% {
		transform: translate(0, 0);
	}

	25% {
		transform: translate(30px, 0);
	}

	50% {
		transform: translate(-30px, 0);
	}

	100% {
		transform: translate(0, 0);
	}
}
















@keyframes slide--enter-top {
	0% {
		transform: translate3d(0, -101%, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--enter-right {
	0% {
		transform: translate3d(101%, 0, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--enter-bottom {
	0% {
		transform: translate3d(0, 101%, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--enter-left {
	0% {
		transform: translate3d(-101%, 0, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--leave-top {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(0, -101%, 0);
	}
}

@keyframes slide--leave-right {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(101%, 0, 0);
	}
}

@keyframes slide--leave-bottom {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(0, 101%, 0);
	}
}

@keyframes slide--leave-left {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(-101%, 0, 0);
	}
}





.badge .badge-heading {
	margin: auto;
	max-width: 80%;
	display: flex;
	text-align: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.badge .badge-heading {
		max-width: 100%;
	}
}

.badge .badge-heading h2 {
	color: #033780;
}

.badge .badge-wrapper {
	display: flex;
	flex-wrap: nowrap;
	padding: 4em 0;
	justify-content: space-between;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 30px;
	padding: 2em;
	margin: 0 1em;
	background-color: #fff;
	z-index: 1;
}

@media (max-width: 767px) {
	.badge .badge-wrapper .badge-card .badge-card-wrapper {
		margin: 0;
	}
}

.badge .badge-wrapper .badge-card .badge-card-wrapper .badge-image {
	width: 7em;
}

@media (max-width: 767px) {
	.badge .badge-wrapper .badge-card .badge-card-wrapper .badge-image {
		width: 12em;
	}
}

.badge .badge-wrapper .badge-card .badge-card-wrapper h3 {
	margin: 1em 0;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper h3 span {
	font-size: 0.5em;
	font-weight: 500;
	margin-left: 0.5em;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper p {
	margin-top: 1em;
	color: #000;
	font-size: 1em;
}

.badge .badge-wrapper .badge-card::before {
	content: "";
	background: #de244a;
	border-radius: 30px;
	bottom: -9px;
	content: "";
	height: 110px;
	position: absolute;
	right: 19px;
	transform: matrix(0.99, 0.11, -0.13, 0.99, 0, 0);
	width: 77%;
	z-index: 0;
}

@media (max-width: 767px) {
	.badge .badge-wrapper .badge-card::before {
		width: 88%;
		right: 2px;
	}
}



.getSocial__card--descrip {
	display: flex;
	justify-content: space-between;
	flex-flow: column;
	height: 100%;
	padding: 6.875em 4.0625em 2.1875em 2.1875em;
}

@media (max-width: 1199px) {
	.getSocial__card--descrip {
		padding: 5em 2em 2.1875em 2.1875em;
	}
}

.getSocial__card--descrip p {
	color: #fff;
	font-size: 1.0625em;
	font-weight: 600;
	margin: 0;
}

@media (max-width: 1199px) {
	.getSocial__card--descrip p {
		font-size: 0.6em;
	}
}

.getSocial__card--descrip a {
	color: #fff;
}




















.scene-spacing {
	height: 100vh;
}






@keyframes move {
	0% {
		transform: translate(-50%, 0);
		opacity: 0;
	}

	50% {
		transform: translate(-50%, 1.5625em);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, 3em);
		opacity: 0;
	}
}

.translateY-top {
	transform: translate(0, 50%);
}

@media (max-width: 767px) {
	.translateY-top {
		transform: translate(0, 0);
	}
}

.translateY-bottom {
	transform: translate(0, -50%);
}

@media (max-width: 767px) {
	.translateY-bottom {
		transform: translate(0, 0);
	}
}

.projectForm--box {
	position: fixed;
	inset: 0;
	width: 100vw;
	height: 100%;
	transform: translateX(-100vw);
	overflow-y: hidden;
	z-index: 9;
	padding: 3em 7%;
}

@media (max-width: 576px) {
	.projectForm--box {
		z-index: 99;
	}
}

.projectForm--box__gradient {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(227, 32, 74, 0.5);
	z-index: -1;
}

.projectForm {
	position: fixed;
	inset: 0;
	width: 73vw;
	height: 100%;
	background-color: #fff;
	padding: 3em 7%;
}

@media (max-width: 991px) {
	.projectForm {
		padding: 0 5em;
	}
}

@media (max-width: 767px) {
	.projectForm {
		padding: 0 3em;
	}
}

@media (max-width: 576px) {
	.projectForm {
		padding: 0 1em;
		width: 100vw;
	}
}

.projectForm__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.projectForm__wrapper .head {
	color: #000;
	margin: 0 0 1em 0;
}

.projectForm .close-btn {
	width: 2em;
	height: 2em;
	position: absolute;
	right: 7%;
	top: 7%;
	padding: 0;
}



.projectBanner {
	width: 28.5em;
	display: flex;
	position: absolute;
	right: 0;
	bottom: 0;
}

@media (max-width: 576px) {
	.projectBanner {
		width: 100%;
		display: none;
	}
}

.projectBanner__leftsec,
.projectBanner__rightsec {
	width: 50%;
}

.projectBanner__leftsec {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1em 1.5em;
	background: #033780;
	transition: 0.2s;
	cursor: pointer;
}

.projectBanner__leftsec::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background: #fff;
	left: auto;
	transition: 0.5s;
}

.projectBanner__leftsec--img {
	width: 2.375em;
	z-index: 1;
}

.projectBanner__leftsec--img svg path {
	transition: 0.2s;
}

.projectBanner__leftsec--head {
	font-size: 1em;
	font-weight: 700;
	padding: 0.5em 0;
	transition: 0.2s;
	z-index: 1;
}

.projectBanner__leftsec--arrow {
	display: flex;
	opacity: 0.5;
	transition: 0.2s;
	z-index: 1;
}

.projectBanner__leftsec--arrow .img-box {
	width: 1em;
	display: flex;
	align-items: center;
	margin-left: 0.5em;
	transform: rotate(-45deg);
	transition: 0.2s;
}

.projectBanner__leftsec--arrow svg path {
	transition: 0.2s;
}

.projectBanner__leftsec:hover::before {
	left: 0;
	width: 100%;
	transition: 0.5s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--img svg path {
	fill: #033780;
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--head {
	color: #033780;
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--arrow {
	opacity: 1;
	color: #033780;
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--arrow .img-box {
	transform: rotate(0);
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--arrow svg path {
	fill: #033780;
	transition: 0.2s;
}

.projectBanner__rightsec {
	position: relative;
	cursor: pointer;
}

.projectBanner__rightsec img {
	display: block;
	height: 100%;
}

.projectBanner__rightsec .play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3.125em;
	height: 3.125em;
	background: #033780;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.projectBanner__rightsec .play-icon .play-icon-arrow {
	width: 1em;
	height: 1em;
	background: #fff;
	clip-path: polygon(0 0, 80% 48%, 0 100%, 0 100%);
	transition: clip-path 0.5s ease;
	transition-delay: 0.4s;
	margin-left: 0.375em;
}

.button-outer-circle {
	background: #033780;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

.has-scale-animation {
	animation: smallScale 2s infinite;
}

.has-delay-short {
	animation-delay: 0.5s;
}

@keyframes smallScale {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(1.5);
		opacity: 0;
	}
}

.error {
	position: absolute;
	top: 100%;
	left: 0 !important;
	color: #db0029 !important;
	font-size: 0.9em !important;
	text-indent: 0 !important;
}

.form-box-wrapper .error {
	top: 80% !important;
}

#video-wrap:not(.active) {
	opacity: 0;
	visibility: hidden;
}

#video-wrap .video-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

#video-wrap .video-container {
	width: 100%;
}

#video-wrap .close-video {
	background-color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 5%;
	top: 5%;
	width: 2.5em;
	height: 2.5em;
	border-radius: 100%;
	padding: 0;
	z-index: 150;
}

#video-wrap .close-video:after,
#video-wrap .close-video:before {
	content: "";
	width: 50%;
	height: 3px;
	background-color: #000;
	position: absolute;
	transition: all 0.3s ease-in-out;
}

#video-wrap .close-video:before {
	transform: rotate(42deg);
}

#video-wrap .close-video:after {
	transform: rotate(-42deg);
}

#video-wrap .close-video:focus {
	outline: 0;
}

#video-wrap .close-video:hover {
	background-color: #000;
}

#video-wrap .close-video:hover:after,
#video-wrap .close-video:hover:before {
	background-color: #fff;
}

#video-wrap iframe,
#video-wrap video {
	width: 100vw;
	height: 62.25vw;
	min-height: 100vh;
	min-width: 177.77vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#video-wrap.active {
	opacity: 1;
	visibility: visible;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	height: 100vh;
	display: flex;
	transition: all 0.3s ease-in-out;
	z-index: 9991;
	align-items: center;
	justify-content: center;
}

/* cursor styling */
.small {
	width: 6px;
	height: 6px;
	border: 2px solid #fff;
	border-radius: 50%;
}

.big {
	width: 50px;
	height: 50px;
	background-color: white;
	border-radius: 50%;
	margin-bottom: 20rem;
	animation-name: stretch;
	animation-duration: 2s;
	animation-timing-function: ease-out;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	animation-play-state: running;
}

@keyframes stretch {
	0% {
		opacity: 0.2;
		background-color: green;
		border-radius: 100%;
	}

	50% {
		background-color: orange;
	}

	100% {
		/* //transform: scale(2.0); */
		background-color: red;
	}
}

/* directional hover css */
.direction-aware-hover {
	display: inline-block;
	margin: 0;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 24em;
}

.direction-aware-hover > * {
	bottom: 0;
	left: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.direction-aware-hover__left {
	left: -70.75%;
	right: 70.75%;
}

.direction-aware-hover__right {
	left: 70.75%;
	right: -70.75%;
}

.direction-aware-hover__top {
	bottom: 70.75%;
	top: -70.75%;
}

.direction-aware-hover__bottom {
	bottom: -70.75%;
	top: 70.75%;
}

.direction-aware-hover__content {
	bottom: none;
	color: #eee;
	height: 100%;
	opacity: 0;
	right: none;
	transform: none;
	transition: opacity 0.55s, left 0.55s ease-in-out, top 0.55s ease-in-out,
		z-index 0s linear 0.55s;
	width: 100%;
	z-index: -1;
}

.direction-aware-hover__content:after {
	bottom: -100%;
	content: "";
	left: -100%;
	position: absolute;
	right: -100%;
	top: -100%;
	z-index: -1;
}

*:hover ~ .direction-aware-hover__content {
	transition: opacity 0s, left 0s ease-in-out, top 0s ease-in-out,
		z-index 0s linear 0s;
	z-index: 1;
}

.direction-aware-hover__left:hover ~ .direction-aware-hover__content {
	left: -100%;
}

.direction-aware-hover__right:hover ~ .direction-aware-hover__content {
	left: 100%;
}

.direction-aware-hover__top:hover ~ .direction-aware-hover__content {
	top: -100%;
}

.direction-aware-hover__bottom:hover ~ .direction-aware-hover__content {
	top: 100%;
}

.direction-aware-hover__content:hover {
	left: 0;
	opacity: 1;
	top: 0;
	transition: opacity 0.55s, left 0.55s ease-in-out, top 0.55s ease-in-out,
		z-index 0s linear 0s;
	z-index: 1;
}

.img-box {
	width: 100%;
}

/* !Two Columns */
.twocol-row {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

/* !Services from Pages */



@media (max-width: 1400px) and (min-width: 1300px) and (min-height: 900px) {
	body {
		font-size: 11.485px;
	}
}

@media (max-width: 1199px) {
	body {
		font-size: 12px;
		overflow-x: hidden;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 14px;
	}
}

@media (max-width: 991px) {
	.section {
		padding: 3em 0;
	}
}

@media (max-width: 991px) {
	.container {
		padding: 0 5em;
	}
}

@media (max-width: 767px) {
	.container {
		padding: 0 3em;
	}
}

@media (max-width: 576px) {
	.container {
		padding: 0 1em;
	}
}

@media (min-width: 1024px) {
	.container.padding-right-0 {
		padding-right: 0;
	}
}

@media (min-width: 1024px) {
	.container.padding-0 {
		padding: 0;
	}
}

@media (max-width: 576px) {
	.home footer {
		padding: 3em 0 4em 0;
	}
}

@media (max-width: 576px) {
	.banner-box {
		padding: 0 !important;
	}
}

@media (max-width: 576px) {
	.banner-heading {
		font-size: 22px !important;
	}
}

@media (max-width: 1199px) {

	.generic-navigation-wrapper.large-btn .swiper-button-next,
	.generic-navigation-wrapper.large-btn .swiper-button-prev {
		width: 3em;
		height: 3em;
	}
}

@media (max-width: 991px) {

	.generic-navigation-wrapper.large-btn .swiper-button-next.swiper-button-disabled,
	.generic-navigation-wrapper.large-btn .swiper-button-prev.swiper-button-disabled {
		opacity: 1;
		visibility: visible;
	}
}

@media (max-width: 1199px) {

	.generic-navigation-wrapper.white-bg .swiper-button-next,
	.generic-navigation-wrapper.white-bg .swiper-button-prev {
		width: 3em;
		height: 3em;
	}
}

@media (max-width: 1199px) {
	.p-lr-4 {
		padding: 2em;
	}
}

@media (max-width: 767px) {
	.mob-none {
		display: none;
	}
}



@media (max-width: 576px) {
	.fill-btn {
		padding: 0.5em 2em;
	}
}





@media (max-width: 576px) {
	.generic--head__subhead.left-element {
		padding: 0;
		letter-spacing: 0 !important;
	}

	.generic--head__subhead.left-element::before {
		content: normal;
	}
}

@media (max-width: 576px) {
	.animate {
		opacity: 1;
	}

	.animate.fadein-Left {
		transform: translate(0, 0);
	}

	.animate.fadein-Right {
		transform: translate(0, 0);
	}

	.animate.fadein-Up {
		transform: translate(0, 0);
	}

	.animate.fadein-Up-Fast {
		transform: translate(0, 0);
	}

	.animate.fadein-Down {
		transform: translate(0, 0);
	}
}

@media (max-width: 991px) {
	.col-90 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-80 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-75 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-70 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-65 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-67 {
		width: 100%;
	}
}

@media (max-width: 576px) {
	.col-33 {
		width: 100%;
	}
}

@media screen and (max-width: 557px) {
	.dg-flotaing-icons {
		left: 1em;
		bottom: 3em;
	}
}

@media (max-width: 991px) {
	.navigation-wrapper .dg-social-wrapper {
		display: none;
		padding: 0 0 1.25em 2em;
	}

	.col-52 {
		width: 100%;
	}

	.col-31 {
		width: 100%;
	}

	.col-30 {
		width: 100%;
	}

	.col-25 {
		width: 100%;
	}

	.col-18 {
		width: 100%;
	}

	.col-20 {
		width: 100%;
	}

	.col-10 {
		width: 100%;
	}

	.col-54 {
		width: 100%;
	}

	.col-46 {
		width: 100%;
	}

	.col-60 {
		width: 100%;
	}

	.col-57 {
		width: 100%;
	}

	.col-59 {
		width: 100%;
	}

	.col-56 {
		width: 100%;
	}

	.col-55 {
		width: 100%;
	}

	.col-53 {
		width: 100%;
	}

	.col-50 {
		width: 100%;
	}

	.col-45 {
		width: 100%;
	}

	.col-44 {
		width: 100%;
	}

	.col-42 {
		width: 100%;
	}

	.col-48 {
		width: 100%;
	}

	.col-47 {
		width: 100%;
	}

	.col-43 {
		width: 100%;
	}

	.col-40 {
		width: 100%;
	}

	.col-37 {
		width: 100%;
	}

	.col-36 {
		width: 100%;
	}

	.col-35 {
		width: 100%;
	}

	.col-33 {
		width: 50%;
	}
}