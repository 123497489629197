@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Urbanist:wght@600&display=swap);

* {
  box-sizing: border-box;
}

:focus {
  outline: 0;
}

html {
  overflow-x: hidden;
  font-size: 62.5%;
}

body {
  font-family: Montserrat, sans-serif;
  font-size: 62.5%;
  font-weight: 400;
  color: #fff;
  text-align: left;
  overflow-x: hidden;
  margin: 0;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Medium";
  src: local("Montserrat"), url("../font/Montserrat-Regular.otf") format("otf");
}

.industries-swiper {
  position: relative;
}

.industries-swiper .my-industries-swiper {
  padding-left: 1.8rem;
  position: relative;
  border-bottom: 2px solid #3636364D;
}

.industries-swiper .my-industries-swiper .swiper-wrapper .swiper-slide-active::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 98%;
  border-bottom: 2px solid #033780;
}

.industries-swiper .my-industries-swiper .industries-slide .image-ind {
  width: auto;
  height: 18rem;
  position: relative;
  overflow: hidden;
}

.industries-swiper .my-industries-swiper .industries-slide img {
  width: auto;
  height: 18rem;
  object-fit: fill;
}

.industries-swiper .my-industries-swiper .industries-slide h1 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #033780;
  text-transform: capitalize;
  word-break: break-word;
  position: absolute;
  font-size: 3.6rem;
  top: 85%;
  right: 13%;
  z-index: 1;
  font-family: sans-serif;
  font-weight: 700;
  overflow: hidden;
}


.industries-swiper .swiper-right {
  height: 100%;
}

@keyframes expandAnimation {
  from {
    height: 0;
  }

  to {
    height: 60vh;
  }
}

.services-dropdown {
  width: 100%;
  background-color: #033780;
  position: fixed;
  margin-top: 4.6rem;
  z-index: 999;
  animation: expandAnimation 1s ease;
}

.services-dropdown .d-body a,
.services-dropdown img {
  opacity: 0;
  transform: translateY(10px);
  animation: fadeInAndSlideDown 1s ease forwards;
}

.slider-portfolio-left  img{
      object-fit: cover !important;
      background-color: #033780;
}
@keyframes fadeInAndSlideDown {
  from {
    opacity: 0;
    transform: translateY(0);
  }

  to {
    opacity: 1;
    transform: translateY(10px);
  }
}

@keyframes fadeExpandAnimation {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: 60vh;
  }
}

.services-dropdown .services-dleft {
  border-right: 1px solid white;
  display: flex;
  height: 60vh;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeExpandAnimation 1s ease-in-out;
}

.services-dropdown .services-dleft a {
  background-color: white;
  color: #033780;
  width: 80%;
  padding: 0.6rem 1rem;
  margin: 4px 10px 4px 20px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1rem;
}

.services-dleft a:hover {
  cursor: pointer;
  background-color: #0070c6;
  color: white;
  transition: 0.5s ease-in;
}

.d-header {
  width: 100%;
  border-bottom: 1px solid white;
  margin-left: -8px;

}

.d-header h1 {
  margin: 2rem 4rem 2rem 4rem;
  font-weight: 600;
}

.d-body {
  margin: 2.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.d-body .d-item {
  width: 30%;
  margin: 1rem;
  display: flex;
  flex-direction: row;
}

.d-body .d-img {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 6px;
  object-fit: fill;
  padding: 1px;
  background-color: white;
  box-shadow: 0 0 5px 5px #00000009;
}

.d-body a {
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
  position: relative;
  text-decoration: none;
}

.d-body a:hover {
  text-decoration: none;
}

.d-body a:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 2px;
  background-color: white;
  bottom: -2px;
  left: 50%;
  transition: width 0.5s ease, left 0.5s ease;
}

.d-body a:hover:after {
  width: 100%;
  left: 0;
}

.dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.dropdown.show {
  max-height: 600px;
}

.custom-nav-button {
  position: absolute;
  bottom: 30%;
  background-color: #033780;
  border-radius: 50%;
}

.swiper-left h3 {
  text-align: center;
  font-weight: 500;
  margin-top: 1rem;
}

.disabled {
  visibility: hidden;
}

.custom-nav-button:hover {
  cursor: pointer;
  background-color: #0070c6;
}

.custom-next-button {
  right: 30rem;
}

.custom-prev-button {
  right: 34rem;
}

.services-home-list {
  display: flex;
  flex-wrap: wrap;
}

.services-home-list .home-lists {
  width: 33%;
  margin-bottom: 0;
}

.services-home-list .home-lists li::marker {
  color: white !important;
}

.services-home-list a {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.523);
  position: relative;
  text-decoration: none;
}

.services-home-list a:hover {
  color: white;
  transition: all 0.5s ease-in;
}


/* !Arrow Anchor Start */
.anchor {
  color: #fff;
  text-decoration: none;
  transition: 0.4s;
}

.anchor:hover {
  color: #033780;
  transition: 0.4s;
}

.arrow-anchor {
  position: relative;
  display: flex;
  align-items: center;
}

.anchor-wrapper {
  display: flex;
}

.arrow-anchor span {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  margin: 0 0.75rem 0 0;
}

.arrow-anchor .img-box {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #033780;
  transition: 0.4s;
}

.arrow-anchor .img-box svg {
  width: 1rem;
  transform: rotate(-45deg);
  transition: 0.4s;
}

.arrow-anchor .img-box svg path {
  transition: 0.4s;
}

.arrow-anchor:hover .img-box {
  background: #fff;
  transition: 0.4s;
  box-shadow: 0 0 9px 6px rgba(136, 136, 136, 0.18);
}

.arrow-anchor:hover .img-box svg {
  transform: rotate(0);
  transition: 0.4s;
}

.arrow-anchor:hover .img-box svg path {
  fill: #033780;
  transition: 0.4s;
}

.arrow-anchor.black-col span {
  color: #000;
  transition: 0.25s;
}

.arrow-anchor.black-col:hover span {
  color: #033780;
  transition: 0.25s;
}

.arrow-anchor.gray-col span {
  color: #363636;
  transition: 0.25s;
}

.arrow-anchor.gray-col:hover span {
  color: #033780;
  transition: 0.25s;
}

.arrow-anchor.transparent-bg .img-box {
  background: 0 0;
}

.arrow-anchor.transparent-bg .img-box svg path {
  fill: #033780;
}

.arrow-anchor.white-bg .img-box {
  background: #fff;
}

.arrow-anchor.white-bg .img-box svg path {
  fill: #033780;
}

.arrow-anchor.small-achor span {
  font-size: 1rem;
}

.arrow-anchor.small-achor .img-box {
  width: 2.5rem;
  height: 2.5rem;
}

/* !Arrow Anchor End */

/* !Utility Classes Start */
.black-col {
  color: #000;
}

.gray-col {
  color: #363636;
}

.primary-col {
  color: #033780;
}

.gray-bg {
  background: #4b4b4b;
}

.gray-bg7 {
  background: #e7e7e7;
}

.primary-bg {
  background: #033780;
}

.st0 {
  fill: #fff;
}

/* !Utility Classes End */

/* ?Main Form Start */
.main-form-wrap {
  justify-content: space-between;
}

.main-form-wrap .input-container {
  display: flex;
  align-items: center;
  height: 5rem;
  position: relative;
  width: 100%;
  margin: 0 0 3rem 0;
}

.main-form-wrap .input-container .input-select {
  width: 100%;
  height: 100%;
  border: none;
  font-family: Montserrat, sans-serif;
  font-size: 1.2rem;
  border: none;
  border-bottom: 1px solid rgba(121, 121, 121, 0.2);
  position: relative;
  top: unset;
  left: unset !important;
  color: #000 !important;
  background: 0 0;
}

.main-form-wrap .input-container .input-select option {
  padding-bottom: 3rem;
}

.main-form-wrap .input-container .input-select option:hover {
  background: #033780;
}

.main-form-wrap .input-container textarea {
  resize: none;
}

.main-form-wrap .input-container .input {
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  width: 100%;
  height: 100%;
  background: 0 0;
  border: none;
  border-bottom: 1px solid rgba(121, 121, 121, 0.2);
  transition: 0.25s;
}

.main-form-wrap .input-container .input.error {
  top: unset;
  left: unset !important;
  font-size: initial !important;
  color: #000 !important;
}

.main-form-wrap .input-container .input:focus {
  outline: 0;
  border-bottom: 1px solid #363636;
  transition: 0.25s;
}

.main-form-wrap .input-container .input::placeholder {
  color: #000;
}

.main-form-wrap .input-container .placeholder {
  color: #636363;
  font-size: 1rem;
  left: 0;
  line-height: 1rem;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 0.2s, color 0.2s;
  top: 2rem;
  padding: 0;
  transition: 0.4s;
  background-color: transparent;
  opacity: 1;
}

.main-form-wrap .input-container.textArea {
  height: 5rem;
}

.main-form-wrap .cut {
  height: 2rem;
  left: 0;
  position: absolute;
  top: -3rem;
  transform: translateY(0);
  transition: transform 0.2s;
  width: 7.6rem;
}

.main-form-wrap .cut-short {
  width: 5rem;
}

.main-form-wrap .input:focus ~ .cut,
.main-form-wrap .input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.main-form-wrap .input:focus ~ .placeholder,
.main-form-wrap .input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-25px) translateX(0) scale(0.75);
  font-size: 1rem;
}

.main-form-wrap .input:not(:placeholder-shown) ~ .placeholder {
  font-size: 1rem;
}

.main-form-wrap .iti__arrow {
  border-top: 4px solid #000;
}

.main-form-wrap .iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #000;
}

/* ?Main Form End */

/* ?H Fonts Start */
h1 {
  font-weight: 400;
  font-size: 3rem;
  font-family: Montserrat, sans-serif;
  color: #fff;
  margin: 0 0 0.5rem 0;
  line-height: 1;
}

h2 {
  font-weight: 500;
  font-size: 2.6rem;
  font-family: Montserrat, sans-serif;
  color: #fff;
  margin: 0 0 0.5rem 0;
  line-height: 1;
}

h3 {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
  color: #fff;
  margin: 0 0 1rem 0;
  line-height: 1;
}

h4 {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
  color: #fff;
  margin: 0 0 0.5em 0;
}

h5 {
  font-size: 1.25rem;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
  color: #fff;
  margin: 0 0 0.5em 0;
}

h6 {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
  color: #fff;
  margin: 0 0 0.5rem 0;
}

p {
  font-size: 1.2rem;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin: 0 0 1.5rem 0;
  color: #fff;
  line-height: 1.5;
}

/* ?H Fonts End */

/* ?NavbarHome Start*/
.brand-logo {
  position: relative;
  background-color: #363636;
}

.brand-logo-anchor {
  width: 12rem;
  height: auto;
  transition: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.8rem;
}

.brand-logo-anchor img {
  height: 100%;
  display: block;
}

.navbar-home {
  background: transparent;
  position: fixed;
  z-index: 9;
  width: 100%;
  top: 0;
  padding-top: 0;
  padding-bottom: 0;
  --bs-navbar-brand-padding-y: 0;
}

.navbar-home .dg-social-wrapper .dg-a-wrap.project-a a {
  min-width: 15rem;
  padding: 0 1rem;
  background: #fff;
  color: #033780;
  font-size: 1.07rem;
  font-weight: 700;
  transition: 0.5s;
  cursor: pointer;
}

.navbar-home .dg-social-wrapper .dg-a-wrap.project-a a::before {
  background: #033780;
}

.navbar-home .dg-social-wrapper .dg-a-wrap.project-a a:hover {
  color: #fff;
  transition: 0.5s;
}

.navbar-home .dg-social-wrapper .dg-a-wrap.project-a a span {
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}

.navbar-home .dg-social-wrapper .dg-a-wrap a {
  position: relative;
  width: 4rem;
  background: #033780;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -1.6rem 0 -1.6rem 0;
}

.navbar-home .dg-social-wrapper .dg-a-wrap a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #fff;
  left: auto;
  transition: 0.5s;
}

.navbar-home .dg-social-wrapper .dg-a-wrap a:hover svg path {
  fill: #033780;
  transition: 0.5s;
}

.navbar-home .dg-social-wrapper .dg-a-wrap a:hover::before {
  left: 0;
  width: 100%;
}

.navbar-home .dg-social-wrapper .dg-a-wrap a svg {
  width: 1.6rem;
  z-index: 1;
}

.navbar-home .dg-social-wrapper .dg-a-wrap a svg path {
  transition: 0.5s;
  fill: #ffffff;
  transition: 0.5s;
}

.navbar-home .brand-logo-anchor .web-logo {
  display: block;
}

.navbar-home .brand-logo-anchor .mob-logo {
  display: none;
}

.navbar-home .nav-menu .nav-menu-item .nav-menu-item-link {
  font-size: 1;
}

.navbar-home .nav-menu .nav-menu-item .nav-menu-item-link:hover {
  font-weight: bold;
  transition: 0.5s;
}

.nav-menu-item-link.active,
.nav-menu-item-link:hover {
  color: #0070c6;
  font-weight: bold !important;
  padding: 0 !important;
}

.navbar-home .active-nav {
  color: #033780;
}

.navbar-home a {
  color: #fff;
  font-size: 1rem;
  text-transform: uppercase;
}

.navbar-home a:hover {
  font-weight: bold;
  transition: 0.5s;
}

.navbar-home.sticky {
  background-color: rgba(0, 0, 0, 0.75);
  overflow-x: hidden;
}

/* ?NavbarHome End*/

/* ?Speak to An Expert Form Start*/
.speak-expert-canvas .btn-close {
  font-size: 2rem;
}

.speak-expert-canvas .btn-close:focus {
  box-shadow: none;
}

.speak-expert-form-wrap .input-container .input {
  color: #000;
  font-size: 1.07rem;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  width: 100%;
  height: 100%;
  background: 0 0;
  border: none;
  border-bottom: 1px solid rgba(121, 121, 121, 0.2);
  transition: 0.25s;
}

.speak-expert-form-wrap .input-container .input-select {
  width: 100%;
  height: 100%;
  border: none;
  font-family: Montserrat, sans-serif;
  font-size: 1.07rem;
  border: none;
  border-bottom: 1px solid rgba(121, 121, 121, 0.2);
  position: relative;
  top: unset;
  left: unset !important;
  color: #000 !important;
  background: 0 0;
}

.speak-expert-form-wrap .input-container .input-select option {
  padding-bottom: 3rem;
}

.speak-expert-form-wrap .input-container .input-select option:hover {
  background: #033780;
}

.speak-expert-form-wrap .input-container textarea {
  resize: none;
  margin-top: 34px;
}

.speak-expert-form-wrap .input-container .input.error {
  top: unset;
  left: unset !important;
  font-size: initial !important;
  color: #000 !important;
}

.speak-expert-form-wrap .input-container .input:focus {
  outline: 0;
  border-bottom: 1px solid #363636;
  transition: 0.25s;
}

.speak-expert-form-wrap .input-container .input::placeholder {
  color: #000;
}

.speak-expert-form-wrap .input-container .placeholder {
  color: #636363;
  font-size: 1.07rem;
  left: 0;
  line-height: 0.3rem;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 0.2s, color 0.2s;
  top: 2rem;
  padding: 0;
  transition: 0.4s;
}

.speak-expert-form-wrap .input-container.textArea {
  height: 5rem;
}

.speak-expert-form-wrap .cut {
  height: 2rem;
  left: 0;
  position: absolute;
  top: -3rem;
  transform: translateY(0);
  transition: transform 0.2s;
  width: 7.6rem;
}

.speak-expert-form-wrap .cut-short {
  width: 5rem;
}

.speak-expert-form-wrap .input:focus ~ .cut,
.speak-expert-form-wrap .input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.speak-expert-form-wrap .input:focus ~ .placeholder,
.speak-expert-form-wrap .input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-25px) translateX(0) scale(0.75);
  font-size: 1.07rem;
}

.speak-expert-form-wrap .input:not(:placeholder-shown) ~ .placeholder {
  font-size: 1.07rem;
}

.speak-expert-form-wrap .iti__arrow {
  border-top: 4px solid #000;
}

.speak-expert-form-wrap .iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #000;
}

.offcanvas-backdrop {
  background-color: #0f51ad73;
}

.offcanvas.offcanvas-start {
  width: 70vw;
}

.speak-expert-canvas .offcanvas-header {
  justify-content: flex-end;
}

.speak-expert-canvas .btn-close {
  background: transparent url(../Images/close-btn.svg) center/1em auto no-repeat;
  width: 2rem;
  height: 2rem;
  margin-top: 3rem;
  margin-right: 3rem;
  opacity: 1;
}

.speak-expert-canvas .offcanvas-body {
  padding: 2rem 7%;
  overflow-y: hidden;
}

.speak-expert-canvas .offcanvas-body h2 {
  color: #000;
}

.speak-expert-canvas .offcanvas-body .placeholder {
  background-color: transparent;
  color: #000;
  opacity: 1;
}

.speak-expert-form-wrap {
  justify-content: space-between;
}

.speak-expert-form-wrap .input-container {
  display: flex;
  align-items: center;
  height: 5rem;
  position: relative;
  width: 100%;
  margin: 0 0 3rem 0;
}

/* ?Speak to An Expert Form End*/

/* ?Button Start */
.primary-anchor {
  position: relative;
}

.primary-anchor a,
.primary-anchor button {
  position: relative;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
  color: #fff;
  padding: 0.9rem 1.8rem;
  background: #033780;
  border: 1px solid #033780;
  transition: 0.4s;
  z-index: 1;
}

.primary-anchor a::before,
.primary-anchor button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #fff;
  left: auto;
  transition: 0.5s;
  z-index: -1;
}

.primary-anchor a:hover,
.primary-anchor button:hover {
  color: #033780;
  transition: 0.4s;
}

.primary-anchor a:hover::before,
.primary-anchor button:hover::before {
  left: 0;
  width: 100%;
}

.primary-anchor.white-col a,
.primary-anchor.white-col button {
  color: #033780;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 4px 13px 3px rgba(0, 0, 0, 0.06);
}

.primary-anchor.white-col a::before,
.primary-anchor.white-col button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #033780;
  left: auto;
  transition: 0.5s;
  z-index: -1;
}

.primary-anchor.white-col a:hover,
.primary-anchor.white-col button:hover {
  color: #fff;
  transition: 0.4s;
}

.primary-anchor.white-col a:hover::before,
.primary-anchor.white-col button:hover::before {
  left: 0;
  width: 100%;
}

/* ?Button End */

/* ?Line Heading Start */
.line-heading {
  display: flex;
  max-width: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.line-heading .outline-text {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #b9b9ba;
  text-transform: capitalize;
  word-break: break-word;
  font-size: 7.5rem;
  font-family: sans-serif;
  font-weight: 700;
  z-index: 1;
}

.line-heading .outline-text.primary-color {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #033780;
}

/* ?Line Heading End */

/* !Home Page Start */
/* ?Main Hero Section Start */
.main-hero-banner-desc {
  font-size: 1.4rem;
}

/* ?Main Hero Section End */

/* ?Services Section Start */
.services {
  position: relative;
  background: #363636;
  animation: 0.5s ease-out 0s 1 normal forwards running anim;
}

.services .line-heading {
  transform: translate3d(176.884px, 0px, 0px);
  position: absolute;
  z-index: 5;
  top: 5rem;
  left: 2rem;
}

.line-heading #servicesHeading {
  margin-left: -20rem;
}

.services-section .swiper-slide h2 a {
  font-size: 1.2rem;
  color: white;
  opacity: 0.2;
}

.services-section .swiper-slide-active h2 a {
  font-size: 2rem !important;
  color: white !important;
  transition: 0.4s;
  font-weight: 600;
  opacity: 1;
}

.services-section .swiper-slide-prev h2 a,
.services-section .swiper-slide-next h2 a {
  font-size: 1.6rem !important;
  color: white !important;
  opacity: 0.35;
}

.services-slider-wrap .services-slider {
  position: relative;
  width: 100%;
  height: 40%;
  margin: auto 0 auto 0;
  padding: 1.5rem;
}

.services-slider-wrap .scroll-text {
  position: absolute;
  top: 50%;
  transform: translate(100%, -50%);
  left: 6%;
  display: flex;
  flex-direction: column;
}

.services-slider-wrap .scroll-text .mouse {
  margin: 0 auto;
  display: block;
  border-radius: 3rem;
  border: 2px solid #fff;
  height: 3.4rem;
  width: 1.6rem;
  position: relative;
  opacity: 0.4;
}

.services-slider-wrap .scroll-text .head {
  font-size: 0.8rem;
  letter-spacing: 0.03rem;
  margin: 0.8rem 0 0 0;
  opacity: 0.4;
}

.services-slider-wrap .scroll-text .move {
  position: absolute;
  background-color: #fff;
  height: 0.2rem;
  width: 0.2rem;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: move 2s linear infinite;
  opacity: 0.8;
}

.service-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.service-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-items,
.services-wrap {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}

.services-item-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  justify-content: flex-end;
}

.services-itemImg {
  width: 60%;
  padding: 0 4.5% 0 4.5%;
  z-index: 0;
  margin-top: -5rem;
}

.services-itemImg p {
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  color: #fff;
  font-weight: 300;
}

.services-itemImg .img-box {
  width: auto;
  height: 30rem;
  margin: 5rem auto 0 auto;
}

.services-itemImg .img-box img {
  height: 100%;
  object-fit: contain;
}

.services-itemImg .description-box {
  margin-top: 1rem;
}

.services-itemImg .description-box p,
.services-itemImg .description-box p span {
  color: #fff !important;
  font-size: 1rem;
}

.services-itemImg .description-box ul {
  display: flex;
  flex-wrap: wrap;
}

.services-itemImg .description-box ul li {
  width: calc(100% * (1 / 3));
  color: #ffffff80 !important;
}

.services-itemImg .description-box ul li::marker {
  color: #fff !important;
}

/* ?Services Section End */

/* ?Our Work Section Start */
.ourWork {
  position: relative;
  padding: 5rem 0 5rem 0;
  background: #363636;
}

.ourWork .mySwiper .swiper-slide:first-child {
  margin-left: -14rem;
}

.ourWork .Work-slider-wrap .Work-slider-img {
  height: 24rem;
  width: 22rem;
  filter: grayscale(1);
  transition: 0.4s;
}

.Work-slider .slick-prev,
.Work-slider .slick-next {
  display: none !important;
}

.Work-slider .swiper-slide .dg-a-wrap {
  opacity: 0;
  transition: 0.2s;
}

.Work-slider .swiper-slide:hover .dg-a-wrap {
  opacity: 1;
  transition: 0.2s;
}

.Work-slider-wrap .Work-slider-img img {
  height: 100%;
  object-fit: cover;
}

.Work-slider-wrap:hover .Work-slider-img {
  filter: grayscale(0);
  transition: 0.4s;
}

.Work-slider-logo {
  width: 70%;
  margin: 0.5rem 0 !important;
}

.Work-slider-logo img {
  width: 16rem !important;
  height: 5rem !important;
  object-fit: cover !important;
}

.ourWork .Work-slider-desc {
  font-size: 1rem !important;
  margin-top: 1rem;
  color: #8d8d8d;
}

/* ?Our Work Section End */

/* ?Brand Section Start */
.brands {
  position: relative;
  background: #f8f8f8;
}

.brands-title {
  width: 60%;
  color: #363636;
  font-size: 3rem;
}

.brands-title span {
  display: block;
  font-weight: 500;
}

.brandSwiper .img-box {
  filter: grayscale(1);
  transition: 0.4s;
  height: 15rem;
}

.brandSwiper .img-box:hover {
  filter: none;
}

.brands .img-box img {
  object-fit: contain;
  height: 15rem;
}

.brandSwiper .swiper-slide {
  margin-top: 4rem !important;
}

.brands .swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  width: 40%;
  margin: 5rem auto 0 auto;
  position: relative !important;
}

.scroll-bar .drag-heading {
  font-size: 1rem;
  color: #636363;
  text-align: center;
}

.scroll-bar .drag-icon {
  width: 1.4rem;
  margin: 0 auto 1rem auto;
  animation: draganimate ease-in-out 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

.brands .swiper-scrollbar-drag {
  background-color: #0070c6 !important;
}

/* ?Brand Section End */

/* ?Awards Section Start */
.awards {
  position: relative;
}

.awards .line-heading .outline-text {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
}

.awards-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.awards-bg-img img {
  height: 100%;
  object-fit: cover;
}

.awards-wrap {
  align-content: flex-start;
  align-items: center;
  min-height: 40rem;
}

.awards__leftsec {
  padding: 0 6rem 0 0;
}

.awards-left-heading {
  color: #fff;
  letter-spacing: -0.03rem;
  font-size: 2.6rem;
}

.awards-left-heading span {
  display: block;
  font-weight: 600;
}

#award_desc {
  font-size: 1.4rem;
  margin-top: 3rem;
}

#award_desc p,
#award_desc span,
#award_desc strong {
  color: white !important;
  font-size: 1.2rem !important;
}

.awards-right-card {
  width: 25%;
  height: 100%;
  padding: 0 0.06rem;
}

.awards-right-card a {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.awards__rightsec {
  display: flex;
  height: 100%;
}

/* ?Awards Section End */

/* ?FAQ Section Start */
.faq {
  position: relative;
  background: #e3e3e3;
}

.faq-box {
  padding: 0 2%;
}

.faq .direction-aware-hover,
.faq-slider-wrap {
  height: 30rem;
}

.faq-slider-wrap {
  position: relative;
  width: 100%;
  height: 30rem;
  padding: 0;
  overflow: hidden;
}

.faq-slide-haeding {
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 1.4rem;
}

.faq-slide-no {
  font-size: 4rem;
  font-family: sans-serif;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #b9b9ba;
  font-weight: 700;
  margin: 0;
}

.faq-slider-desc {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #033780;
  padding: 2rem;
}

.faq-slider-desc p {
  font-size: 1.2rem;
  margin: 0;
  transition: 0.2s;
}

.faq-slider-desc-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-image: url(../Images/faq-texture.webp);
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.faq-slider-desc h2 {
  font-size: 4rem;
  font-family: sans-serif;
  font-weight: 700;
  transition: 0.2s;
}

.faq-slider-arrow {
  width: 3rem;
  height: 3rem;
  padding: 0.4rem;
  border: 2px solid #033780;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-slider-arrow img {
  width: 1rem;
  height: 1rem;
}

.faq-slider-arrow-wrap {
  display: flex;
  justify-content: center;
}

.faq-slider-d p,
.faq-slider-d p span {
  font-size: 1rem;
  color: white !important;
}

/* ?FAQ Section End */

/* ?Blog Section Start */
.blog {
  position: relative;
}

.blog-slide {
  position: relative;
  padding: 2rem 0 0 0;
  width: 26rem;
  margin: 0 4rem;
}

.blog-slide-image {
  min-height: 26rem;
}

.blog-slide-image img {
  object-fit: cover;
}

.blog-slide-desc-wrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 1.55rem 0 0 0;
}

.blog-slide-heading {
  width: 40%;
  color: #c1c1c1;
  font-size: 1rem;
  font-weight: 600;
  padding: 0 1.5rem 0 0;
  margin: 0;
}

.blog-slide-desc {
  font-size: 0.9rem;
  width: 60%;
  color: #282828;
  font-weight: 500;
  line-height: 1.3;
  margin: 0;
}

.blog .blog-s-date h3 {
  opacity: 0;
  font-size: 2.2rem;
}

.blog .swiper-slide-active .blog-s-date h3 {
  opacity: 1;
}

.blog .swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  width: 70rem;
}

.blog .swiper-scrollbar-drag {
  background-color: #0070c6 !important;
}

.worth-read__Slider::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2rem;
  top: 58%;
  left: 0;
  background: #033780;
}

/* ?Blog Section End */

/* ?Conversation Section Start */
.convo {
  position: relative;
  background: #f8f8f8;
}

.convo-card {
  position: relative;
  width: 20%;
}

.convo-card.big-card {
  width: 40%;
}

.convo .convo-card-img img {
  height: 22rem;
}

.convo-card-img {
  width: 100%;
  height: 100%;
}

.convo-heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 1rem 0;
}

.convo-heading p {
  color: #000;
  margin: 0;
  letter-spacing: 0.6rem;
  font-weight: 500;
  font-size: 1.4rem;
}

.convo-heading h2 {
  color: #000;
  margin: 0;
  font-size: 3.2rem;
}

.convo-heading .convo-socials .convo-social-item a svg path {
  fill: #000;
  transition: 0.4s;
}

.convo-heading .convo-socials .convo-social-item a:hover::before {
  transform: translate(-50%, -50%) scale(1);
  transition: 0.3s;
}

.convo-heading .convo-socials .convo-social-item a:hover svg path {
  fill: #fff;
  transition: 0.4s;
}

.convo-heading .convo-socials {
  display: flex;
  padding: 0;
}

.convo-heading .convo-socials .convo-social-item {
  width: 3.7rem;
  height: 3.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem;
}

.convo-heading .convo-socials .convo-social-item a {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.convo-heading .convo-socials .convo-social-item a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: #033780;
  border-radius: 100%;
  transition: 0.3s;
  z-index: 1;
}

.convo-heading .convo-socials .convo-social-item a svg {
  width: auto;
  height: 40%;
  z-index: 2;
}

/* ?Conversation Section End */

/* ?Footer Section Start */
.footer {
  position: relative;
  background: #363636;
  z-index: 0;
}

.footer .line-heading .outline-text {
  margin: 8rem 0 6rem 0;
}

.footer ul {
  margin: 0;
  list-style: none;
}

.footer-bg-color {
  position: absolute;
  width: 45%;
  height: 100%;
  top: 0;
  left: 0;
  background: #033780;
  z-index: -1;
}

.footer-txt-links-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.footer-txt-links-col:nth-child(1) .left-desc h2 span {
  display: block;
  font-weight: 600;
}

.footer-txt-links-col:nth-child(1) .contact-info-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 0 7rem;
}

.footer-txt-links-col:nth-child(1) .contact-info-wrap::before {
  content: "";
  position: absolute;
  width: 5rem;
  height: 0.2rem;
  background-color: #fff;
  top: 0.6rem;
  left: 0;
}

.footer-txt-links-col:nth-child(1) .contact-info-wrap .address {
  margin: 0 0 0.8rem 0;
}

.footer-txt-links-col:nth-child(1) .contact-info-wrap .mail {
  margin: 0 0 0.8rem 0;
}

.footer-txt-links-col:nth-child(1) .contact-info-wrap .icon-wrapper {
  display: flex;
  margin-top: 2rem;
}

.footer-txt-links-col:nth-child(1) .contact-info-wrap .icon-wrapper .icon {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.footer-txt-links-col:nth-child(2) {
  width: 55%;
  padding: 0 9% 0 5rem;
}

.footer-txt-links-col:nth-child(2) .links-col-wrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 2rem 0 0 0;
}

.footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item .links-menu-item {
  margin: 0 0 1rem 0;
}

.footer-txt-links-col:nth-child(2) {
  padding: 0 0.5rem 0 3%;
  font-size: 1rem;
}

.footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item {
  padding-right: 2rem;
  width: 40%;
}

.footer-txt-links-col:nth-child(1) .left-desc p,
.footer-txt-links-col:nth-child(1) .contact-info-wrap p,
.footer-txt-links-col:nth-child(1) .contact-info-wrap span,
.footer-txt-links-col:nth-child(1) .contact-info-wrap a {
  font-size: 1rem;
  margin: 0 0 2rem 0;

}

.footer-txt-links-col:nth-child(2) p {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  position: relative;
  display: block;
  padding: 0 0 0 0.6rem;
  margin-bottom: 1.7rem;
}

.footer-txt-links-col:nth-child(2) p::after {
  content: "";
  position: absolute;
  width: 0.2rem;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
}

.footer-txt-links-col:nth-child(2) a {
  color: #fff;
  font-size: 1rem;
  display: inline-block;
  text-decoration: none;
}

/* ?Footer Section End */
/* !Home Page End */

/* !Services Page Start */
.services-hero-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.services-hero-background img {
  height: 100%;
  object-fit: cover;
}

.services-hero-background::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(226.92deg,
      #0070c6 15.33%,
      rgba(0, 0, 0, 0.88) 55.21%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  opacity: 0.7;
}

.services-hero-form .form-head.hero-main .hero-main-subheading {
  font-weight: 700;
  font-size: 2rem;
}

.services-hero-form .form-head.hero-main .hero-main-para {
  font-size: 1.2rem;
}

.services-hero-form .main-form .input-container {
  margin: 0 0 1rem 0;
}

.services-hero-form .main-form .input-container .input {
  font-size: 1.2rem;
}

.services-hero-form .main-form .input-container .input.error {
  position: relative !important;
  color: #636363 !important;
  top: unset;
  left: unset !important;
  font-size: initial !important;
}

.services-hero .main-form {
  position: relative;
  background: #fff;
  padding: 4rem 4.4rem;
  height: 46rem;
}

.services-hero-social-wrap {
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translate(-50%, -50%) rotate(-90deg);
  display: flex;
  align-items: center;
  z-index: 1;
}

.services-hero-social-wrap .head {
  position: relative;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  padding: 0 0.5rem 0 0;
  margin: 0;
}

.services-hero-socialLinks {
  display: flex;
}

.services-hero-socialLinks .services-hero-socialLink {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  z-index: 1;
  margin: 0 2rem 0 0;
  transition: 0.4s;
  transform: rotate(90deg);
}

.services-hero-socialLinks .services-hero-socialLink .img-box {
  width: 45%;
  height: 45%;
}

.services-hero-socialLinks .services-hero-socialLink .img-box svg {
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

.services-hero-socialLinks .services-hero-socialLink .img-box svg path {
  fill: #fff;
  transition: 0.4s;
}

.serviceHelp {
  position: relative;
}

.services-hero-socialLinks .services-hero-socialLink::after {
  content: "";
  position: absolute;
  width: 0.2rem;
  height: 2.5rem;
  background: #fff;
  right: 50%;
  top: 2.8rem;
}

.services-hero-socialLinks .services-hero-socialLink:first-child::after {
  width: 0;
}

.services-hero-socialLinks .services-hero-socialLink:hover {
  background: #fff;
  transition: 0.4s;
}

.services-hero-socialLinks .services-hero-socialLink:hover svg path {
  fill: #033780;
  transition: 0.4s;
}

.services-main-mainWrap {
  position: relative;
}

.services-main-mainWrap::before {
  content: "";
  position: absolute;
  width: 33rem;
  height: 100%;
  background-color: #363636;
  background-image: url(../Images/hero-form-bg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  z-index: 1;
}

.services-main-container {
  max-width: 100%;
  padding: 0 11%;
}

.services-main-wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 3rem 0;
  z-index: 1;
}

.services-main-heading {
  width: 55%;
  padding: 0 8rem 0 0;
}

.services-hero-form {
  width: 45%;
  padding: 0 0 0 4rem;
}

.services-list-card {
  width: 33.33%;
  padding: 0.7rem;
  height: auto;
}

.services-list-cards-sections {
  width: 78%;
}

.services-list-card .services-list-card-wrap {
  transition: box-shadow 0.25s;
  padding: 3rem 2rem;
  height: 100%;
}

.services-list-card .head {
  color: #363636;
  font-weight: 600;
  letter-spacing: -0.04em;
  line-height: 1.22;
  font-size: 1.6rem;
  margin: 0 0 1em 0;
}

.services-list-card .description {
  color: #363636;
  font-weight: 400;
  font-size: 1.2rem !important;
  margin: 0 0 1rem 0;
}

.services-list-card .card-icon {
  width: 4rem;
  height: 4rem;
  background-color: rgba(164, 161, 161, 0.5);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.5rem 0;
  transition: background-color 0.25s;
}

.services-list-card .card-icon img {
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  object-fit: contain;
}

.services-list-card .arrow-anchor {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s visibility 0.25s;
}

.services-list-card .arrow-anchor .img-box svg {
  width: 1.2rem;
}

.services-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.services-list-heading-section {
  width: 22%;
  padding: 3rem 0 0 0;
}

.services-list-heading-section .head {
  color: #363636;
  font-weight: 600;
  letter-spacing: -0.03rem;
  text-transform: capitalize;
  line-height: 1.22;
  font-size: 3rem;
}

/* !Services Page End */

/* !Blog Page Start */
.case-tabs {
  list-style: none;
  padding-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 5rem 0 0 0;
}

.case-tabs li {
  background: #f2f2f2;
  color: #363636;
  border-radius: 1.6rem;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem 2rem;
  outline: 0;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  margin: 0.6rem;
  display: flex;
  align-items: center;
}

.case-tabs li:hover {
  background: #033780;
  color: #fff;
}

.case-tabs li.mbl-tab {
  display: none;
}

.case-tabs li.active-tab {
  background: #033780;
  color: #fff;
}

.case-tabs li.mbl-active {
  display: none;
}

.case-tabs li.mbl-active::after {
  border-bottom: 0;
  border-top: 0.3rem solid;
  content: "";
  display: inline-block;
  margin-left: 85%;
  border-left: 0.3rem solid rgba(0, 0, 0, 0);
  border-right: 0.3rem solid rgba(0, 0, 0, 0);
  vertical-align: 0.2rem;
}

.blog-page-card {
  border: none;
  margin-bottom: 2rem;
}

.blog-tabs .blog-page-card span p span {
  color: black;
  font-size: 1rem;
}

.blog-page-card .card-body span {
  font-size: 1rem;
}

.blog-page-card .card-body h5 {
  font-size: 1.2rem;
  font-weight: 600;
}

.blogSliderSection {
  position: relative;
  padding-bottom: 8rem;
}

.blog-page-slider .slick-slide {
  padding: 1rem;
}

.blog-page-slider .slick-prev,
.blog-page-slider .slick-next {
  display: block !important;
}

.blog-page-slider .slick-list {
  height: 36rem;
}

/* !Blog Page End */

/* !Careers Page Start */
.intership-form {
  background: linear-gradient(90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 18%,
      rgba(0, 212, 255, 1) 100%);
  background-size: cover;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.intership-form .hero-main-heading {
  font-size: 2rem;
  padding: 2rem;
}

.openings-section .opening-card {
  padding: 2rem;
  border: 1px solid rgba(102, 102, 102, 0.5);
  border-radius: 0 !important;
  margin-bottom: 2.5rem;
}

.openings-section .opening-card .card-body {
  padding: 0 !important;
}

.openings-section .opening-card .card-body p {
  margin: 0 0 0 0;
  font-size: 1rem;
}

.openings-section .hero-main-heading {
  font-size: 3rem;
}

.openings-section .hero-main-section-txt p {
  color: black;
  width: 70%;
  margin: auto;
  font-size: 1.2rem;
}

.openings-section .hero-main-section-txt .job-details p {
  font-size: 1rem;
  width: 100%;
  text-align: justify;
  margin: 1rem 0;
}

.openings-section .opening-card .apply-now {
  font-size: 1.2rem;
}

/* !Careers Page End */

/* !Careers Setail Page Start */
.currentOpenings {
  position: relative;
}

.job-description__card {
  box-shadow: 0 4px 12px 2px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.job-description__card .apply-btn {
  display: flex;
  font-size: 1rem;
}

.job-description .job-detail-wrapper {
  padding: 0 1rem 0 1rem;
  width: 50%;
}

.job-description__cardWrapper .head-wrapper .generic--head h2 {
  color: #033780;
  margin: 0.6rem 0 0 0;
  padding: 0;
  font-size: 3rem;
  width: 30rem;
}

.job-description .job-detail-wrapper .description {
  margin-bottom: 2rem;
}

.job-description .job-detail-wrapper .description.points .iteam {
  margin: 2.5rem 0 0 0;
}

.job-description .job-detail-wrapper .description.points .iteam span p,
.job-description .job-detail-wrapper .desccription-wrapper span p,
.job-description .job-detail-wrapper .desccription-wrapper span ul li,
.job-description .job-detail-wrapper .desccription-wrapper span ul li span,
::marker {
  color: black !important;
  font-size: 1.2rem;
}

.job-description .job-detail-wrapper .description.dots .iteam {
  position: relative;
  padding-left: 1rem;
}

.job-description .job-detail-wrapper .description.dots .iteam::before {
  content: "";
  width: 0.6rem;
  height: 0.6rem;
  background-image: linear-gradient(180deg, #033780 0, #b4299a 100%);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
}

.job-description .desccription-wrapper .iteam {
  color: #000;
  margin: 1rem 0;
}

.job-description .bottom-wrapper {
  margin-top: 1.2rem;
}

/* !Careers Detail Page End */


/* !Services Details Page Start */
.portfolio {
  position: relative;
}

.bg-portfolio {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.bg-portfolio img {
  height: 100%;
  object-fit: cover;
}

.slider-portfolio {
  padding: 2rem 0 0 0;
}

.slider-portfolio-slide {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.slider-portfolio-left {
  width: 50%;
  padding: 0 3rem;
}

.slider-portfolio-left .img-box {
  max-width: 100%;
  display: flex;
  justify-content: center;
  height: 32rem;
}

.slider-portfolio-left .img-box img {
  max-width: 100%;
}

.slider-portfolio-right {
  width: 50%;
  padding: 1rem 5rem;
}

.slider-portfolio-right .icon-wrapper {
  width: auto;
  height: auto;
  margin: 0 0 1.5rem 0;
}

.slider-portfolio-right .icon-wrapper img {
  max-width: 100%;
  width: 15rem !important;
  height: 5rem !important;
  object-fit: fill;
  object-position: bottom;
}

.slider-portfolio-slide .icon-wrapper {
  transform: translateY(10px);
  transition: 0.25s;
}

.slider-portfolio-slide .hero-main-subheading {
  transform: translateY(10px);
  transition: 0.25s;
  font-size: 2rem;
}

.slider-portfolio-slide .hero-main-section-txt {
  transform: translateY(10px);
  transition: 0.25s;
  font-size: 1.4rem;
}

.slider-portfolio-slide .anchor-wrapper {
  transform: translateY(10px);
  transition: 0.25s;
}

.slider-portfolio-slide.swiper-slide-active .icon-wrapper {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
  transition-delay: 0.1s;
}

.slider-portfolio-slide.swiper-slide-active .hero-main-subheading {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
  transition-delay: 0.2s;
}

.slider-portfolio-slide.swiper-slide-active .hero-main-section-txt {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
  transition-delay: 0.3s;
}

.slider-portfolio-slide.swiper-slide-active .anchor-wrapper {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
  transition-delay: 0.4s;
}

.portfolio .generic-navigation-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.portfolio .generic-navigation-wrapper .swiper-button-next,
.portfolio .generic-navigation-wrapper .swiper-button-prev {
  top: 40%;
}

.portfolio .generic-navigation-wrapper .swiper-button-next {
  right: 92%;
}

.portfolio .generic-navigation-wrapper .swiper-button-prev {
  left: 92%;
}

.go-to-left {
  width: 33.33%;
}

.go-to-left p {
  font-size: 1rem;
}

.hero-main .hero-main-section-txt {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.key-highlight {
  position: relative;
  background-color: #edeef3;
}

.key-highlight-cards-wrap {
  justify-content: center;
  margin: 4rem 0 0 0;
}

.key-highlight-card {
  width: 33.33%;
  text-align: center;
  border-right: 1px solid rgba(54, 54, 54, 0.2);
}

.key-highlight-card:last-child {
  border-right: none;
}

.key-highlight-card .head {
  color: #363636;
  font-size: 1.2rem;
  font-weight: 400;
}

.key-numbers {
  font-size: 6rem;
}

.ourProcess__card .card-descrip ul {
  margin: 0;
  padding: 0;
}

.ourProcess__card .card-descrip ul li span {
  font-size: 1.2rem;
}

.ourProcess__card .card-descrip ul li::marker {
  color: #c1c1c1;
  font-size: 1.2rem;
}

.ourProcess__card .card-head {
  font-size: 2.4rem;
  font-weight: 600;
  color: #363636;
  letter-spacing: -0.04rem;
  text-transform: capitalize;
  margin: 0 0 0.5rem 0;
}

.ourProcess__card .card-descrip {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ourProcess__card .card-descrip .item {
  display: flex;
  align-items: center;
  color: #363636;
  margin: 0 0 0.5rem 0;
}

.ourProcess__card .card-descrip .item svg {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 1rem 0 0;
}

.ourProcess__card .card-descrip .item svg path {
  transition: 0.25s;
}

.ourProcess__card .card-descrip .item .descrip {
  margin: 0;
}

.ourProcess__card:nth-child(even) .card-arrow {
  transform: scaleY(-1);
  top: 2rem;
}

.ourProcess__card:last-child .card-arrow {
  display: none;
}

.ourProcess__card:hover::before {
  opacity: 1;
  transition: 0.25s;
}

.ourProcess__card:hover .card-arrow svg path {
  fill: #033780;
  opacity: 1;
  transition: 0.25s;
}

.ourProcess__card:hover .card-icon {
  background-color: #033780;
  transition: 0.25s;
}

.ourProcess__card:hover .card-descrip .item svg path {
  fill: #033780;
  transition: 0.25s;
}

.ourProcess {
  position: relative;
  padding: 4rem 0 5rem 0;
}

.ourProcess__cardsWrapper .card-descrip ul li {
  font-size: 1.2rem;
  color: black;
}

.ourProcess__cardsWrapper {
  padding: 5rem 0 0 0;
}

.ourProcess__card {
  position: relative;
  width: 25%;
  padding: 0 2.5rem 2.5rem 2.5rem;
}

.ourProcess__card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(100% - 2.1875em);
  bottom: 0;
  left: 0;
  background-color: #f6f6f6;
  z-index: -1;
  border-radius: 1rem;
  opacity: 0;
  transition: 0.25s;
}

.ourProcess__card .card-arrow {
  position: absolute;
  width: 10rem;
  top: -1rem;
  right: 4rem;
}

.ourProcess__card .card-arrow svg {
  width: 14rem;
}

.ourProcess__card .card-arrow svg path {
  transition: 0.25s;
}

.ourProcess__card .card-icon {
  width: 6rem;
  height: 6rem;
  background-color: #c1c1c1;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem 0;
  transition: 0.25s;
}

.ourProcess__card .card-icon img {
  width: 4rem;
  height: 4rem;
}

/* !Services Details Page End */

/* !Floating Icons Start */
.dg-flotaing-icons {
  display: flex;
  position: fixed;
  bottom: 4rem;
  left: 2rem;
  flex-direction: column;
  z-index: 9;
}

.dg-flotaing-icons .img-box {
  margin-bottom: 1rem;
  padding: 0.8rem;
  background: #033780;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  transition: 800ms ease-in-out;
}

.dg-flotaing-icons .img-box a {
  display: flex;
  height: 100%;
  width: 100%;
}

.dg-flotaing-icons .img-box a svg {
  height: 100%;
  width: 100%;
  display: flex;
}

.dg-flotaing-icons .img-box a img {
  height: 100%;
  width: 100%;
  display: flex;
}

.whatsapp:hover {
  background: #25d366;
}

.phone:hover {
  background: #17aef3;
}

.email:hover {
  background: #dcdcdc;
}

/* !Floating Icons End */


/*!  CaseDetails Start  */
.challenges {
  background-color: #0c85ca;
  overflow: hidden;
  padding: 0;
}

.challenges .heading {
  font-weight: 500;
  font-size: 3rem;
  margin-top: 3rem;
  position: relative;
  text-align: center;
  top: 1.5rem;
}

.challenges span {
  text-align: center;
  font-weight: 400;
  display: block;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 4rem auto 4rem auto;
  width: 40%;
}

.process .process-heading {
  color: #033780;
  font-weight: 800;
  text-align: center;
  margin: 6rem auto 1rem auto;
}

.process .process-text {
  color: black;
  text-align: center;
  display: block;
  font-size: 1.4rem;
  margin: 0.5rem auto 3rem auto;
}

.process .swiper-wrapper {
  padding: 2rem 0;
}

.process .research-design__slider {
  margin: 1rem auto;
}

.process .research-design__slider .swiper-slide {
  width: 22rem;
}

.solution {
  margin: 15rem auto 8rem auto;
}

.solution h1 strong {
  color: #000000;
  font-size: 3rem;
}

.solution h3 strong {
  color: #000000;
  font-size: 1.8rem;
}

.solution p {
  color: #000000;
}

.solution button .solution-url {
  font-size: 1.6rem;
  color: #033780;
  font-weight: 900;
  text-transform: uppercase;
}

.grid-layout .img-hover-zoom {
  overflow: hidden;
}

.grid-layout .img-hover-zoom img {
  transition: transform 0.5s ease;
}

.grid-layout .img-hover-zoom:hover img {
  transform: scale(1.2);
}

.research-design__slider {
  padding: 3rem 0 0;
}

.research-design__slider--border {
  width: 16rem;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #033780;
  border-radius: 50%;
  position: relative;
  padding: 0 3rem;
  text-align: center;
}

.research-design__slider--border h5 {
  text-transform: capitalize;
}

.research-design__slider--border:before {
  content: "";
  position: absolute;
  background: #033780;
  width: 0.5rem;
  height: 0.5rem;
  right: -0.2rem;
  border-radius: 50%;
}

.research-design__slider--border:after {
  content: "";
  position: absolute;
  background: #033780;
  width: 0.5rem;
  height: 0.5rem;
  left: -0.2rem;
  border-radius: 50%;
}

.research-design__slider--slide-number {
  font-size: 5rem;
  position: absolute;
  top: -3rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.2);
  text-transform: capitalize;
  word-break: break-word;
  font-family: sans-serif;
  font-weight: 700;
  z-index: 1;
}

.research-design__slider .swiper-slide {
  position: relative;
}

.process .swiper-slide::before {
  content: "";
  position: absolute;
  background: url(../Images/dotted-line.svg) no-repeat;
  height: 0.1rem;
  left: 16rem;
  width: 10rem;
  top: 50%;
}

.process .swiper-slide:last-child::before {
  display: none;
}

.research-design__slider .swiper-slide:last-child .research-design__slider--border::before {
  display: none;
}

/*! CaseDetails End*/

/*! Case Studies Page Start*/
.case-cards-row .card {
  border: none;
  border-radius: 0;
  margin: 0 2rem;
}

.case-cards-row .img-hover-zoom {
  overflow: hidden;
}

.case-cards-row .img-hover-zoom img {
  transition: transform 0.5s ease;
}

.case-cards-row .img-hover-zoom:hover img {
  transform: scale(1.2);
}

.case-cards-row .card .card-body {
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0 0 0;
}

.case-cards-row .case-cards-col:nth-child(even) {
  margin-top: 5rem;
}

.case-cards-second-row {
  margin-top: 1rem;
}

.case-cards-second-row .card {
  border: none;
  border-radius: 0;
  margin: 0 2rem;
}

.case-cards-second-row .card .card-body {
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0 0 0;
}

.case-cards-second-row .card-body .arrow-anchor span {
  font-size: 1rem;
}

.case-cards-second-row .case2-cards-col:nth-child(2) {
  margin-top: 5rem;
}

.case-cards-second-row:last-child {
  margin-bottom: 5rem;
}

.all,
.web,
.mbl,
.seo,
.smm,
.ppc,
.print {
  margin-top: 5rem;
}

/*! Case Studies End */

/* !Social Wrap Start */
.main-hero-social-link-wrap {
  position: absolute;
  top: 45%;
  left: 4.5%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.main-hero-social-link {
  position: relative;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 100%;
  z-index: 1;
  margin: 0 0 1rem 0;
  overflow: hidden;
  transition: 0.4s;
}

.main-hero-social-link::before {
  content: "";
  position: absolute;
  width: 110%;
  height: 110%;
  border-radius: 100%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
  transition: 0.4s;
}

.main-hero-social-link .img-box {
  width: 45%;
  height: 45%;
}

.main-hero-social-link .img-box svg {
  width: 110%;
  height: 120%;
  transition: 0.4s;
  margin-top: 1%;
}

.main-hero-social-link:hover {
  transition: 0.4s;
}

.main-hero-social-link:hover::before {
  transform: translate(-50%, -50%) scale(1);
  transition: 0.4s;
}

.main-hero-social-link:hover svg path {
  fill: #363636;
  transition: 0.4s;
}

/* !Social Wrap End */

/* !Industries Start */
.serviceDevelopment {
  position: relative;
  padding: 10rem 0 6rem 0;
  overflow: hidden
}

.serviceDevelopment::before {
  content: "";
  position: absolute;
  width: 25rem;
  height: 25rem;
  top: 14%;
  left: 0;
  transform: translate(-20%, 0);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1
}

.serviceDevelopment__sliderWrapper {
  position: relative;
  padding: 5rem 0 0 0
}

.serviceDevelopment__thumbWrapper {
  width: 60%
}

.serviceDevelopment__galleryWrapper {
  width: 40%;
  height: 100%;
  padding: 0 7rem
}

.serviceDevelopment__thumb {
  position: relative
}

.serviceDevelopment__thumb::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2rem;
  background-color: rgba(54, 54, 54, .3);
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: transform .25s
}

.serviceDevelopment__thumb .swiper-slide {
  position: relative
}

.serviceDevelopment__thumb .swiper-slide::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.2rem;
  background-color: #e3204a;
  bottom: 0;
  left: 0;
  transform: scale(0);
  transition: transform .25s
}

.serviceDevelopment__thumb .swiper-slide .slide-img {
  position: relative;
  height: 16rem;
  overflow: hidden
}

.serviceDevelopment__thumb .swiper-slide .slide-img img {
  height: 100%;
  object-fit: cover
}

.serviceDevelopment__thumb .swiper-slide .slide-img .slide-count {
  position: absolute;
  bottom: -.5rem;
  right: .45rem;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #b9b9ba;
  font-size: 3rem;
  font-family: sans-serif;
  font-weight: 700;
  z-index: 1
}

.serviceDevelopment__thumb .swiper-slide .slide-head {
  color: #363636;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  padding: .7rem 0;
  opacity: .6;
  transition: opacity .25s
}

.serviceDevelopment__thumb .swiper-slide.swiper-slide-thumb-active::before {
  transform: scale(1);
  transition: transform .25s
}

.serviceDevelopment__thumb .swiper-slide.swiper-slide-thumb-active .slide-head {
  opacity: 1;
  transition: opacity .25s
}

.serviceDevelopment__gallery .swiper-slide .descrip,
.serviceDevelopment__gallery .swiper-slide .head {
  opacity: 1;
  visibility: visible;
  transform: translateY(15px);
  transition: .25s
}

.serviceDevelopment__gallery .swiper-slide.swiper-slide-active .descrip,
.serviceDevelopment__gallery .swiper-slide.swiper-slide-active .head {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: .25s;
  transition-delay: .45s
}

/* !Industries End */

/* !Testimonials Start */
.testimonial-slider .slick-next,
.testimonial-slider .slick-prev {
  display: block !important;
  border-radius: 100%;
  font-size: 2rem;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  transition: 0.25s;
  margin-left: -1rem;
}

.testimonial-slider .slick-next::before,
.testimonial-slider .slick-prev::before {
  color: #033780;
  font-size: 3.5rem;
}

.testimonial-slider .slick-next .slick-next,
.testimonial-slider .slick-prev .slick-next {
  right: 2.5rem;
}

.testimonial-slider .slick-next .slick-prev,
.testimonial-slider .slick-prev .slick-prev {
  left: 2.5rem;
}

.testimonial-slider .swiper-slide .profileImgWrapper {
  position: relative;
  width: 16rem;
  height: 20rem;
  margin: 0 auto 2rem auto;
}

.testimonial-slider .swiper-slide .transparentRadius {
  position: absolute;
  bottom: 0;
  left: -0.4rem;
  width: 16.8rem;
  height: 20rem;
  border-radius: 100%;
  z-index: 1;
  overflow: hidden;
}

.testimonial-slider .swiper-slide .transparentRadius .profileImg {
  width: 100%;
  height: 100%;
}

.testimonial-slider .swiper-slide .transparentRadius .profileImg img {
  height: 100%;
  object-fit: cover;
}

.testimonial-slider .swiper-slide .colorRadius {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 16rem;
  height: 16.5rem;
  background: linear-gradient(174.6deg,
      #033780 -4.81%,
      #0070c6 54.1%,
      rgba(217, 217, 217, 0) 88.85%);
  border-radius: 100%;
}

.testimonial-slider .swiper-slide {
  text-align: center;
}

.testimonial-slider .swiper-slide .profileName {
  color: #363636;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 0.5rem 0;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: 0.25s;
}

.testimonial-slider .swiper-slide .profileDesignation {
  color: #363636;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 0.5rem 0;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: 0.25s;
}

.testimonial-slider .swiper-slide .profileDescrip {
  position: relative;
  color: #363636;
  font-size: 1rem;
  padding: 3em 0 0 0;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: 0.25s;
}

.testimonial-slider .swiper-slide .profileDescrip::before {
  content: "“";
  position: absolute;
  font-size: 4rem;
  font-weight: 700;
  color: #033780;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}

.testimonial-slider .swiper-slide.swiper-slide-active .profileName {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
  transition-delay: 0.2s;
}

.testimonial-slider .swiper-slide.swiper-slide-active .profileDesignation {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
  transition-delay: 0.3s;
}

.testimonial-slider .swiper-slide.swiper-slide-active .profileDescrip {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition: 0.25s;
  transition-delay: 0.4s;
}

/* !Testimonials End */

/* ?Inner Banner Start*/
.about-us .top-desc p span {
  color: white !important;
  font-size: 1.4rem;
}

/* Case Studies */
.inner-banner-case .side-heading {
  position: relative;
  top: -1rem;
  width: 40rem;
}

.inner-banner-case .inner-hero-heading #hero-heading {
  font-size: 4rem;
}

.inner-banner-case span,
.inner-banner-case p {
  font-size: 1.2rem;
}

.inner-banner-case .hero-main-subheading.left-element {
  color: #033780;
  font-weight: 500;
}

.inner-banner-case .hero-main-subheading.left-element::before {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 0.1rem;
  left: 0;
  top: 50%;
  background: #033780 !important;
}

.inner-banner-case {
  background: #f4f4f4;
  position: relative;
  display: flex;
  align-items: center;
  padding: 11rem 0 2.5rem 0;
}

.inner-banner-case h6,
.inner-banner-case .inner-hero-heading .hero-main-heading,
.inner-banner-case p,
.inner-banner-case span {
  color: black;
}

.inner-banner-case::before {
  content: "";
  position: absolute;
  top: 0;
  background: url(../Images/case-study.svg) no-repeat;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.inner-banner-case .twocol-row {
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
}

.inner-hero-heading .hero-main-subheading {
  line-height: 1.5;
  letter-spacing: -0.04rem;
  margin: 0 0 2rem 0;
}

/* Case Studies End */
.inner-banner {
  background: #363636;
  position: relative;
  display: flex;
  align-items: center;
  padding: 11rem 0 4rem 0;
}

.inner-banner::before {
  content: "";
  position: absolute;
  top: 0;
  background: url(../Images/inner-banner.svg) no-repeat;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.hero-main-subheading.left-element {
  position: relative;
  padding: 0 0 0 2rem;
}

.hero-main-subheading.left-element::before {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 0.1rem;
  left: 0;
  top: 50%;
  background: #fff;
}

.inner-banner .twocol-row {
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
}

.inner-hero-heading .hero-main-heading {
  font-size: 3.2rem;
  line-height: 1.22;
  letter-spacing: -0.045rem;
  text-transform: capitalize;
  color: #fff;
}

/* ?Inner Banner End*/

/* !Blog Details Page Start */
.blog-detail-main .breadcrum-wrapper {
  margin: 0 0 2rem 0;
}

.blog-detail-main .breadcrum-wrapper span a {
  color: #363636;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0.6rem 0.6rem 0.6rem 0;
}

.blog-detail-main .breadcrum-wrapper span a:hover {
  color: #0070c6;
  cursor: pointer;
}

.blog-detail-main .breadcrum-wrapper .breadcrumb_last {
  color: #0070c6;
  font-weight: 500;
  font-size: 1.2rem;
}

.share__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.blog-detail-anchors {
  display: flex;
  width: 70%;
  margin: 1.5rem 0 0 0;
}

.blog-detail-anchors a {
  color: #666;
  text-transform: uppercase;
  margin: 0 1.5rem 0 0;
  font-size: 1rem;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.blog-detail-anchors a:hover {
  text-decoration: none;
  color: #033780;
}

.blog-detail-share-icons {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
}

.blog-detail-share-icons ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.blog-detail-share-icons li {
  margin: 0 0.5rem;
}

.blog-detail-share-icons a.share {
  margin: 0.6rem 0.3rem 0 0;
  display: block;
}

.blog--section .blog-detail-main .blog-detail-left .blog-detail-content span p {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.blog--section .blog-detail-main .blog-detail-left .blog-detail-content span ul li {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.blog-detail-main h2,
.blog-detail-main h3,
.blog-detail-main h4,
.blog-detail-main h5,
.blog-detail-main h6,
.blog-detail-main p {
  color: #000;
}

.wp-socializer:after {
  clear: both;
  content: "";
  display: block;
}

.wp-socializer.wpsr-share-icons {
  margin: 1rem 0;
}

.wpsr-share-icons .wpsr-si-inner {
  display: flex;
}

.wpsr-share-icons .wpsr-si-inner > *:first-child {
  margin-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wpsr-share-icons .wpsr-si-inner > *:first-child span h4 {
  margin: 0;
  font-size: 2rem;
  font-weight: 800;
  color: black;
}

.wpsr-share-icons .wpsr-si-inner > *:first-child p {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.5);
}

.wpsr-share-icons .wpsr-si-inner > *:last-child {
  margin-right: 0;
}

.wpsr-share-icons .socializer {
  margin: 0 1rem;
}

.wpsr-share-icons.wpsr-flex-center {
  text-align: center;
}

.wpsr-share-icons.wpsr-flex-center .wpsr-si-inner {
  justify-content: center;
}

.wp-socializer .wpsr-si-inner .socializer .ctext span {
  font-size: 0.8rem;
  color: white;
  background-color: #033780;
  padding: 0.02rem 0.2rem;
  border-radius: 50%;
  position: relative;
  right: 1rem;
  bottom: 1rem;
}

.blog-detail-right {
  width: 30%;
}

.blog-detail-right .post-block {
  display: flex;
  padding: 2rem;
  width: 100%;
  flex-wrap: wrap;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.3);
  justify-content: center;
  z-index: 99;
  background: #fff;
  height: auto;
  flex-flow: column;
  align-items: center;
}

.blog-detail-right .post-block .post-block-heading {
  font-weight: 600;
}

.blog-detail-right .post-block form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.blog-detail-right .post-block form input {
  width: 90%;
  margin: 0.6rem auto;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 1rem;
}

.blog-detail-right .post-block form select {
  width: 92%;
  margin: 0.6rem auto;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 1rem;
}

.blog-detail-right .post-block form select option {
  width: 92%;
  margin: 0.6rem auto;
  padding: 1rem;
  font-size: 1rem;
}

.blog-detail-right .post-block form label {
  width: 90%;
  color: black;
  margin: 1.2rem auto 0.2rem auto;
  font-size: 0.9rem;
}

.blog-detail-right .post-block form label span {
  width: 90%;
  color: red;
  margin: 0.2rem auto;
}

.blog-detail-right .post-block form p {
  width: 90%;
  margin: 4rem auto;
}

.blog-detail-right .post-block form .form-button {
  width: 90%;
  align-self: center;
}

.blog-detail-right .post-block form .form-button button {
  width: 100%;
}

.blog--section ul,
.blog--section ol {
  color: #000;
  font-size: 1.2rem;
}

.recent-post-cont ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.recent-post-cont {
  margin: 3rem 0;
}

.recent-post-cont li {
  display: flex;
  padding: 1rem 0;
  border-bottom: solid 1px #e7e7e7;
}

.r-post-image {
  width: 7rem;
  height: 7rem;
  margin: 0 1rem 0 0;
}

.r-post-image img {
  width: 100%;
  height: 100%;
}

.r-post-content {
  width: 68%;
}

.r-post-content a {
  color: #1f1a1c;
  font-size: 1.4rem;
  text-transform: capitalize;
  font-weight: 500;
  min-height: 6rem;
  width: 100%;
  display: block;
  transition: 0.3s;
  line-height: 1;
  text-align: left;
}

.r-post-content a:hover {
  color: #0070c6;
  text-decoration: none;
}

.r-post-content p {
  text-transform: uppercase;
  color: #667083;
  font-weight: 400;
  word-spacing: 0.4rem;
  width: 100%;
  text-align: left;
}

/* !Blog Details Page End */

#blog-container {
  color: #363636;
}

#blog-container .clearfix {
  padding: 1em;
}

.blog-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blog-pagination a.page-numbers {
  padding: 0.5em 0.8em;
  background: #033780;
  border-radius: 0.2em;
  color: #fff;
  margin: 0 0.2em;
}

.blog-pagination .page-numbers.dots {
  color: #033780;
}

.blog-pagination .current {
  border-radius: 0.2em;
  padding: 0.5em 0.8em;
  background: #033780;
  color: #fff;
}

#blog-container .blog-pagination {
  position: absolute;
  width: 9%;
  bottom: 2.5em;
  left: 50%;
  transform: translateX(-50%);
}

#blog-container .blog-pagination .swiper-paginatin-wrapper {
  display: flex;
  justify-content: space-between;
}

#blog-container .blog-pagination .swiper-paginatin-wrapper .swiper-button-next,
#blog-container .blog-pagination .swiper-paginatin-wrapper .swiper-button-prev {
  left: unset;
  right: unset;
  width: 2em;
  height: 0;
  margin-top: 0;
  top: unset;
  position: relative;
}

#blog-container .blog-pagination .swiper-paginatin-wrapper .swiper-button-next::after,
#blog-container .blog-pagination .swiper-paginatin-wrapper .swiper-button-prev::after {
  color: #033780;
}

.filter {
  position: relative;
}

.filter .case-studies__tabs-wrapper li::after {
  display: none !important;
}

.blog-list-slider {
  height: auto !important;
}

.blog-list {
  height: 100%;
  border-radius: 0.3125em;
  overflow: hidden;
  box-shadow: 0 0 15px -4px rgba(0, 0, 0, 0.59);
  -webkit-box-shadow: 0 0 15px -4px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0 0 15px -4px rgba(0, 0, 0, 0.59);
}

.blog-list .img-box {
  height: 13em;
}

.blog-list .img-box img {
  height: 100%;
  object-fit: cover;
}

.blog-list .content-wrapper {
  padding: 1em;
}

.blog-list .content-wrapper .small a {
  color: #000;
}

.blog-detail-anchors #blog-user {
  background: url(../Images/avatar.svg);
}

.blog-slider {
  position: relative;
}

.blog-slider::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  top: 59%;
  left: 0;
  background: #033780;
}

.blog-slider .scroll-bar-wrap {
  padding: 2em 0 0 0;
}

.blog .blog-slider {
  height: 50em;
}

.blog-slider-btn-prev {
  position: absolute;
  left: 1em;
  top: 16em;
}

.blog-slider-btn-next {
  position: absolute;
  right: 1em;
  top: 16em;
}

.blog-slider .swiper-slide-prev .blog-slide-date h2 {
  color: red;
  transition-property: all;
  transition-delay: 1s;
  transition-timing-function: ease-in;
  opacity: 1;
}

.blog .blog-slider:hover .cursor-dot-outline::before {
  opacity: 1;
}

.blog .blog-slider .slick-slide {
  margin-right: 80px;
}

.blog .blog-slider .slick-dots {
  z-index: 3;
}

.blog .blog-slider .slick-dots li {
  width: 150px;
  height: 3px;
  margin: 0px;
  cursor: grab;
}

.blog .blog-slider .slick-dots li button {
  width: 150px;
  height: 3px;
  cursor: grab;
  border-radius: 10px;
}

.blog .blog-slider .slick-dots li button::before {
  width: 150px;
  height: 3px;
  background: rgba(0, 0, 0, 0.1);
  content: "";
  opacity: 1;
  border-radius: 10px;
}

.blog .blog-slider .slick-dots li.slick-active button {
  border-radius: 10px;
}

.blog .blog-slider .slick-dots li.slick-active button::before {
  background: #033780;
  border-radius: 10px;
}

.blog .blog-slider .slick-dots {
  z-index: 3;
}

.blog .blog-slider .slick-dots li.slick-active button::before {
  background: #033780;
}

/* !About Page Start */
.about-second-hero-cardWrap {
  display: flex;
  flex-wrap: wrap;
  box-shadow: -6px -4px 18px 1px rgba(0, 0, 0, 0.11);
}

.about-second-hero-cardWrap-card {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-second-hero-cardWrap-card h3,
.about-second-hero-cardWrap-card span strong {
  color: black;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

.about-second-hero-cardWrap-card span p {
  color: black;
  font-size: 1.2rem;
}

.about-second-hero-cardWrap-card .hero-main-subheading {
  margin: 0 0 2rem;
}

.about-second-hero-cardWrap-card .img-box {
  object-fit: cover;
}

.experties__tabs-wrapper .col-20 :first-child p {
  font-size: 1.1rem;
  width: 30rem;
}

.experties__tabs-wrapper .col-20 :first-child {
  display: block;
}

.experties__tabs-wrapper--small-cards .img-box {
  width: 4rem;
  height: 4rem;
  margin: 0 0 1rem 0;
}

.experties__tabs-wrapper .col-20 .img-box {
  display: flex !important;
  width: 100%;
  justify-content: center;
}

.experties__tabs-wrapper--detail-tab {
  display: none;
}

.experties__tabs-wrapper--detail-tab .img-box {
  width: 17rem;
  height: 15rem;
  margin: 0 0 3rem 0;
}

.experties__tabs-wrapper--detail-tab .img-box img {
  object-fit: contain;
}

.hero-main h3 {
  font-size: 2rem;
}

.about-second-hero {
  padding: 0 0 10rem;
  position: relative;
}

.about-second-hero .about-second-hero-pilot-head span p {
  color: black;
  font-size: 1.2rem;
}

.about-second-hero::before {
  content: "";
  position: absolute;
  background: url(../Images/A.svg) no-repeat;
  width: 62rem;
  height: 56rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.about-second-hero-heading {
  position: relative;
}

.about-second-hero-heading .right-heading {
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translate(50%, -50%);
  left: -15%;
}

.about-second-hero-center {
  display: flex;
  align-items: center;
  margin: 4rem 0 0;
}

.about-second-hero-pilot-head {
  background: rgba(0, 0, 0, 0.1);
  padding: 6rem 3rem 3rem 10rem;
}

/* !About Page End */

.generic--head__head {
  width: 60%;
}

.currentOpenings__cardsWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.currentOpenings__card {
  width: 50%;
  padding: 0 1.2rem;
  margin: 0 0 2.5rem 0;
}

.currentOpenings__cardWrapper {
  padding: 3rem 2.5rem;
  border: 1px solid rgba(102, 102, 102, 0.5);
}

.currentOpenings .head-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.currentOpenings .designation-wrapper {
  display: flex;
  align-items: center;
  margin: 0 1rem 0 0;
}

.currentOpenings .designation {
  color: #363636;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 1rem 0 0;
}

.currentOpenings .depart {
  font-size: 0.8rem;
  position: relative;
  color: #363636;
  padding: 0.5rem 1rem 0.5rem 2rem;
  background: #d9d9d9;
  border-radius: 2.5em;
}

.currentOpenings .depart::before {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #363636;
  border-radius: 100%;
  top: 50%;
  left: 1rem;
  transform: translate(0, -50%);
}

.currentOpenings .location {
  font-size: 0.8rem;
  font-weight: 600;
  position: relative;
  color: #363636;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  background: #efefef;
  border-radius: 2.5rem;
}

.currentOpenings .location::before {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100%;
  top: 50%;
  left: 1rem;
  transform: translate(0, -50%);
}

.currentOpenings .descrip {
  font-size: 1rem;
  color: #363636;
  margin: 1rem 0 1.5rem 0;
}

.currentOpenings .bottom-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.currentOpenings .detail-wrapper {
  display: flex;
}

.currentOpenings .item {
  display: flex;
  align-items: center;
  margin: 0 2rem 0 0;
}

.currentOpenings .item img {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0 0.5em 0 0;
}

.currentOpenings .item span {
  color: #363636;
  font-size: 1rem;
}

.currentOpenings .apply-now {
  font-size: 1rem;
  font-weight: 700;
  text-transform: capitalize;
}

.currentOpenings .loadmore-btn {
  display: flex;
  justify-content: center;
  padding: 1.8rem 0 0 0;
}

.service-item__head {
  width: 40%;
  padding: 0 0 0 9%;
}

.services-item-list-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.services-item-list-wrap li {
  width: 33.33%;
  padding: 0 1.5rem 0 0;
  margin-bottom: 0.25rem;
  font-size: 0.7rem;
}

.services-item-list-wrap li a {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  transition: 0.25s ease-in-out;
}

.services-item-list-wrap li a:hover {
  color: #fff;
}

.services-items .skipSec {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 4.5rem;
  height: 4.5rem;
  z-index: 3;
}

.services-items .skipSec__wrapper {
  width: 100%;
  height: 100%;
  background: #033780;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-items .skipSec .head {
  font-size: 1rem;
}

.services-slider-wrap {
  position: absolute;
  width: 40%;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 7rem 0;
  z-index: 5;
  padding: 5rem 0 5rem 9%;
  display: flex;
  align-items: center;
}

.services-slider-wrap .services-slider::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
}

.services-slider-wrap .services-slider .swiper-slide {
  display: flex;
  align-items: center;
}

.services-slider-wrap .services-slider .swiper-slide .services-haeding {
  opacity: 0.2;
  transition: 0.25s all ease-in-out;
}

.services-slider-wrap .services-slider .swiper-slide .services-haeding h2 {
  font-size: 1rem;
  transition: 0.25s all ease-in-out;
}

.services-slider-wrap .services-slider .swiper-slide .services-haeding h2 a {
  color: #fff;
}

.services-slider-wrap .services-slider .swiper-slide.swiper-slide-next .services-haeding,
.services-slider-wrap .services-slider .swiper-slide.swiper-slide-prev .services-haeding {
  opacity: 0.4;
  transition: 0.25s all ease-in-out;
}

.services-slider-wrap .services-slider .swiper-slide.swiper-slide-next .services-haeding h2,
.services-slider-wrap .services-slider .swiper-slide.swiper-slide-prev .services-haeding h2 {
  font-size: 1.5rem;
  transition: 0.25s all ease-in-out;
}

.services-slider-wrap .services-slider .swiper-slide.swiper-slide-active .services-haeding {
  opacity: 1;
  transition: 0.25s all ease-in-out;
}

.services-slider-wrap .services-slider .swiper-slide.swiper-slide-active .services-haeding h2 {
  font-size: 2rem;
  transition: 0.25s all ease-in-out;
}

.services-slider-wrap .services-slider .services-haeding h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: -0.03rem;
  margin: 0;
}

.services-slider-wrap .services-slider .slick-prev,
.services-slider-wrap .services-slider .slick-next {
  display: none !important;
}

.services-slider-wrap .services-slider .slick-current {
  color: red;
}

.services-slider-wrap .services-slider .slick-current .services-haeding h2 {
  font-size: 1.3rem;
  opacity: 1;
}

.services-slider-wrap .services-slider .slick-current .services-haeding h2 a {
  color: #fff;
}

.services-slider-wrap .services-slider .services-haeding h2 {
  font-size: 1rem;
  opacity: 0.5;
}

.services-slider-wrap .services-slider .services-haeding h2 a {
  color: #fff;
}

.service-item {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.service-item .services-a {
  display: none;
}

.service-item {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.mbl-view-services-wrap {
  display: none;
}

.navbar-home .navbar-toggler {
  border: none;
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  background: #fff;
  border-radius: 0;
}

.navbar-home .navbar-toggler .navbar-toggler-icon {
  background-image: none;
  width: 2.5rem;
  height: 3.5rem;
}

.navbar-home .navbar-toggler .navbar-toggler-icon::before {
  content: "";
  width: 3rem;
  height: 0.6rem;
  position: absolute;
  background: #033780;
  transition: all 250ms ease-out;
  will-change: transform;
  left: 50%;
  transform: translate(-50%, 1em);
  top: -0.4rem;
}

.navbar-home .navbar-toggler .navbar-toggler-icon::after {
  content: "";
  width: 3rem;
  height: 0.6rem;
  position: absolute;
  background: #033780;
  transition: all 250ms ease-out;
  will-change: transform;
  left: 50%;
  transform: translate(-50%, 20px);
  top: 0.2rem;
}

.navbar-home .nav-menu .nav-menu-item .service-menu-dropdown-menu {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  text-align: left;
  margin: 0;
  list-style-type: none;
  z-index: 9;
  background: linear-gradient(57.3deg, #4f1180 28.3%, #7b22c0 88.2%);
  overflow: hidden;
}

.navbar-home .nav-menu .service-menu-dropdown .service-menu-toggleBtn {
  display: none;
}

.navbar-home .nav-menu .service-menu-dropdown .service-menu-toggleBtn::before {
  content: "";
  position: absolute;
  top: 2rem;
  transform: translate(50%, -50%);
  right: 1rem;
  width: 1rem;
  height: 0.2rem;
  background-color: #000;
}

.navbar-home .nav-menu .service-menu-dropdown .service-menu-toggleBtn::after {
  content: "";
  position: absolute;
  top: 2rem;
  transform: translate(50%, -50%);
  right: 1rem;
  width: 0.2rem;
  height: 1rem;
  background-color: #000;
  transition: 0.25s;
}

.navbar-home .nav-menu .accordion {
  margin-left: 2rem;
  width: 90%;
}

.navbar-home .nav-menu .accordion .accordion-item {
  border: none;
  box-shadow: none;
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-button {
  padding: 0.75em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 0.9rem;
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-button::after {
  background-image: none;
  content: "";
  position: absolute;
  right: 0.5rem;
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-block;
  height: 0.7rem;
  width: 0.7rem;
  transform: rotate(45deg);
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-button:not(.collapsed) {
  background-color: transparent;
  color: #033780;
  border-bottom: none;
  position: relative;
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "";
  position: absolute;
  right: 0.5rem;
  border-color: #000;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-block;
  height: 0.7rem;
  width: 0.7rem;
  transform: rotate(226deg);
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-body {
  padding: 0;
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-body .sub-inner-menu {
  list-style: none;
  padding-left: 1rem;
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-body .sub-inner-menu li {
  position: relative;
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.navbar-home .nav-menu .accordion .accordion-item .accordion-body .sub-inner-menu li a {
  color: #000 !important;
}

.navbar-home .nav-menu .accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.mySwiper {
  transition: transform 0.3s ease;
}

.text-black {
  color: #000000 !important;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-vertical .slick-slide {
  height: 5.1rem !important;
}

.slick-vertical .slick-slide .slick-active .service-thumb__head h2 {
  font-size: 2rem;
  opacity: 1;
}

.slick-vertical .slick-slide .slick-active .service-thumb__head h2 a {
  color: #fff;
}

.portfolio-slider .slick-prev,
.portfolio-slider .slick-next {
  display: none !important;
}

.portfolio-slider .slick-prev::before {
  content: "";
  background: url(../Images/serviceDetail/navigation-arrow-large.svg);
  background-repeat: no-repeat;
}

.cursor-dot,
.cursor-dot-outline {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  z-index: 1047;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.cursor-dot {
  width: 8px;
  height: 8px;
  background-color: #033780;
}

.cursor-dot-outline {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 2px solid #0070c6;
}

.cursor-dot-outline::before {
  content: "DRAG";
  position: absolute;
  width: 6rem;
  height: 6rem;
  background-color: #033780;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale3d(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  opacity: 1;
  transition: transform 0.6s, opacity 0.4s;
  z-index: 1047;
  font-size: 0.65em;
  font-weight: 700;
}

a:hover .cursor-dot-outline {
  background-color: #0070c6;
  border: 2px solid #0070c6;
}

a:hover .cursor-dot {
  opacity: 0;
}

.serviceHelp {
  display: inline-block;
}

.serviceHelp .swiper {
  display: flex !important;
}

.serviceHelp .services-list-card {
  width: 33.3%;
}

.serviceHelp .services-list-cards-sections {
  width: 78% !important;
}

.slider-portfolio .swiper-wrapper {
  display: block !important;
}

.generic-navigation-wrapper.large-btn .swiper-button-next img,
.generic-navigation-wrapper.large-btn .swiper-button-next svg,
.generic-navigation-wrapper.large-btn .swiper-button-prev img,
.generic-navigation-wrapper.large-btn .swiper-button-prev svg {
  height: 1rem;
  width: 1rem;
  margin: auto;
}

.swiper-button-next svg path {
  fill: #033780 !important;
}

.portfolio .generic-navigation-wrapper .swiper-button-next:hover svg path {
  fill: white !important;
}

.swiper-button-prev svg path {
  fill: #033780 !important;
}

.portfolio .generic-navigation-wrapper .swiper-button-prev:hover svg path {
  fill: white !important;
}

.portfolio .generic-navigation-wrapper .swiper-button-next {
  width: 7rem;
  height: 7rem;
  display: flex;
  margin-left: 6rem;
}

.portfolio .generic-navigation-wrapper .swiper-button-prev {
  width: 7rem;
  height: 7rem;
  display: flex;
  margin-right: 6rem;
}

.hidden {
  visibility: hidden;
}

.future-tech-logos .swiper-wrapper {
  justify-content: center;
  margin: 0 auto;
  width: 85% !important;
}

.future-tech-logos .swiper-wrapper .swiper-slide {
  margin-top: 2rem !important;
}

.future-tech-logos ul li,
.future-tech-logos .logo-bg {
  width: 8rem !important;
  height: 8rem;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.future-tech-logos ul li,
.future-tech-logos .logo-bg img {
  width: 4rem;
  height: 4rem;
  margin: 0;
  object-fit: contain;
}

#expHeading {
  margin: 4rem 0;
}

.pagination-btn {
  padding: 0.5rem 0.8rem;
  background: #033780;
  border-radius: 0.2rem;
  color: #fff;
  margin: 0 0.2rem;
  width: 10rem;
  font-size: 1rem;
}

.pagination-btn:disabled {
  visibility: hidden;
}


/*! ------------------------------- Media Queries ------------------------------ */

@media (min-width: 300px) and (max-width: 576px) {

  .inner-hero-heading .hero-main-subheading {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
  }

  .footer-txt-links-col:nth-child(2) a {
    color: #fff;
    font-size: 1.2rem;
    display: inline-block;
    text-decoration: none;
  }

  .slider-portfolio-left {
    width: 98vw;
  }

  .services {
    background: #ffffff;
    height: 80vh;
  }

  .blog-detail-right {
    width: 100%;
  }

  .job-description .job-detail-wrapper {
    padding: 0 1rem 0 1rem;
    width: 100%;
  }

  .footer-bg-color {
    background: none;
  }

  .experties__tabs-wrapper .col-20 :first-child p {
    width: 32rem;
  }

  .faq-slider-desc p,
  .faq-slider-desc p span {
    font-size: 1.2rem;
  }

  .blog-slide-desc {
    width: 100%;
  }

  .blog-slide-heading {
    width: 100%;
  }

  .blog-slide-image {
    min-height: auto;
  }

  .worth-read__Slide--img {
    min-height: auto;
  }

  .worth-read__Slide--head {
    width: 100%;
  }

  .convo-heading .convo-socials .convo-social-item {
    width: 3rem;
    height: 3rem;
  }

  .services-slider-wrap .services-slider .swiper-slide .services-haeding h2 {
    font-size: 1rem;
  }

  .line-heading .outline-text {
    font-size: 1.5rem;
  }

  #careersHeading {
    font-size: 3rem;
  }

  .job-description__cardWrapper .head-wrapper .generic--head h2 {
    font-size: 3rem;
    width: 100%;
    text-align: center;
  }

  .inner-hero-heading .hero-main-heading {
    font-size: 2.4rem;
    margin: 3rem auto;
  }

  .services-hero {
    height: 130vh;
  }

  p {
    font-size: 1.2rem !important;
  }

  .footer-txt-links-col:nth-child(1) .contact-info-wrap a {
    font-size: 1.2rem !important;
  }

  .footer-txt-links-col:nth-child(1) .contact-info-wrap .mail a span {
    font-size: 1.4rem !important;
  }

  .job-description__card {
    flex-direction: column;
    justify-content: center;
  }

  .case-tabs {
    margin: 1rem;
    margin-top: 2rem;
    flex-direction: column;
    overflow-x: hidden;
  }

  .case-tabs li.web-active {
    display: block;
    font-size: 1rem;
  }

  .blog-slider-btn-prev {
    position: absolute;
    left: 35vw;
    top: 46rem;
  }

  .blog-page-slider .slick-list {
    height: 120vh;
  }

  .blog-slider-btn-next {
    position: absolute;
    left: 25vw;
    top: 46rem;
  }

  .blog-slider-btn-next svg {
    width: 1.4rem;
  }

  .blog-slider-btn-prev svg {
    width: 1.4rem;
  }

  .blog-s-date h3 {
    font-size: 1rem;
  }

  .blog .swiper-slide {
    width: 90vw;
    padding: 5vw;
  }

  .pagination-btn {
    padding: 0.2rem 0.3rem;
    margin: 0 0.2rem;
    font-size: 0.8rem;
    width: 7rem;
    height: 2.5rem;
  }

  .pagination-p {
    font-size: 1rem;
  }

  .blog-page-slider .slick-list {
    height: 90vh;
  }

  .currentOpenings .head-wrapper {
    justify-content: center;
  }

  .footer .web-solutions {
    margin-left: 6rem;
  }

  .custom-next-button {
    right: 4rem;
  }

  .custom-prev-button {
    right: 8rem;
  }

  .ourWork__Slider {
    padding-left: 1rem;
  }

  .ourWork .swiper-slide .swiper-slide-prev {
    margin-right: 0;
  }

  .ourWork .line-heading .outline-text {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: unset;
    font-size: 1.8rem;
  }

  .Work-slider {
    padding-left: 1rem;
  }

  .serviceDevelopment .industries-swiper .swiper-slide {
    width: 95vw !important;
  }

  .industries-swiper .swiper-right {
    margin-top: 6rem;
    padding: 1rem;
    text-align: center;
  }

  .industries-swiper .swiper-right p {
    font-size: 1.2rem;
  }

  .Work-slider-wrap {
    width: 84vw;
    padding: 1vw;
    margin-left: 15rem;
  }

  .ourWork .Work-slider-wrap .Work-slider-img {
    width: 84vw;
    height: 50vh;
  }

  .ourWork .slick-slider {
    height: 50rem;
  }

  .ourWork .line-heading .outline-text {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: unset;
    font-size: 3rem;
    margin-left: -4rem;
  }

  .ourWork .Work-slider-desc {
    font-size: 1.4rem !important;
  }

  .brands .swiper-horizontal > .swiper-scrollbar,
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    margin: 8rem auto 0 auto;
  }

  .Work-slider .swiper-slide .dg-a-wrap .arrow-anchor .img-box {
    visibility: visible;
  }

  .Work-slider-logo {
    width: 40%;
  }

  .main-form-wrap .input-container.textArea {
    height: 5rem;
  }

  .currentOpenings .item span {
    font-size: 1rem;
  }

  .ourProcess__card {
    padding: 0 0.5rem 1.5rem 0.5rem;
    margin: 0 0 1rem 0;
  }

  .main-form-wrap .input-container {
    margin: 0 0 1.5rem 0;
  }

  .brand-logo-anchor {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    background: 0 0;
  }

  .brand-logo-anchor img {
    height: 100%;
  }

  .speak-expert-form-wrap .input-container {
    margin: 0 0 1.5rem 0;
  }

  .speak-expert-form-wrap .input-container.textArea {
    height: 5rem;
  }

  .line-heading {
    padding-left: 1rem;
  }

  .line-heading .outline-text {
    font-size: 2rem;
  }

  .faq .swiper-slide {
    width: 90vw;
    padding: 0 5vw;
  }

  .faq-slider-desc {
    margin-left: 5vw;
    padding-right: 10vw;
  }

  .faq .line-heading .outline-text {
    -webkit-text-fill-color: #000;
    -webkit-text-stroke: unset;
    font-size: 3rem;
    margin-left: -8rem;
  }

  .faq-slide-no {
    font-size: 3rem;
  }

  .faq-slider-desc h2 {
    font-size: 3rem;
  }

  .faq-box {
    padding: 0 1rem;
  }

  .brands-title {
    font-size: 1.8rem;
    width: 100%;
  }

  .brands .img-box img {
    margin: 1rem !important;
    width: 26vw;
  }

  .brands .img-box {
    height: 10rem;
    width: 100rem;
    padding: 2.8rem !important;
    object-fit: cover;
  }

  .brandSwiper .swiper-slide {
    margin-top: 0 !important;
  }

  .brands .slick-dots {
    bottom: 0;
    overflow-x: hidden;
  }

  .brands .slick-dots li {
    width: 1rem;
    overflow-x: hidden;
  }

  .brands .slick-dots li button {
    width: 1rem;
    padding: 0;
    overflow-x: hidden;
  }

  .brands .slick-dots li button::before {
    width: 1rem;
    overflow-x: hidden;
  }

  .awards .line-heading .outline-text {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: unset;
  }

  .awards-left-heading {
    font-size: 1.6rem;
  }

  #award_desc p,
  #award_desc span,
  #award_desc strong {
    font-size: 1rem;
  }

  .awards .line-heading .outline-text {
    font-size: 1.8rem;
    margin-left: -12rem;
  }

  .serviceDevelopment h2.generic--head__head {
    width: 100%;
  }

  .blog-slide {
    padding: 1rem 0 0 0;
  }

  .blog-slide-image {
    min-height: 22rem;
  }

  .blog .line-heading .outline-text {
    -webkit-text-fill-color: #000;
    -webkit-text-stroke: unset;
    font-size: 3rem;
    margin-left: -8rem;
  }

  .blog .swiper-backface-hidden .swiper-slide {
    width: 85vw !important;
  }

  .worth-read__Slider::before {
    display: none;
  }

  .worth-read__Slider {
    padding: 0 1rem;
  }

  .worth-read__Slide {
    padding: 1rem 0 0 0;
  }

  .worth-read__Slide--img {
    min-height: 22rem;
  }

  .worth-read .line-heading .outline-text {
    -webkit-text-fill-color: #000;
    -webkit-text-stroke: unset;
    font-size: 3rem;
  }

  .convo-card {
    width: 50%;
  }

  .convo-card.big-card {
    width: 100%;
  }

  .convo-heading {
    margin: 0 0 1rem 0;
  }

  .convo-heading h2 {
    font-size: 2rem;
  }

  .convo-heading .convo-socials .convo-social-item {
    width: 4.5rem;
    height: 4.5rem;
  }

  .footer {
    padding: 3rem 0 0 0;
  }

  .footer-txt-links-col:nth-child(1) .contact-info-wrap::before {
    width: 0;
  }

  .footer-txt-links-col:nth-child(1) .contact-info-wrap {
    padding: 0;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item {
    width: 100%;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item .links-menu.open {
    display: flex !important;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item .links-menu {
    padding: 0 0 1rem 2rem;
    display: none;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item .links-menu-item {
    width: 100%;
    margin: 0 0 1rem 0;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item:last-child .links-menu-item {
    padding: 0 1rem 0 0;
  }

  .footer-txt-links-col:nth-child(2) p {
    display: inline-block;
  }

  .footer-txt-links-col:nth-child(2) p::after,
  .footer-txt-links-col:nth-child(2) p::before {
    position: absolute;
    background-color: #033780;
    left: 0;
    top: 40%;
    width: 0.2rem;
    height: 1rem;
    transition: all 0.5s ease-in-out;
  }

  .footer-txt-links-col:nth-child(2) p::before {
    top: 0.25rem;
    left: 0.38rem;
  }

  .footer-txt-links-col:nth-child(2) p:after {
    left: 0;
    width: 1rem;
    height: 0.2rem;
    transform: translate(0, -50%);
  }

  .footer-txt-links-col:nth-child(2) {
    padding: 0 1rem;
    width: 100%;
  }

  .footer-txt-links-col:nth-child(2) p {
    font-size: 1.2rem !important;
    line-height: 1.5;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item .links-menu.open {
    flex-direction: column;
  }

  .footer-txt-links-col:nth-child(2) p::after,
  .footer-txt-links-col:nth-child(2) p::before {
    content: "";
  }

  .footer-txt-links-col:nth-child(2) p.opened::before {
    transform: rotate(90deg);
  }

  .footer-txt-links-col:nth-child(2) p.opened::after {
    transform: rotate(90deg);
    opacity: 0;
  }

  .footer .line-heading .outline-text {
    margin: 0 0 0.5rem 0;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: unset;
  }

  .footer-txt {
    padding: 2rem 0;
  }

  .footer-txt-links-wrapper {
    display: block;
  }

  .footer-txt-links-col:nth-child(1) {
    align-items: center;
    padding: 0 1rem;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap {
    flex-wrap: wrap;
  }

  .services-hero-form {
    width: 100%;
  }

  .services-hero-social-wrap {
    display: none;
  }

  .services-main-container {
    padding: 0 1rem;
  }

  .services-list-cards-sections {
    flex-wrap: nowrap;
  }

  .services-list-card {
    padding: 1rem;
  }

  .services-list-card .services-list-card-wrap {
    padding: 2.7rem 2rem;
    box-shadow: 0 -1px 21px rgba(56, 61, 77, 0.08);
  }

  .services-list-card .services-list-card-wrap .arrow-anchor .img-box {
    box-shadow: 0 0 9px 6px rgba(136, 136, 136, 0.18);
  }

  .services-list-card {
    width: 100%;
  }

  .services-list-card .card-icon {
    background-color: #033780;
    transition: background-color 0.25s;
  }

  .services-list-card .arrow-anchor {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s visibility 0.25s;
  }

  .services-list-card .arrow-anchor {
    opacity: 1;
    visibility: visible;
  }

  .services-list-card:hover .services-list-card-wrap {
    box-shadow: 0 -1px 21px rgba(56, 61, 77, 0.08);
    transition: box-shadow 0.25s;
  }

  .services-list-card:hover .card-icon {
    background-color: #033780;
    transition: background-color 0.25s;
  }

  .services-list-card:hover .arrow-anchor {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.25s visibility 0.25s;
  }

  .job-description__card .item span {
    font-size: 1rem;
  }

  .job-description__cardWrapper {
    width: 100%;
  }

  .job-description__cardWrapper .head-wrapper {
    justify-content: center;
  }

  .portfolio {
    padding: 7rem 0 1rem 0;
  }

  .slider-portfolio {
    padding: 1rem 0 0 0;
  }

  .process .swiper-slide::before {
    display: none;
  }

  .about-casestudy .generic--head__sec-para {
    font-size: 1.2rem;
    margin: 1rem;
  }

  .slider-portfolio-left .img-box {
    height: auto;
  }

  .key-highlight-card:nth-child(odd) {
    border-right: none;
  }

  .key-highlight-card {
    width: 100%;
  }

  .key-highlight-card .head {
    font-size: 1.2rem;
  }

  .ourProcess__cardsWrapper {
    padding: 5rem 0 0 1rem;
  }

  .key-highlight-card .count {
    font-size: 3rem;
  }

  .ourProcess__card .card-icon {
    width: 4rem;
    height: 4rem;
    margin: 0 0 1rem 0;
  }

  .ourProcess__card .card-icon img {
    width: 2rem;
    height: 2rem;
  }

  .ourProcess__card .card-descrip .item .descrip {
    width: calc(100% - 1.6rem);
  }

  .research-design__slider {
    padding: 2rem 0 0;
  }

  .research-design__slider--border {
    width: 14rem;
    height: 14rem;
    padding: 0 1rem;
  }

  .process .swiper-wrapper {
    padding: 2.5rem 0;
  }

  .research-design__slider--slide-number {
    font-size: 4rem;
  }

  .research-design__slider .swiper-slide::before {
    display: none;
  }

  .solution {
    margin-top: 0;
  }

  .serviceDevelopment__sliderWrapper {
    padding: 2rem 0 0 0
  }

  .serviceDevelopment__thumbWrapper {
    width: 100%;
    padding: 0
  }

  .serviceDevelopment__galleryWrapper {
    width: 100%;
    padding: 0 1rem
  }

  .serviceDevelopment__galleryWrapper .generic-navigation-wrapper {
    margin: 0 auto
  }

  .serviceDevelopment__thumb .swiper-slide.swiper-slide-thumb-active::before {
    transform: scale(0)
  }

  .serviceDevelopment__gallery {
    padding: 2rem 0 0 0
  }

  .serviceDevelopment__gallery .swiper-slide .descrip,
  .serviceDevelopment__gallery .swiper-slide .head {
    text-align: center
  }

  .hero-main-subheading.left-element {
    padding: 3rem 0 0 0;
    letter-spacing: 0 !important;
  }

  .hero-main-subheading.left-element::before {
    content: normal;
  }

  #blog-container .blog-pagination {
    width: 25%;
    bottom: 2.5rem;
  }

  .blog-slider {
    padding: 0 1rem;
  }

  .blog-slider::before {
    display: none;
  }

  .blog-list .img-box {
    height: 10rem;
  }

  .dg-flotaing-icons .img-box {
    width: 3rem !important;
    height: 3rem !important;
  }

  .mbl-view {
    font-size: 1.2rem;
  }

  .about-second-hero-pilot-head {
    padding: 2rem;
  }

  .about-second-hero-center {
    margin: 0;
  }

  .about-second-hero-heading .right-heading {
    position: relative;
    top: auto;
    transform: none;
    left: 0;
    display: flex;
    width: 100%;
    padding-top: 1rem;
  }

  .about-second-hero {
    padding: 0;
  }

  .about-second-hero-cardWrap-card:nth-child(3) .img-box {
    margin: 0;
  }

  .about-second-hero-cardWrap-card {
    width: 100%;
  }

  .about-second-hero::before {
    display: none;
  }

  .currentOpenings__card {
    padding: 0;
  }

  .currentOpenings__cardWrapper {
    padding: 1.2rem 1.5rem;
  }

  .currentOpenings .designation-wrapper {
    flex-wrap: wrap;
    margin: 0 0 1rem 0;
  }

  .currentOpenings .designation {
    width: 100%;
    margin: 0 0 0.5rem 0;
  }

  .currentOpenings .item {
    margin: 0 0.7rem 0 0;
  }

  .currentOpenings .item img {
    width: 0.8rem;
    height: 0.8rem;
  }

  .currentOpenings .loadmore-btn {
    padding: 0;
  }

  .services-itemImg .img-box {
    width: 100%;
    height: auto;
  }

  .services-itemImg .img-box img {
    height: auto;
    visibility: hidden;
  }

  .service-bg img {
    visibility: hidden;
  }

  .services-itemImg .description-box {
    display: none;
  }

  .services .line-heading {
    margin: 0;
    padding: 2rem 0 0 1rem;
    top: 0;
  }

  .services .line-heading .outline-text {
    -webkit-text-fill-color: #000;
    -webkit-text-stroke: unset;
  }

  .services .line-heading .outline-text {
    -webkit-text-fill-color: #000;
    -webkit-text-stroke: unset;
    font-size: 3rem;
    margin-left: -25rem !important;
  }

  .services-items,
  .services-wrap {
    height: 65vh;

  }

  .services-slider-wrap {
    padding: 4rem 0 2rem 0;
    display: none;
  }

  .services-slider-wrap .services-slider {
    padding: 0;
  }

  .services-slider-wrap .services-slider .swiper-slide {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0 1rem;
  }

  .services-slider-wrap .services-slider .swiper-slide .services-haeding h2 a {
    color: #000;
  }

  .services-slider-wrap .services-slider .services-haeding h2 {
    font-size: 2rem;
  }

  .service-item .services-a {
    display: none;
  }

  .notfound_text h4 span {
    font-size: 10rem;
  }

  .services-dropdown {
    display: none;
  }

  .home-hero .head {
    margin: 0 auto;
    font-size: 2.5rem;
    text-align: center;
  }

  .main-hero-banner-desc {
    font-size: 1.4rem;
  }

  .main-hero .arrow-anchor .img-box {
    visibility: visible;
  }

  .container {
    padding: 0 1rem;
  }

  .footer .line-heading .outline-text {
    margin-left: -20vw;
    font-size: 2.6rem;
  }

  .mbl-view-services-wrap {
    z-index: 999;
    width: 88vw;
  }

  .mbl-view-services-wrap li a {
    color: black;
  }

  .convo .convo-card-img img {
    height: 50vw;
  }

  .footer-txt-links-col:nth-child(1) .left-desc h2 {
    font-size: 2rem !important;
  }

  .services-main-wrap {
    flex-direction: column;
  }

  .services-main-heading {
    width: 95vw;
    padding: 0 0 0 0;
  }

  .services-hero-form {
    width: 95vw;
  }

  .services-hero-form .arrow-anchor .img-box {
    visibility: visible;
  }

  .serviceHelp .swiper {
    flex-direction: column;
  }

  .services-list-heading-section {
    width: 90vw;
    padding: 5vw;
    font-size: 1rem;
  }

  .serviceHelp .services-list-cards-sections {
    flex-direction: column;
  }

  .serviceHelp .services-list-card {
    width: 90vw;
    padding: 5vw;
  }

  .services-list-card .arrow-anchor {
    opacity: 1;
    visibility: visible;
  }

  .portfolio .generic-navigation-wrapper {
    width: 30rem;
    height: 3.7rem;
    top: 2rem;
    right: 4rem;
  }

  .go-to-left {
    width: 100%;
  }

  .generic-navigation-wrapper.large-btn .swiper-button-next {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
  }

  .generic-navigation-wrapper.large-btn .swiper-button-prev {
    width: 3rem;
    height: 3rem;
  }

  .slider-portfolio-right {
    width: 100vw;
    padding: 3vh;
  }

  .ourProcess__cardsWrapper .ourProcess__card {
    width: 45vw;
  }

  .about-second-hero-heading .right-heading .line-heading .outline-text.primary-color {
    font-size: 2rem;
  }

  .experties .line-heading .outline-text,
  .line-heading .outline-text.primary-color {
    font-size: 2.8rem !important;
  }

  .testimonial-slider {
    width: 80vw;
    margin: 5vw;
  }

  .our-culture-leftside .line-heading {
    font-size: 0.6rem;
  }

  .navbar-home.sticky {
    background-color: rgba(0, 0, 0, 0.75);
    overflow-x: hidden;
  }

  .brand-logo {
    background-color: transparent;
  }

  .navbar-home a {
    color: #000;
    width: 8rem;
    height: 3rem;
    margin-left: 0.4rem;
  }

  .navbar-home .dg-social-wrapper .dg-a-wrap.project-a a {
    display: none;
    overflow-x: hidden;
  }

  .navbar-home .dg-social-wrapper .dg-a-wrap a {
    display: none;
    overflow-x: hidden;
  }

  .navbar-home .brand-logo-anchor .web-logo {
    overflow-x: hidden;
  }

  .navbar-home .brand-logo-anchor .mob-logo {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    overflow-x: hidden;
  }

  .navbar-home .nav-menu {
    padding: 1rem 1rem 2rem 1rem;
    background: #fff;
    overflow-x: hidden;
  }

  .navbar-home .nav-menu .nav-menu-item .service-menu-dropdown-menu {
    display: none;
    overflow-x: hidden;
  }

  .navbar-home .nav-menu .nav-menu-item {
    position: relative;
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    overflow-x: hidden;
  }

  .navbar-home .nav-menu .nav-menu-item .nav-menu-item-link a {
    color: #000;
    font-size: 1.2rem;
    overflow-x: hidden;
    width: 80%;
  }

  .navbar-home .nav-menu .service-menu-dropdown .service-menu-toggleBtn {
    display: block;
    overflow-x: hidden;
  }

  .blog .blog-slider {
    height: 50rem;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-slide {
    margin-right: 0;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots {
    bottom: 0;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots li {
    width: 1rem;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots li button {
    width: 1rem;
    padding: 0px;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots li button::before {
    width: 1rem;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots {
    bottom: 0;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots li {
    width: 2rem;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots li button {
    width: 2rem;
    overflow-x: hidden;
  }

  .blog .blog-slider .slick-dots li button::before {
    width: 2rem;
    overflow-x: hidden;
  }

  .offcanvas.offcanvas-start {
    width: 100vw;
    overflow-x: hidden;
  }

  .cursor-dot,
  .cursor-dot-outline {
    display: none;
    overflow-x: hidden;
  }

  .case-tabs li {
    margin: 0 0.6rem 1rem 0;
    overflow-x: hidden;
  }

  .case-tabs li.mbl-tab {
    display: block;
    overflow-x: hidden;
  }

  .case-tabs li.web-active {
    display: none;
    overflow-x: hidden;
    text-align: center;
  }

  .case-tabs li.mbl-active {
    display: block;
    overflow-x: hidden;
  }

  .case-cards-row .card {
    margin: 2rem 0;
    overflow-x: hidden;
  }

  .case-cards-row .case-cards-col:nth-child(even) {
    margin-top: 0;
    overflow-x: hidden;
  }

  .case-cards-second-row .card {
    margin: 2rem 0;
    overflow-x: hidden;
  }

  .case-cards-second-row .case2-cards-col:nth-child(2) {
    margin-top: 0;
    overflow-x: hidden;
  }

  .all,
  .web,
  .mbl,
  .seo,
  .smm,
  .ppc,
  .print {
    margin-top: 0;
    overflow-x: hidden;
  }

  .inner-banner-case .inner-hero-heading #hero-heading {
    font-size: 3rem;
  }

  .challenges span {
    width: 90vw;
  }

  .job-description .job-detail-wrapper .description.points .iteam span p,
  .job-description .job-detail-wrapper .desccription-wrapper span ul li,
  .job-description .job-detail-wrapper .desccription-wrapper span ul li span,
  ::marker {
    color: black;
  }

  .service-item .dg-a-wrap a .arrow-anchor {
    visibility: hidden;
  }

  .apply-now-canvas {
    max-width: 100vw !important;
    height: 90vh !important;
    padding-top: -10vw;
    padding-bottom: 0;
    overflow: scroll;
  }

  .speak-expert-canvas .offcanvas-body {
    overflow: scroll;
  }

  .mbl-view-services-wrap {
    display: block !important;
    list-style: none;
    color: #000;
    position: absolute;
    top: 70px;
    width: 100%;
    margin: 0 1rem;
  }

  .mbl-view-services-wrap .mbl-view-service-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 1rem;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .ourProcess__card .card-arrow {
    display: none;
  }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
  .experties__tabs-wrapper .col-20 :first-child p {
    font-size: 1.2rem;
    width: 100%;
  }

  .portfolio .generic-navigation-wrapper {
    width: 10rem;
    height: 3.7rem;
    top: 2rem;
    right: 4rem;
  }

  .ourProcess__card .card-arrow {
    display: none;
  }

  .slider-portfolio-left {
    width: 100%;
  }

  .go-to-left {
    width: 100%;
  }

  .slider-portfolio-right {
    width: 90vh;
    padding: 5vh;
    margin-top: 7rem;
  }

  .custom-next-button {
    right: 10rem;
    bottom: -18%;
  }

  .testimonial-slider .slick-next {
    margin-right: 3rem;
  }

  .custom-prev-button {
    right: 14rem;
    bottom: -18%;
  }

  .industries-swiper .swiper-right {
    height: 100%;
    margin: 2rem;
  }

  .services-items,
  .services-wrap {
    height: 60vh;
  }

  .job-description .job-detail-wrapper {
    padding: 0 1rem 0 1rem;
    width: 100%;
  }

  .footer-txt-links-col:nth-child(2) {
    width: 100%;
  }

  .footer-bg-color {
    background: none;
  }

  .brands-title {
    font-size: 1.7rem;
  }

  .services-slider-wrap .services-slider {
    display: none;
  }

  .services-slider-wrap {
    visibility: hidden;
  }

  .awards-bg-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
  }

  .awards-wrap {
    min-height: auto;
  }

  .faq-slider-desc h2 {
    font-size: 1.7rem;
  }

  .faq-slide-no {
    font-size: 1.7rem;
  }

  .faq-box {
    padding: 0 3rem;
  }

  .footer-txt-links-col:nth-child(1) {
    margin: 0;
  }


  .main-hero-social-link-wrap {
    display: none;
  }

  .blog-detail-anchors a {
    font-size: 1rem;
  }

  .blog-detail-right {
    width: 100%;
    margin-top: 0;
  }

  .r-post-content a {
    font-size: 1.2rem;
    min-height: auto;
    margin-bottom: 0.5rem;
  }

  .service-item__head {
    width: 100%;
    margin-bottom: 4rem;
    padding: 0 1rem;
    text-align: center;
  }

  .services-itemImg {
    width: 100%;
  }

  .services .line-heading {
    margin: 0;
    padding: 2rem 0;
    top: 0;
  }

  .services {
    background: #fff;
  }

  .services-slider-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    padding: 10rem 0 0 0;
    text-align: center;
  }

  .services-slider-wrap .services-slider {
    height: 100%;
  }

  .services-slider-wrap .services-slider .swiper-wrapper {
    display: grid;
  }

  .services-slider-wrap .services-slider .swiper-slide .services-haeding {
    opacity: 1;
  }

  .services-slider-wrap .services-slider .swiper-slide.swiper-slide-next .services-haeding,
  .services-slider-wrap .services-slider .swiper-slide.swiper-slide-prev .services-haeding {
    opacity: 1;
  }

  .services-slider {
    display: none;
  }

  .mbl-view-services-wrap {
    display: block !important;
    list-style: none;
    color: #000;
    position: absolute;
    top: 70px;
    width: 100%;
    margin: 0 1rem;
  }

  .mbl-view-services-wrap .mbl-view-service-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 1rem;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .case-tabs {
    margin: 1rem;
    margin-top: 2rem;
    flex-direction: column;
    overflow-x: hidden;
  }

  .case-tabs li.web-active {
    display: block;
    font-size: 1rem;
  }

  .blog-slider-btn-prev {
    position: absolute;
    left: 35vw;
    top: 58rem;
  }

  .blog-slider-btn-next {
    position: absolute;
    left: 25vw;
    top: 58rem;
  }

  .blog-slider-btn-next svg {
    width: 1.8rem;
  }

  .blog-slider-btn-prev svg {
    width: 1.8rem;
  }

  .navbar-nav .dg-social-wrapper {
    margin-top: 8rem;
    height: 2rem;
  }

  .home-hero .head {
    font-size: 3rem;
    width: 100%;
    margin-top: 3rem;
  }

  .main-hero-banner-desc {
    font-size: 1.2rem;
  }

  .main-hero-banner .dg-a-wrap {
    display: flex;
    width: 13rem;
    height: 2.4rem;
  }

  .mbl-view-services-wrap li a {
    color: black;
  }

  .convo .convo-card-img img {
    height: 26vw;
  }

  .line-heading .outline-text {
    font-size: 3.5rem;
  }

  .case-tabs {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }

  .about-casestudy .generic--head__sec-para {
    margin: 1rem;
    width: 100%;
  }

  .slick-initialized .slick-slide {
    margin-top: 2rem;
  }

  .blog-page-slider .slick-list {
    height: 60vh;
  }

  .services-main-wrap {
    flex-direction: column;
  }

  .services-main-heading {
    width: 95vw;
    padding: 0 0 0 0;
  }

  .services-hero-form {
    width: 85vw;
    padding: 5vw;
  }

  .services-hero-form .arrow-anchor .img-box {
    visibility: visible;
  }

  .serviceHelp .swiper {
    flex-direction: column;
  }

  .services-list-heading-section {
    width: 90vw;
    padding: 5vw;
    font-size: 1rem;
  }

  .serviceHelp .services-list-cards-sections {
    flex-direction: column;
  }

  .serviceHelp .services-list-card {
    width: 90vw;
    padding: 5vw;
  }

  .services-list-card .arrow-anchor {
    opacity: 1;
    visibility: visible;
  }

  .generic-navigation-wrapper.large-btn .swiper-button-next {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
  }

  .generic-navigation-wrapper.large-btn .swiper-button-prev {
    width: 3rem;
    height: 3rem;
  }

  .slider-portfolio-right {
    width: 90vh;
    padding: 5vh;
  }

  .ourProcess__cardsWrapper .ourProcess__card {
    width: 40vw;
  }

  .nav-menu {
    padding: 1rem 1rem 2rem 1rem;
  }

  .about-second-hero-heading .right-heading {
    margin-left: 10rem;
  }

  .job-description .job-detail-wrapper .description.points .iteam span p,
  .job-description .job-detail-wrapper .desccription-wrapper span ul li,
  .job-description .job-detail-wrapper .desccription-wrapper span ul li span,
  ::marker {
    color: black;
  }

  .apply-now-canvas {
    max-width: 100vw !important;
    height: 100vh !important;
    padding-top: -10vw;
    padding-bottom: 0;
  }

  .services-list-card .services-list-card-wrap {
    box-shadow: 0 -1px 21px rgba(10, 10, 10, 0.08);
    transition: box-shadow 0.25s;
  }

  .services-dropdown {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .key-highlight-card .head {
    margin: 0 0 0.5rem 0;
  }

  .portfolio .generic-navigation-wrapper {
    width: 10rem;
    height: 3.7rem;
    top: 2rem;
    right: 4rem;
  }

  .footer-bg-color {
    background: none;
  }

  .blogSliderSection {
    height: 48vw;
  }

  .line-heading #servicesHeading {
    font-size: 5rem;
  }

  .blog-detail-anchors {
    width: 100%;
  }

  .ourProcess__cardsWrapper {
    padding: 3rem 0 0 0;
  }

  .ourProcess__card {
    width: 50%;
  }

  .ourProcess__card .card-arrow {
    display: none;
  }

  .ourProcess {
    padding: 3rem 0;
  }

  .service-item {
    height: 75vh;
  }

  .slider-portfolio-right {
    width: 100%;
  }

  .serviceDevelopment {
    padding: 3rem 0;
    margin-top: 10rem;
  }

  .serviceDevelopment__thumbWrapper {
    width: 50%
  }

  .serviceDevelopment__galleryWrapper {
    width: 50%;
    padding: 0 2rem
  }

  .col-md-5 {
    width: 40% !important;
  }

  .custom-next-button {
    right: 14rem;
    bottom: 8%;
  }

  .custom-prev-button {
    right: 18rem;
    bottom: 8%;
  }

  .inner-banner-case {
    padding: 9rem 0 2.5rem 0;
  }

  .blog-pagination {
    bottom: 0;
  }

  #blog-container .blog-pagination {
    bottom: 2rem;
  }

  .go-to-left {
    width: 100%;
  }

  .key-highlight-card {
    width: 50%;
    border-right: none;
    margin: 0 0 1.5rem 0;
  }

  .key-highlight-card:nth-child(odd) {
    border-right: 1px solid rgba(54, 54, 54, 0.2);
  }

  .faq-box {
    padding: 0 5rem;
  }

  .awards__rightsec--card a {
    padding: 0.5rem;
  }

  .awards__leftsec {
    padding: 0;
  }

  .awards-left {
    padding: 0;
  }

  .awards-right-card a {
    padding: 0.5rem;
  }

  .ourWork {
    padding: 3rem 0;
  }

  .line-heading .outline-text {
    font-size: 4rem;
  }

  .services-item-list-wrap li a {
    font-size: 1rem;
  }

  .services-items,
  .services-wrap {
    height: 75vh;
  }

  .services-slider-wrap .scroll-text {
    display: none;
  }

  .service-item {
    height: 75vh;
  }

  .services-slider-wrap {
    height: 75vh;
    padding: 5rem 0 5rem 5rem;
  }

  .services-hero-form {
    width: 70%;
    margin: 0 auto;
    padding: 0;
  }

  .job-description .job-detail-wrapper {
    width: 100%;
    padding: 0;
  }

  .portfolio {
    padding: 7rem 0 5rem 0;
  }

  .slider-portfolio-left {
    width: 100%;
    padding: 0;
  }

  .services-hero-form .main-form .input-container .input {
    font-size: 1rem;
  }

  .services-main-container {
    padding: 0 5rem;
  }

  .services-main-heading {
    width: 100%;
    padding: 3rem 2rem 3rem 0;
  }

  .services-main-heading {
    padding: 1rem 2rem 1rem 0;
  }

  .services-list-card .services-list-card-wrap {
    padding: 2.25rem 1.25rem;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item .links-menu-item {
    margin: 0 0 1.5rem 0;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item .links-menu-item.address {
    width: 100%;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item:last-child .links-menu-item {
    padding: 0;
  }

  .footer-txt {
    padding: 3rem 0;
  }

  .footer-txt-links-wrapper {
    justify-content: center;
  }

  .footer-txt-links-col:nth-child(1) {
    width: 100%;
    padding: 0 5rem;
  }

  .footer-txt-links-col:nth-child(2) {
    width: 100%;
    padding: 0 5rem;
  }

  #blog-container .blog-pagination .swiper-paginatin-wrapper .swiper-button-next::after,
  #blog-container .blog-pagination .swiper-paginatin-wrapper .swiper-button-prev::after {
    font-size: 2rem;
  }

  .blog-list .img-box {
    height: 11rem;
  }

  .about-second-hero-heading .right-heading {
    top: 112%;
    transform: translate(50%, -50%);
    left: -70%;
  }

  .currentOpenings__card {
    width: 100%;
  }

  .services-item-list-wrap {
    padding-left: 1rem;
  }

  .services-item-list-wrap li {
    width: 50%;
  }

  .r-post-content a {
    font-size: 1rem;
  }

  .blog-detail-right {
    width: 100%;
  }

  .blog-detail-share-icons li:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  .blog-detail-share-icons {
    width: 100%;
    justify-content: flex-start;
    margin-top: 1.5rem;
  }

  .experties__tabs-wrapper .col-20 :first-child p {
    width: 100%;
    font-size: 1.2rem;
  }

  .services-section .swiper-slide h2 a {
    font-size: 1.4rem;
  }

  .services-section .swiper-slide-active h2 a {
    font-size: 2rem !important;
  }

  .services-section .swiper-slide-prev h2 a,
  .services-section .swiper-slide-next h2 a {
    font-size: 1.8rem !important;
  }

  .Work-slider-logo {
    width: 40%;
  }

  .ourWork .mySwiper .swiper-slide:first-child {
    margin-left: 10rem;
  }

  .ourWork .mySwiper .swiper-slide {
    width: 20rem !important;
  }

  .brands-title {
    width: 100%;
  }

  .awards-bg-img {
    height: 120%;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .blog-slide-image {
    min-height: 38rem !important;
  }

  .process {
    padding: 0 5rem;
  }

  .inner-banner-case .side-heading {
    top: 0;
  }

  .navbar-nav .dg-social-wrapper {
    margin-top: 8rem;
    height: 2rem;
  }

  .home-hero .head {
    font-size: 4rem;
    width: 100%;
  }

  .main-hero-banner-desc {
    font-size: 1.2rem;
  }

  .main-hero-banner .dg-a-wrap {
    display: flex;
    width: 13.2rem;
    height: 2.4rem;
  }

  .convo .convo-card-img img {
    height: 26vw;
  }

  .line-heading .outline-text {
    font-size: 3.5rem;
  }

  .case-tabs {
    flex-wrap: wrap;
    row-gap: 1.6rem;
  }

  .process .swiper-horizontal {
    margin: 0 -8rem;
    padding-top: 1rem;
  }

  .about-casestudy .generic--head__sec-para {
    margin: 1rem;
    width: 100%;
  }

  .slick-initialized .slick-slide {
    margin-top: 2rem;
  }

  .blog-page-slider .slick-list {
    height: 80vh;
  }

  .services-main-wrap {
    flex-direction: column;
  }

  .services-main-heading {
    width: 95vw;
    padding: 0 0 0 0;
  }

  .services-hero-form {
    width: 85vw;
    padding: 5vw;
  }

  .services-hero-form .arrow-anchor .img-box {
    visibility: visible;
  }

  .serviceHelp .swiper {
    flex-direction: column;
  }

  .services-list-heading-section {
    width: 90vw;
    padding: 5vw;
    font-size: 1rem;
  }

  .serviceHelp .services-list-cards-sections {
    flex-direction: column;
  }

  .serviceHelp .services-list-card {
    width: 90vw;
    padding: 5vw;
  }

  .services-list-card .arrow-anchor {
    opacity: 1;
    visibility: visible;
  }

  .generic-navigation-wrapper.large-btn .swiper-button-next {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
  }

  .generic-navigation-wrapper.large-btn .swiper-button-prev {
    width: 3rem;
    height: 3rem;
  }

  .slider-portfolio-right {
    width: 90vh;
    padding: 5vh;
    margin-top: 7rem;
  }

  .ourProcess__cardsWrapper .ourProcess__card {
    width: 40vw;
  }

  .nav-menu {
    padding: 1rem 1rem 2rem 1rem;
  }

  .about-second-hero-heading .right-heading {
    margin-left: 16rem;
  }

  .job-description .job-detail-wrapper .description.points .iteam span p,
  .job-description .job-detail-wrapper .desccription-wrapper span ul li,
  .job-description .job-detail-wrapper .desccription-wrapper span ul li span,
  ::marker {
    color: black;
  }

  .apply-now-canvas {
    max-width: 100vw !important;
    height: 100vh !important;
    padding-top: -10vw;
    padding-bottom: 0;
  }

  .services-list-card .services-list-card-wrap {
    box-shadow: 0 -1px 21px rgba(10, 10, 10, 0.08);
    transition: box-shadow 0.25s;
  }

  .blog-detail-left {
    width: 100vw;
  }

  .speak-expert-form-wrap .input-container .input-select option {
    font-size: 10px;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1199px) {
  .nav-menu-item-link {
    font-size: 1.2rem;
  }

  .blog-detail-right {
    width: 30%;
  }

  .Work-slider .swiper-slide .dg-a-wrap {
    opacity: 1;
  }

  .brands-title {
    width: 100%;
    font-size: 2.2rem;
  }

  .faq-slider-desc h2 {
    font-size: 2.2rem;
  }

  .faq-slide-no {
    font-size: 2.2rem;
  }

  .blog-slide-image {
    min-height: auto;
  }

  .footer-txt-links-col:nth-child(1) {
    padding: 0 2rem;
  }

  .footer-txt-links-col:nth-child(2) {
    padding: 0 2rem;
  }

  .serviceDevelopment__galleryWrapper {
    padding: 0 3rem;
  }

  .convo-heading .convo-socials .convo-social-item a svg {
    height: 2rem;
  }

  .ourWork .Work-slider-wrap .Work-slider-img {
    height: 32rem;
    width: 28rem;
    filter: grayscale(1);
    transition: 0.4s;
  }

  .faq-slider-desc p {
    font-size: 1.2rem;
  }

  .faq-slider-arrow {
    padding: 0.5rem;
  }

  .line-heading .outline-text {
    font-size: 5rem;
  }

  .speak-expert-form-wrap .input-container .input {
    margin: 0;
  }

  .main-form-wrap .input-container .input {
    margin: 0;
  }

  .arrow-anchor .img-box svg {
    width: 1.5rem;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap {
    justify-content: space-between;
  }

  .footer-txt-links-col:nth-child(2) .links-col-wrap .links-col-item {
    padding-right: 1.5rem;
  }

  .services-main-heading {
    padding: 0 2rem 0 0;
  }

  .services-list-heading-section {
    width: 50%;
    padding: 0;
  }

  .services-list-cards-sections {
    width: 100%;
  }

  .slider-portfolio-right {
    padding: 2rem 0 0 0;
  }

  .services-slider-wrap .services-slider .swiper-slide.swiper-slide-next .services-haeding h2,
  .services-slider-wrap .services-slider .swiper-slide.swiper-slide-prev .services-haeding h2 {
    font-size: 1rem;
  }

  .services-slider-wrap .services-slider .swiper-slide.swiper-slide-active .services-haeding h2 {
    font-size: 1rem;
  }

  .services-dleft {
    padding-top: 6rem;
    justify-content: flex-start;
  }

  .services-dropdown {
    width: 100%;
    height: 30vh;
    background-color: #033780;
    position: fixed;
    margin-top: 4.6rem;
    z-index: 999;
  }

  .main-hero-social-link .img-box svg {
    width: 100%;
    height: 100%;
    margin-top: 1%;
  }

  .services-slider-wrap {
    height: 47vh;
  }

  .navbar-home a {
    font-size: 1.2rem;
  }

  .services-itemImg {
    margin-top: -60rem;
  }

  .services-items,
  .services-wrap {
    height: 60vh;
  }

  .home-hero .head {
    font-size: 5rem;
  }

  .navbar-home .dg-social-wrapper .dg-a-wrap.project-a a {
    font-size: 1rem;
  }

  .brands-title {
    font-size: 2.8rem;
  }

  .nav-menu-item {
    font-size: 1rem;
  }

  .ourWork .mySwiper .swiper-slide:first-child {
    margin-left: 1rem;
  }

  .faq-slider-d p,
  .faq-slider-d p span {
    font-size: 1.4rem !important;
  }

  .main-hero-banner-desc {
    font-size: 1.4rem;
  }

  .faq-slide-haeding {
    font-size: 1.6rem;
  }

  .faq-slide-no {
    font-size: 4rem;
  }

  .blog-slide-image {
    min-height: 32rem !important;
  }

  .faq-slider-desc h2 {
    font-size: 4rem;
  }

  .footer-txt-links-col:nth-child(1) {
    width: 40%;
  }

  .services-hero {
    position: relative;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .ourWork .Work-slider-desc {
    font-size: 1.2rem !important;
    width: 88%;
  }

  .blog-slide:first-child {
    margin-left: -5rem;
  }

  .awards .line-heading .outline-text {
    font-size: 3.4rem;
  }

  .arrow-anchor span {
    font-size: 1.4rem;
  }

  .convo .convo-card-img img {
    height: 20vw;
  }

  .arrow-anchor .img-box {
    width: 3.6rem;
    height: 3.6rem;
  }

  .main-hero-social-link {
    width: 3.6rem;
    height: 3.6rem;
  }

  .portfolio .generic-navigation-wrapper .swiper-button-prev,
  .portfolio .generic-navigation-wrapper .swiper-button-next {
    width: 5rem;
    height: 5rem;
  }

  .custom-next-button {
    right: 26rem;
  }

  .custom-prev-button {
    right: 30rem;
  }

  .serviceHelp .services-list-cards-sections {
    width: 100% !important;
  }

  .services-list-heading-section {
    width: 100%;
    margin-top: 2rem;
  }

  .apply-now-canvas {
    height: 50%;
  }

  .slider-portfolio .slick-list {
    padding: 1rem;
  }

  .experties .twocol-row {
    justify-content: space-around;
  }

  .speak-expert-form-wrap .input-container .input-select option {
    font-size: 10px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  html {
    font-size: 66%;
  }


}

@media (min-width: 1401px) and (max-width: 1600px) {
  html {
    font-size: 75%;
  }

  .speak-expert-form-wrap .input-container textarea {
    resize: none;
    margin-top: 34px;
  }

  .speak-expert-form-wrap .input-container .input-select option {
    font-size: 8px;
  }
}

@media (min-width: 1601px) and (max-width: 1800px) {
  html {
    font-size: 87.5%;
  }

  .speak-expert-form-wrap .input-container .input-select option {
    font-size: 8px;
  }
}

@media (min-width: 1801px) and (max-width: 2000px) {
  html {
    font-size: 100%;
  }

  .speak-expert-form-wrap .input-container textarea {
    resize: none;
    margin-top: 34px;
  }

  .speak-expert-form-wrap .input-container .input-select option {
    font-size: 10px;
  }
}

@media (min-width: 2001px) and (max-width: 3300px) {
  html {
    font-size: 125%;
  }

  .speak-expert-form-wrap .input-container textarea {
    resize: none;
    margin-top: 80px;
  }

  .speak-expert-form-wrap .input-container .input-select option {
    font-size: 10px;
  }
}

@media (max-width: 1400px) and (min-width: 1300px) and (min-height: 900px) {
  .services-hero {
    height: auto;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .services-items,
  .services-wrap {
    height: 75vh;
  }

  .service-item {
    height: 75vh;
  }

  .services-slider-wrap {
    height: 75vh;
  }
}