@media (max-width: 767px) {
	.services-slider {
		display: none;
	}
}


@keyframes scroll {
	100% {
		transform: translateX(-1672px);
	}
}




.w-uline {
	color: #fff;
	position: relative;
	text-decoration: none;
}

.w-uline::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -0.3em;
	width: 100%;
	height: 2px;
	background: #fff;
	transform: scaleX(0);
	transition: transform 0.5s ease;
}

.w-uline.active::before,
.w-uline:hover::before {
	transition: transform 0.5s ease;
	transform: scaleX(1);
}

.w-uline.primary-col {
	color: #033780;
}

.w-uline.primary-col::before {
	background: #033780;
}

.w-uline.black-col {
	color: #000;
}

.w-uline.black-col::before {
	background: #000;
}




@media (max-width: 576px) {
	.fill-btn {
		padding: 0.5em 2em;
	}
}





.margin-set {
	margin-left: -6em;
}



.hero-main.center-head {
	text-align: center;
	margin: 0 auto;
}





@media (max-width: 576px) {
	.animate {
		opacity: 1;
	}

	.animate.fadein-Left {
		transform: translate(0, 0);
	}

	.animate.fadein-Right {
		transform: translate(0, 0);
	}

	.animate.fadein-Up {
		transform: translate(0, 0);
	}

	.animate.fadein-Up-Fast {
		transform: translate(0, 0);
	}

	.animate.fadein-Down {
		transform: translate(0, 0);
	}
}

@keyframes anim {
	to {
		transform: translate(0, 0);
		opacity: 1;
	}
}


@media (max-width: 991px) {
	.col-90 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-80 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-75 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-70 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-65 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-67 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-60 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-57 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-59 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-56 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-55 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-53 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-45 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-44 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-42 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-48 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-47 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-43 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-40 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-37 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-36 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-35 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-33 {
		width: 50%;
	}
}

@media (max-width: 576px) {
	.col-33 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-31 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-30 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-25 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-18 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-20 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-10 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-54 {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.col-46 {
		width: 100%;
	}
}


@media (max-width: 991px) {
	.col-52 {
		width: 100%;
	}
}


.navigation-wrapper .social-wrapper {
	display: flex;
}

@media (max-width: 991px) {
	.navigation-wrapper .social-wrapper {
		display: none;
		padding: 0 0 1.25em 2em;
	}
}

.navigation-wrapper .social-wrapper .anchor-wrapper a {
	position: relative;
	width: 4em;
	background: #033780;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 1200px) {
	.navigation-wrapper .social-wrapper .anchor-wrapper a {
		width: 3.5em;
	}
}

.navigation-wrapper .social-wrapper .anchor-wrapper a::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background: #fff;
	left: auto;
	transition: 0.5s;
}

.navigation-wrapper .social-wrapper .anchor-wrapper a svg {
	width: 1.625em;
	z-index: 1;
}

.navigation-wrapper .social-wrapper .anchor-wrapper a svg path {
	transition: 0.5s;
}

.navigation-wrapper .social-wrapper .anchor-wrapper a:hover svg path {
	fill: #033780;
	transition: 0.5s;
}

.navigation-wrapper .social-wrapper .anchor-wrapper a:hover::before {
	left: 0;
	width: 100%;
}

.navigation-wrapper .social-wrapper .anchor-wrapper.project-anchor a {
	min-width: 13.875em;
	padding: 0 1.75em;
	background: #fff;
	color: #033780;
	font-size: 1em;
	font-weight: 700;
	transition: 0.5s;
}

.navigation-wrapper .social-wrapper .anchor-wrapper.project-anchor a span {
	width: 22ch;
	white-space: nowrap;
	overflow: hidden;
	z-index: 1;
}

.navigation-wrapper .social-wrapper .anchor-wrapper.project-anchor a::before {
	background: #033780;
}

.navigation-wrapper .social-wrapper .anchor-wrapper.project-anchor a:hover {
	color: #fff;
	transition: 0.5s;
}

@media (max-width: 991px) {
	.navigation-wrapper .social-wrapper .anchor-wrapper.project-anchor a {
		min-width: unset;
		padding: 0 1em;
		width: 8.25em;
	}
}

.navigation-wrapper .social-wrapper.mob-social-wrapper {
	display: none;
	padding: 0;
}

@media (max-width: 991px) {
	.navigation-wrapper .social-wrapper.mob-social-wrapper {
		display: flex;
		align-items: center;
	}
}

.navigation-wrapper .social-wrapper.mob-social-wrapper .anchor-wrapper {
	height: 100%;
	border-right: 1px solid #e1637e;
}

@keyframes typing {
	from {
		width: 0;
	}
}

@keyframes blink {
	50% {
		border-color: transparent;
	}
}



@media (max-width: 991px) {
	.navigation {
		display: none;
	}
}

.menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	max-width: 100%;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

@media (max-width: 991px) {
	.menu {
		padding: 5em 1em 2em 1em;
		width: 100%;
	}
}

.menu__menuitem {
	height: 100%;
	display: flex;
	align-items: center;
	margin: 0 3em 0 0;
}

.menu__menuitem.active .menu__menuitem--menulink {
	color: #033780;
}

@media (max-width: 1024px) {
	.menu__menuitem {
		margin: 0 2em 0 0;
	}
}

@media (max-width: 991px) {
	.menu__menuitem {
		padding: 0 0 1.5em 0;
		margin: 0;
	}
}

@media (max-width: 991px) {
	.menu__menuitem {
		width: 100%;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		padding: 0;
	}
}

@media (max-width: 991px) {
	.menu__menuitem.s-column-dropdown .menu__menuitem--menulink {
		position: relative;
	}
}

.menu__menuitem.s-column-dropdown .menu__menuitem--menulink::before {
	content: "";
	position: absolute;
	top: 50%;
	right: -1em;
	padding: 0.15em;
	transition: 0.5s;
	display: none;
	border-style: solid;
	border-color: #033780;
	border-width: 2px 2px 0 0;
	transform-origin: 100% 20%;
	transform: rotate(135deg);
	transition: 0.4s;
}

@media (max-width: 991px) {
	.menu__menuitem.s-column-dropdown .menu__menuitem--menulink::before {
		display: inline-block;
	}
}

.menu__menuitem.s-column-dropdown.dropdown-open .menu__menuitem--menulink {
	color: #033780;
	transition: 0.4s;
}

.menu__menuitem.s-column-dropdown.dropdown-open .menu__menuitem--menulink::before {
	top: 35%;
	transform: rotate(-45deg);
	transition: 0.4s;
}

.menu__menuitem .s-column-dropdown-menu {
	display: none;
	position: absolute;
	width: 100%;
	top: 100%;
	left: 0;
	text-align: left;
	margin: 0;
	list-style-type: none;
	z-index: 2;
	background: linear-gradient(57.3deg, #4f1180 28.3%, #7b22c0 88.2%);
	overflow: hidden;
}

@media (max-width: 991px) {
	.menu__menuitem .s-column-dropdown-menu {
		position: relative;
		padding: 0;
		background: 0 0;
	}
}

.menu__menuitem--menulink {
	height: 100%;
	display: flex;
	align-items: center;
	font-size: 1em;
	font-weight: 500;
	font-family: Montserrat, sans-serif;
	line-height: 1;
	letter-spacing: -0.03em;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	transition: 0.4s;
	padding: 0.75em 0 0.75em 0;
}

@media (max-width: 1024px) {
	.menu__menuitem--menulink {
		font-size: 0.95em;
	}
}

.menu__menuitem--menulink.active,
.menu__menuitem--menulink:hover {
	color: #033780;
	transition: 0.4s;
}

.nav-button {
	display: none;
	position: relative;
	height: 3.5em;
	width: 3.5em;
	padding: 0;
	background: 0 0;
	transition: all 250ms ease-out;
	z-index: 99;
	background: #fff;
	cursor: pointer;
}

@media (max-width: 991px) {
	.nav-button {
		height: 100%;
		display: flex;
		align-items: center;
	}
}

.nav-button::after,
.nav-button::before {
	content: "";
	width: 2em;
	height: 3px;
	position: absolute;
	background: #033780;
	transition: all 250ms ease-out;
	will-change: transform;
	left: 50%;
}

.nav-button::before {
	transform: translate(-50%, -50%);
}

.nav-button::after {
	transform: translate(-50%, 6px);
}

.nav-button.active::before {
	transform: translate(-50%, 0) rotate(45deg);
}

.nav-button.active::after {
	transform: translate(-50%, 0) rotate(-45deg);
}

.menu-slider-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.menu-slider-wrapper .menu-slider-thumb-wrapper {
	width: 30%;
	padding: 3em 4em;
}

@media (max-width: 991px) {
	.menu-slider-wrapper .menu-slider-thumb-wrapper {
		width: 100%;
		padding: 0;
	}
}

.menu-slider-wrapper .menu-slider-thumb-wrapper .menu-slider-thumb {
	list-style-type: none;
	padding: 0;
	max-height: 22em;
	overflow-y: scroll;
}

.menu-slider-wrapper .menu-slider-thumb-wrapper .menu-slider-thumb .menu-slider-thumb-slide {
	margin: 0.5em 0.5em 0.5em 0;
}

.menu-slider-wrapper .menu-slider-thumb-wrapper .menu-slider-thumb .menu-slider-thumb-slide .slide-head {
	color: #fff;
	font-size: 0.875em;
	font-weight: 600;
	text-transform: uppercase;
	padding: 1.25em 1em;
	border-radius: 5.625em;
	display: flex;
	align-items: center;
	line-height: 1;
	transition: 0.4s;
	cursor: pointer;
}

@media (max-width: 991px) {
	.menu-slider-wrapper .menu-slider-thumb-wrapper .menu-slider-thumb .menu-slider-thumb-slide .slide-head {
		font-size: 1.25em;
		padding: 0.75em 1em;
	}
}

@media (max-width: 576px) {
	.menu-slider-wrapper .menu-slider-thumb-wrapper .menu-slider-thumb .menu-slider-thumb-slide .slide-head {
		font-size: 1em;
		border-radius: 0;
	}
}

.menu-slider-wrapper .menu-slider-thumb-wrapper .menu-slider-thumb .menu-slider-thumb-slide.active .slide-head {
	background: #033780;
	transition: 0.4s;
}

.menu-slider-wrapper .menu-slider-gallery-wrapper {
	width: 70%;
	height: auto;
	border-left: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 991px) {
	.menu-slider-wrapper .menu-slider-gallery-wrapper {
		width: 100%;
		border-left: none;
	}
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery {
	list-style-type: none;
	padding: 0;
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .slide-head {
	color: #fff;
	font-size: 2em;
	font-weight: 600;
	letter-spacing: -0.03em;
	text-transform: uppercase;
	padding: 1.38em 1.82em 0.875em 1.82em;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 991px) {
	.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .slide-head {
		color: #033780;
		font-size: 1.5em;
		border-bottom: none;
		padding: 0.5em 0;
	}
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 3em 5em;
}

@media (max-width: 1024px) {
	.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper {
		padding: 3em;
	}
}

@media (max-width: 991px) {
	.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper {
		padding: 1em 0;
	}
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item {
	width: 33.33%;
	margin: 0 0 2em 0;
}

@media (max-width: 991px) {
	.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item {
		width: 100%;
		margin: 0 0 1em 0;
	}
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item a {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	color: #fff;
	font-size: 0.875em;
	font-weight: 600;
	text-transform: uppercase;
}

@media (max-width: 1024px) {
	.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item a {
		font-size: 0.75em;
	}
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item a.active {
	color: #033780;
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item__head {
	position: relative;
	display: flex;
	align-items: center;
	text-decoration: none;
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item__head::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -0.3em;
	width: 100%;
	height: 2px;
	background: #fff;
	transform: scaleX(0);
	transition: transform 0.5s ease;
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item__head:hover::before {
	transition: transform 0.5s ease;
	transform: scaleX(1);
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item__img {
	width: 2em;
	height: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: -0.9375em 0.9375em 1.4375em rgba(56, 1, 89, 0.5);
	border-radius: 0.375em;
	margin: 0 1.8125em 0 0;
}

.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide .list-wrapper .list-item__img img {
	width: 60%;
	height: 60%;
}

@media (max-width: 991px) {
	.menu-slider-wrapper .menu-slider-gallery-wrapper .menu-slider-gallery-slide {
		padding: 1em 1em 1em 2em;
	}
}

.menu-slider-thumb::-webkit-scrollbar {
	width: 0;
	border-radius: 5px;
}

.mobNav {
	display: none;
}

@media (max-width: 991px) {
	.mobNav {
		position: fixed;
		right: -100%;
		width: 50vw;
		height: 100vh;
		top: 0;
		transition: 0.7s;
		background: #fff;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		z-index: 7;
		overflow-y: scroll;
	}

	.mobNav.open {
		right: 0;
	}
}

@media (max-width: 576px) {
	.mobNav {
		width: 100%;
	}
}

.mobNav__menu {
	padding: 5em 1em 2em 1em;
	width: 100%;
	list-style-type: none;
	margin: 0;
}

.mobNav__menu--item {
	position: relative;
	padding: 0.75em 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.mobNav__menu--item .menu-toggleBtn {
	position: absolute;
	top: 0.5em;
	transform: translate(0, -0.25em);
	right: 0;
	width: 2em;
	height: 2em;
}

.mobNav__menu--item .menu-toggleBtn::before {
	content: "";
	position: absolute;
	top: 1em;
	transform: translate(50%, -50%);
	right: 1em;
	width: 1em;
	height: 2px;
	background-color: #000;
}

.mobNav__menu--item .menu-toggleBtn::after {
	content: "";
	position: absolute;
	top: 1em;
	transform: translate(50%, -50%);
	right: 1em;
	width: 2px;
	height: 1em;
	background-color: #000;
	transition: 0.25s;
}

.mobNav__menu--item .menu-toggleBtn.active::after,
.mobNav__menu--item .menu-toggleBtn.active::before {
	transform: translate(50%, -50%) rotate(90deg);
	transition: 0.25s;
}

.mobNav__menu--item .menu-toggleBtn.active::before {
	opacity: 0;
}

.mobNav__menu--item .menu-link {
	color: #000;
	font-size: 1em;
}

.mobNav__menu--item .menu-link.active {
	color: #033780;
}

.mobNav__menu--item .menu-link.active-menu {
	color: #033780;
}

.mobNav__menu--item.active-menu .menu-link {
	color: #033780;
}

.mobNav__menu--item .inner-menu {
	display: none;
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0 0 0 1em;
}

.mobNav__menu--item .inner-menu__item {
	padding: 0.75em 0;
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.mobNav__menu--item .inner-menu__item:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.mobNav__menu--item .inner-menu__item--link {
	display: block;
	color: #000;
	font-size: 0.9375em;
	font-weight: 400;
}

.mobNav__menu--item .inner-menu__item--link.active {
	padding: 0 0 0.5em;
	color: #033780;
}

.mobNav__menu--item .inner-menu__item--link.active-menu {
	color: #033780;
}

.mobNav__menu--item .inner-menu__item .sub-inner-menu {
	display: none;
	list-style-type: none;
	margin: 0;
	padding: 0 0 0 1em;
}

.mobNav__menu--item .inner-menu__item .sub-inner-menu__item {
	position: relative;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.mobNav__menu--item .inner-menu__item .sub-inner-menu__item:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

.mobNav__menu--item .inner-menu__item .sub-inner-menu__item--link {
	display: block;
	padding: 0.75em 1em;
	color: #000;
	font-size: 0.875em;
	font-weight: 400;
	text-transform: capitalize;
}

.mobNav__menu--item .inner-menu__item .sub-inner-menu__item--link.active-menu {
	background-color: #033780;
	color: #fff;
}

.mobNav__menu--item .inner-menu__item .inner-menu-toggleBtn {
	position: absolute;
	top: 1em;
	transform: translate(0, -0.25em);
	right: 0;
	width: 2em;
	height: 2em;
	transition: 0.25s;
}

.mobNav__menu--item .inner-menu__item .inner-menu-toggleBtn::after {
	content: "";
	position: absolute;
	top: 0.25em;
	right: 0.5em;
	border-color: #000;
	border-bottom-style: solid;
	border-bottom-width: 2px;
	border-right-style: solid;
	border-right-width: 2px;
	display: inline-block;
	height: 0.375em;
	width: 0.375em;
	transform: rotate(45deg);
}

.mobNav__menu--item .inner-menu__item .inner-menu-toggleBtn.active {
	transform: translate(0.5em, -1em) rotate(180deg);
	transition: 0.25s;
}



@media (max-width: 991px) {
	.header .scroll-text {
		display: none;
	}
}




.brand-logo__anchor {
	width: 16em;
	height: auto;
	transition: 0.4s;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #363636;
	padding: 0.5em 0.75em;
}

.brand-logo__anchor img {
	height: 100%;
	display: block;
}

@media (max-width: 576px) {
	.brand-logo__anchor {
		width: 3.5em;
		height: 3.5em;
		padding: 0;
		background: 0 0;
	}

	.brand-logo__anchor img {
		height: 100%;
	}
}

.header.sticky {
	background-color: rgba(0, 0, 0, 0.75);
	transition: 0.4s;
}



.qlinks-menu {
	padding: 0;
}

@media (max-width: 576px) {
	.qlinks-menu {
		padding: 0 0 1em 2em;
		display: none;
	}
}

.qlinks-menu__item {
	font-size: 1em;
	font-weight: 400;
	padding: 0 1em 0 0;
	margin-bottom: 1.5em;
}

@media (max-width: 991px) {
	.qlinks-menu__item {
		padding: 0 2em 0 0;
	}
}

@media (max-width: 576px) {
	.qlinks-menu__item {
		padding: 0 1em 0 0;
	}
}



.copy-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2.5625em 0;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

@media (max-width: 576px) {
	.copy-wrapper {
		align-items: center;
		padding: 1.5em 1em 1.5em 1em;
	}
}

.copy-wrapper .vline {
	padding: 0 1em;
}

.copy-wrapper p {
	color: #fff;
	font-weight: 400;
	margin: 0;
}

@media (max-width: 576px) {
	.copy-wrapper p {
		text-align: center;
	}
}

@media (max-width: 576px) {
	.copy-wrapper {
		flex-flow: column;
	}
}

/* !Home Hero Start */

.home-hero {
	position: relative;
	width: 100%;
	height: 100vh;
}

@media (max-width: 991px) {
	.home-hero {
		height: 75vh;
	}
}

@media (max-width: 576px) {
	.home-hero {
		height: 100vh;
	}
}

.home-hero__heroImg {
	position: relative;
	width: 100%;
	height: 100%;
}

.home-hero__heroImg .img-box {
	position: relative;
	width: 100%;
	height: 100%;
}

.home-hero__heroImg .img-box .video-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 11em;
	overflow: hidden;
}

.home-hero__heroImg .img-box .video-wrapper::before {
	position: absolute;
	content: "";
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1;
}

@media (max-width: 576px) {
	.home-hero__heroImg .img-box .video-wrapper::before {
		content: none;
	}
}

.home-hero__heroImg .img-box .video-wrapper iframe,
.home-hero__heroImg .img-box .video-wrapper video {
	object-fit: cover;
	width: auto;
	height: 100vh;
	position: relative;
	right: 0;
	z-index: 0;
}

@media (min-width: 576px) {

	.home-hero__heroImg .img-box .video-wrapper iframe,
	.home-hero__heroImg .img-box .video-wrapper video {
		width: 1920px;
		height: 1080px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.home-hero__heroImg img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.home-hero__hero-caption {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	z-index: 1;
}

@media (max-width: 576px) {
	.home-hero__hero-caption {
		top: 30%;
	}
}

.home-hero__hero-caption--container {
	padding: 0 4.5%;
}

@media (max-width: 991px) {
	.home-hero__hero-caption--container {
		padding: 0 5em;
	}
}

@media (max-width: 576px) {
	.home-hero__hero-caption--container {
		padding: 0 1em;
	}
}

.home-hero .head {
	width: 70%;
	margin: 0 0 0 auto;
	color: #fff;
	font-size: 6rem;
	font-weight: 700;
	line-height: 0.88;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
	text-align: right;
}

@media (max-width: 991px) {
	.home-hero .head {
		width: 100%;
		font-size: 3em;
	}
}



@media (max-width: 375px) {
	.home-hero .head {
		font-size: 1.5em;
	}
}

.home-hero .head .head-text-wrapper {
	display: block;
	overflow: hidden;
}

.home-hero .head .head-text-wrapper span {
	display: inline-block;
}

.home-hero .head .head-text-wrapper .gradient-anim {
	background: linear-gradient(200deg, #6c06ec, #033780 60%);
	background-size: 200% 200%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: gradientAnimation 5s linear infinite;
	-webkit-animation: gradientAnimation 5s linear infinite;
}

@keyframes gradientAnimation {
	0% {
		background-position: 0 25%;
	}

	50% {
		background-position: 100% 45%;
	}

	to {
		background-position: 0 50%;
	}
}

.home-hero__hero-banner {
	width: 100%;
	position: absolute;
	top: 77.5%;
	left: 0;
	transform: translate(0, -50%);
	z-index: 1;
}

@media (max-width: 576px) {
	.home-hero__hero-banner {
		top: 45%;
		bottom: auto;
		transform: translate(0, 0);
		text-align: center;
	}

	.home-hero__hero-banner .anchor-wrapper {
		justify-content: center;
	}
}

.home-hero__hero-banner--container {
	padding: 0 9%;
}

@media (max-width: 991px) {
	.home-hero__hero-banner--container {
		padding: 0 5em;
	}
}

@media (max-width: 576px) {
	.home-hero__hero-banner--container {
		padding: 0 1em;
	}
}

.home-hero__hero-banner--wrapper {
	max-width: 42%;
	margin: 0;
}

@media (max-width: 1024px) {
	.home-hero__hero-banner--wrapper {
		max-width: 55%;
	}
}

@media (max-width: 576px) {
	.home-hero__hero-banner--wrapper {
		max-width: 100%;
		padding: 1em 0 0 0;
	}
}

.home-hero__hero-banner--wrapper .mob-video {
	display: none;
	margin: 8em auto 0 auto;
	width: 20%;
	border-radius: 100%;
	overflow: hidden;
}

.home-hero__hero-banner--wrapper .mob-video .img-box {
	height: 5em;
}

@media (max-width: 576px) {
	.home-hero__hero-banner--wrapper .mob-video {
		display: flex;
	}
}

.home-hero__hero-banner--wrapper .mob-video .play-icon {
	width: 2.5em;
	height: 2.5em;
}

.mobile-only {
	display: none;
}

@media (max-width: 576px) {
	.mobile-only {
		display: block;
		position: fixed;
		bottom: 0;
		z-index: 99;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
}

.mobile-only__social-wrapper {
	background: #363636;
	width: 50%;
	display: flex;
	justify-content: center;
	text-align: center;
}

.mobile-only__social-wrapper a {
	width: 50%;
	z-index: 1;
	display: block;
	padding: 0.75em 0;
}

.mobile-only__social-wrapper a:first-child {
	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.mobile-only__social-wrapper svg {
	width: 1.52em;
}

.mobile-only__get-quote {
	background: #033780;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mobile-only__get-quote a {
	text-align: center;
	text-transform: capitalize;
	color: #fff;
	font-weight: 600;
}

.mobile-only .icon-btn-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
}

.hero--section__social-linkWrapper {
	position: absolute;
	top: 45%;
	left: 4.5%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.hero--section__social-link {
	position: relative;
	width: 2.5625em;
	height: 2.5625em;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #fff;
	border-radius: 100%;
	z-index: 1;
	margin: 0 0 1.25em 0;
	overflow: hidden;
	transition: 0.4s;
}

.hero--section__social-link::before {
	content: "";
	position: absolute;
	width: 110%;
	height: 110%;
	border-radius: 100%;
	background-color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	z-index: -1;
	transition: 0.4s;
}

.hero--section__social-link .img-box {
	width: 45%;
	height: 45%;
}

.hero--section__social-link .img-box svg {
	width: 100%;
	height: 100%;
	transition: 0.4s;
}

.hero--section__social-link:hover {
	transition: 0.4s;
}

.hero--section__social-link:hover::before {
	transform: translate(-50%, -50%) scale(1);
	transition: 0.4s;
}

.hero--section__social-link:hover svg path {
	fill: #363636;
	transition: 0.4s;
}

.hero--section__scrollWrapper {
	position: absolute;
	top: 75%;
	right: 6.78%;
	transform: translate(33%, -50%) rotate(-90deg);
}

@media (max-width: 991px) {
	.hero--section__scrollWrapper {
		display: none;
	}
}

.hero--section__scroll {
	color: #033780;
	font-size: 0.75em;
	font-family: Montserrat, sans-serif;
	letter-spacing: 0.845em;
}

.hero--section__scroll::before {
	content: "";
	position: absolute;
	width: 3.5em;
	height: 1px;
	background-color: #033780;
	left: -6em;
	top: 0.65em;
}

.homepage .mob-social-wrapper {
	display: none !important;
}

/* !Home Hero End */




.collabCompanies {
	position: relative;
	background: #f8f8f8;
}

.collabCompanies__title {
	width: 40%;
	color: #363636;
	font-size: 2.75em;
}

.collabCompanies__title .title-wrapper {
	overflow: hidden;
}

.collabCompanies__title span {
	display: block;
	font-weight: 500;
}

.collabCompanies__title .word {
	display: inline-block;
}

.collabCompanies__Slider .swiper-wrapper {
	flex-direction: row;
}

.collabCompanies__Slider .swiper-slide {
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.collabCompanies__Slider .swiper-slide .img-box img {
	filter: grayscale(1);
	transition: 0.4s;
}

.collabCompanies__Slider .swiper-slide:hover .img-box img {
	filter: grayscale(0);
	transition: 0.4s;
}

.app-slider__scrollbar {
	position: relative;
	width: 40%;
	margin: 0 auto;
	padding: 3em 0 0 0;
}

.app-slider__scrollbar .swiper-scrollbar {
	z-index: 99;
	cursor: grab !important;
	top: 1em;
	height: 3px !important;
}

.app-slider__scrollbar .swiper-scrollbar .swiper-scrollbar-drag {
	background: #033780;
}

.app-slider__scrollbar .drag-head {
	font-size: 0.875em;
	color: #636363;
	text-align: center;
}

.app-slider__scrollbar .drag-img {
	width: 1.25em;
	margin: 0 auto 1em auto;
	animation: draganimation ease-in-out 4s;
	animation-iteration-count: infinite;
	transform-origin: 50% 50%;
}

@keyframes draganimation {
	0% {
		transform: translate(0, 0);
	}

	25% {
		transform: translate(30px, 0);
	}

	50% {
		transform: translate(-30px, 0);
	}

	100% {
		transform: translate(0, 0);
	}
}



@keyframes slide--enter-top {
	0% {
		transform: translate3d(0, -101%, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--enter-right {
	0% {
		transform: translate3d(101%, 0, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--enter-bottom {
	0% {
		transform: translate3d(0, 101%, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--enter-left {
	0% {
		transform: translate3d(-101%, 0, 0);
	}

	100% {
		transform: none;
	}
}

@keyframes slide--leave-top {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(0, -101%, 0);
	}
}

@keyframes slide--leave-right {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(101%, 0, 0);
	}
}

@keyframes slide--leave-bottom {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(0, 101%, 0);
	}
}

@keyframes slide--leave-left {
	0% {
		transform: none;
	}

	100% {
		transform: translate3d(-101%, 0, 0);
	}
}



.badge .badge-heading {
	margin: auto;
	max-width: 80%;
	display: flex;
	text-align: center;
	justify-content: center;
}

@media (max-width: 768px) {
	.badge .badge-heading {
		max-width: 100%;
	}
}

.badge .badge-heading h2 {
	color: #033780;
}

.badge .badge-wrapper {
	display: flex;
	flex-wrap: nowrap;
	padding: 4em 0;
	justify-content: space-between;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 30px;
	padding: 2em;
	margin: 0 1em;
	background-color: #fff;
	z-index: 1;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper .badge-image {
	width: 7em;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper h3 {
	margin: 1em 0;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper h3 span {
	font-size: 0.5em;
	font-weight: 500;
	margin-left: 0.5em;
}

.badge .badge-wrapper .badge-card .badge-card-wrapper p {
	margin-top: 1em;
	color: #000;
	font-size: 1em;
}

.badge .badge-wrapper .badge-card::before {
	content: "";
	background: #de244a;
	border-radius: 30px;
	bottom: -9px;
	content: "";
	height: 110px;
	position: absolute;
	right: 19px;
	transform: matrix(0.99, 0.11, -0.13, 0.99, 0, 0);
	width: 77%;
	z-index: 0;
}

.getSocial {
	position: relative;
	background: #f8f8f8;
}

.getSocial__card {
	position: relative;
	width: 20%;
}

@media (max-width: 576px) {
	.getSocial__card {
		width: 50%;
	}
}

.getSocial__card.big-card {
	width: 40%;
}

@media (max-width: 576px) {
	.getSocial__card.big-card {
		width: 100%;
	}
}

.getSocial__card.blue-bg {
	background: #1da1f3;
}

.getSocial__card--descrip {
	display: flex;
	justify-content: space-between;
	flex-flow: column;
	height: 100%;
	padding: 6.875em 4.0625em 2.1875em 2.1875em;
}

.getSocial__card--descrip p {
	color: #fff;
	font-size: 1.0625em;
	font-weight: 600;
	margin: 0;
}

.getSocial__card--descrip a {
	color: #fff;
}

.getSocial__card--icon {
	position: absolute;
	top: 2.1875em;
	left: 2.1875em;
	width: auto;
	height: 1.8125em;
}

.getSocial__card--icon img {
	width: auto;
	height: 100%;
}

.getSocial__card--playbtn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3.5em;
	height: 3.5em;
}

.getSocial__card--playbtn img {
	width: 100%;
	height: 100%;
}

.getSocial__card--img {
	width: 100%;
	height: 100%;
}

.getSocial__card--img img {
	height: 100%;
}

.getSocial__head {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 0 1em 0;
}

@media (max-width: 576px) {
	.getSocial__head {
		margin: 0 0 1em 0;
	}
}

.getSocial__head p {
	color: #000;
	margin: 0;
	letter-spacing: 0.625em;
	font-weight: 500;
}

.getSocial__head h2 {
	color: #000;
	margin: 0;
	font-size: 3em;
}

@media (max-width: 576px) {
	.getSocial__head h2 {
		font-size: 2em;
	}
}

.getSocial__head .social-icon {
	display: flex;
	padding: 0;
}

.getSocial__head .social-icon .social-item {
	width: 3.5em;
	height: 3.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0.25em;
}

@media (max-width: 576px) {
	.getSocial__head .social-icon .social-item {
		width: 4.5em;
		height: 4.5em;
	}
}

@media (max-width: 375px) {
	.getSocial__head .social-icon .social-item {
		width: 3em;
		height: 3em;
	}
}

.getSocial__head .social-icon .social-item a {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.getSocial__head .social-icon .social-item a::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	background: #033780;
	border-radius: 100%;
	transition: 0.3s;
	z-index: 1;
}

.getSocial__head .social-icon .social-item a svg {
	width: auto;
	height: 40%;
	z-index: 2;
}

@media (max-width: 1024px) {
	.getSocial__head .social-icon .social-item a svg {
		height: 20px;
	}
}

.getSocial__head .social-icon .social-item a svg path {
	fill: #000;
	transition: 0.4s;
}

.getSocial__head .social-icon .social-item a:hover::before {
	transform: translate(-50%, -50%) scale(1);
	transition: 0.3s;
}

.getSocial__head .social-icon .social-item a:hover svg path {
	fill: #fff;
	transition: 0.4s;
}


.services__items,
.services__wrap {
	position: relative;
	height: 100vh;
	width: 100%;
	margin: 0 auto;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

	.services__items,
	.services__wrap {
		height: 75vh;
	}
}

@media (max-width: 991px) {

	.services__items,
	.services__wrap {
		height: 75vh;
	}
}

.services .line-heading {
	position: absolute;
	z-index: 5;
	top: 5em;
}

@media (max-width: 576px) {
	.services .line-heading {
		margin: 0;
		padding: 2em 0 0 1em;
		top: 0;
	}
}

.services__items .skipSec {
	position: absolute;
	bottom: 2em;
	right: 2em;
	width: 4.5em;
	height: 4.5em;
	z-index: 3;
}

.services__items .skipSec__wrapper {
	width: 100%;
	height: 100%;
	background: #033780;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.services__items .skipSec .head {
	font-size: 1.125em;
}


.scene-spacing {
	height: 100vh;
}



.service-item__wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	margin: 0 auto;
	z-index: 2;
	position: relative;
	justify-content: flex-end;
}

.service-item__head {
	width: 40%;
	padding: 0 0 0 9%;
}

.service-item__img {
	width: 60%;
	padding: 0 4.5% 0 4.5%;
	z-index: 0;
}

.service-item__img .img-box {
	width: auto;
	height: 35em;
	margin: 0 auto 0 auto;
}

.service-item__img .img-box img {
	height: 100%;
	object-fit: contain;
}

@media (max-width: 576px) {
	.service-item__img .img-box {
		width: 100%;
		height: auto;
	}

	.service-item__img .img-box img {
		height: auto;
	}
}

@media (max-width: 576px) {
	.service-item__img .descrip-wrapper {
		display: none;
	}
}

.service-item__img p {
	font-family: Montserrat, sans-serif;
	font-size: 0.9em;
	color: #fff;
	font-weight: 300;
}

.service-item .services-anchor {
	display: none;
}

@media (max-width: 576px) {
	.service-item .services-anchor {
		display: block;
	}
}

.service-item__bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
}

.service-item__bg img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.service-item__list-wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 991px) {
	.service-item__list-wrapper {
		padding-left: 1em;
	}
}

.service-item__list-wrapper li {
	width: 33.33%;
	padding: 0 1.5em 0 0;
	margin-bottom: 0.25em;
}

@media (max-width: 991px) {
	.service-item__list-wrapper li {
		width: 50%;
	}
}

.service-item__list-wrapper li a {
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.875em;
	transition: 0.25s ease-in-out;
}

@media (max-width: 991px) {
	.service-item__list-wrapper li a {
		font-size: 0.95em;
	}
}

.service-item__list-wrapper li a:hover {
	color: #fff;
}

.services-thumb-slider-wrapper {
	position: absolute;
	width: 40%;
	height: 100vh;
	top: 0;
	left: 0;
	padding: 7em 0;
	z-index: 2;
	padding: 7em 0 7em 9%;
	display: flex;
	align-items: center;
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	.services-thumb-slider-wrapper {
		height: 75vh;
	}
}

@media (max-width: 991px) {
	.services-thumb-slider-wrapper {
		height: 75vh;
		padding: 5em 0 5em 5em;
	}
}

@media (max-width: 576px) {
	.services-thumb-slider-wrapper {
		padding: 4em 0 2em 0;
	}
}

.services-thumb-slider-wrapper .scroll-text {
	position: absolute;
	top: 50%;
	transform: translate(100%, -50%);
	left: 4.5%;
	display: flex;
	flex-direction: column;
}

@media (max-width: 991px) {
	.services-thumb-slider-wrapper .scroll-text {
		display: none;
	}
}

.services-thumb-slider-wrapper .scroll-text .mouse {
	margin: 0 auto;
	display: block;
	border-radius: 3.125em;
	border: 2px solid #fff;
	height: 3.125em;
	width: 1.5em;
	position: relative;
	opacity: 0.4;
}

.services-thumb-slider-wrapper .scroll-text .move {
	position: absolute;
	background-color: #fff;
	height: 0.25em;
	width: 0.25em;
	border-radius: 50%;
	left: 50%;
	transform: translateX(-50%);
	animation: move 2s linear infinite;
	opacity: 0.8;
}

.services-thumb-slider-wrapper .scroll-text .head {
	font-size: 0.75em;
	letter-spacing: 0.03em;
	margin: 0.75em 0 0 0;
	opacity: 0.4;
}

.services-thumb-slider-wrapper .services-thumb-slider {
	position: relative;
	width: 100%;
	height: 50%;
	margin: auto 0 auto 0;
	padding: 0 0 0 1.5em;
}

@media (max-width: 576px) {
	.services-thumb-slider-wrapper .services-thumb-slider {
		padding: 0;
	}
}

.services-thumb-slider-wrapper .services-thumb-slider::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.2);
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide {
	display: flex;
	align-items: center;
}

@media (max-width: 576px) {
	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide {
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		padding: 0 1em;
	}
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide .service-thumb__head {
	opacity: 0.2;
	transition: 0.25s all ease-in-out;
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide .service-thumb__head h2 {
	font-size: 1.1em;
	transition: 0.25s all ease-in-out;
}

@media (max-width: 375px) {
	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide .service-thumb__head h2 {
		font-size: 1em;
	}
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide .service-thumb__head h2 a {
	color: #fff;
}

@media (max-width: 576px) {
	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide .service-thumb__head h2 a {
		color: #000;
	}
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-next .service-thumb__head,
.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-prev .service-thumb__head {
	opacity: 0.4;
	transition: 0.25s all ease-in-out;
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-next .service-thumb__head h2,
.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-prev .service-thumb__head h2 {
	font-size: 1.5em;
	transition: 0.25s all ease-in-out;
}

@media (max-width: 1024px) {

	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-next .service-thumb__head h2,
	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-prev .service-thumb__head h2 {
		font-size: 1em;
	}
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-active .service-thumb__head {
	opacity: 1;
	transition: 0.25s all ease-in-out;
}

.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-active .service-thumb__head h2 {
	font-size: 2em;
	transition: 0.25s all ease-in-out;
}

@media (max-width: 1024px) {
	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-active .service-thumb__head h2 {
		font-size: 1.1em;
	}
}

.services-thumb-slider-wrapper .services-thumb-slider .service-thumb__head h2 {
	color: #fff;
	font-size: 2em;
	font-weight: 500;
	letter-spacing: -0.03em;
	margin: 0;
}

@media (max-width: 576px) {
	.services-thumb-slider-wrapper .services-thumb-slider .service-thumb__head h2 {
		font-size: 2em;
	}
}

@keyframes move {
	0% {
		transform: translate(-50%, 0);
		opacity: 0;
	}

	50% {
		transform: translate(-50%, 1.5625em);
		opacity: 1;
	}

	100% {
		transform: translate(-50%, 3em);
		opacity: 0;
	}
}

.cursor {
	position: fixed;
	background-color: #db0029;
	width: 0.625em;
	height: 0.625em;
	transition: 0.25s linear transform,
		0.25s cubic-bezier(0.75, -1.27, 0.3, 2.33) border-color, 0.25s linear height,
		0.25s linear width, 0.25s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
	user-select: none;
	pointer-events: none;
	z-index: 99999999;
	transform: scale(1) translate(-50%, -50%);
	border: 1px solid transparent;
	transform-origin: 50% 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
}

@media (min-width: 1300px) and (max-width: 1400px) and (min-height: 900px) {
	.cursor {
		display: none;
	}
}

.cursor.hovered {
	cursor: pointer !important;
	opacity: 0;
}

.cursor.img-hover {
	opacity: 0;
}

.cursor.white-bg {
	opacity: 0;
}

.cursor.white-col {
	background-color: #fff;
}

.cursor-follower {
	position: fixed;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	transition: 0.25s ease-in-out opacity, 0.25s ease-in-out width,
		0.25s ease-in-out height;
	user-select: none;
	pointer-events: none;
	z-index: 99999999;
	transform: translate(-50%, -50%);
	width: 3.125em;
	height: 3.125em;
	border: 2px solid rgba(227, 32, 74, 0.3);
	opacity: 1;
}

@media (min-width: 1300px) and (max-width: 1400px) and (min-height: 900px) {
	.cursor-follower {
		display: none;
	}
}

.cursor-follower.hovered {
	width: 3.5em;
	height: 3.5em;
	background: rgba(227, 32, 74, 0.3);
}

.cursor-follower::before {
	content: "DRAG";
	position: absolute;
	width: 6em;
	height: 6em;
	background-color: #033780;
	color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale3d(0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	opacity: 0;
	transition: transform 0.6s, opacity 0.4s;
	z-index: -1;
	font-size: 0.65em;
	font-weight: 700;
}

.cursor-follower.img-hover::before {
	transform: translate(-50%, -50%) scale3d(1, 1, 1);
	opacity: 1;
	transition: transform 0.6s, opacity 0.4s;
}

.cursor-follower.white-bg::before {
	transform: translate(-50%, -50%) scale3d(1, 1, 1);
	opacity: 1;
	transition: transform 0.6s, opacity 0.4s;
	background-color: #fff;
	color: #033780;
}

.cursor-follower.white-col {
	border-color: #fff;
}

.cursor-follower.extra-white::before {
	content: "cursor";
	transform: translate(-50%, -50%) scale3d(1, 1, 1);
	opacity: 1;
	transition: transform 0.6s, opacity 0.4s;
	background-color: #fff;
	color: #033780;
}

.translateY-bottom {
	transform: translate(0, -50%);
}

.projectForm--box {
	position: fixed;
	inset: 0;
	width: 100vw;
	height: 100%;
	transform: translateX(-100vw);
	overflow-y: hidden;
	z-index: 9;
	padding: 3em 7%;
}

@media (max-width: 576px) {
	.projectForm--box {
		z-index: 99;
	}
}

.projectForm--box__gradient {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(227, 32, 74, 0.5);
	z-index: -1;
}

.projectForm {
	position: fixed;
	inset: 0;
	width: 73vw;
	height: 100%;
	background-color: #fff;
	padding: 3em 7%;
}

@media (max-width: 991px) {
	.projectForm {
		padding: 0 5em;
	}
}

@media (max-width: 576px) {
	.projectForm {
		padding: 0 1em;
		width: 100vw;
	}
}

.projectForm__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.projectForm__wrapper .head {
	color: #000;
	margin: 0 0 1em 0;
}

.projectForm .close-btn {
	width: 2em;
	height: 2em;
	position: absolute;
	right: 7%;
	top: 7%;
	padding: 0;
}



.projectBanner {
	width: 28.5em;
	display: flex;
	position: absolute;
	right: 0;
	bottom: 0;
}

@media (max-width: 576px) {
	.projectBanner {
		width: 100%;
		display: none;
	}
}

.projectBanner__leftsec,
.projectBanner__rightsec {
	width: 50%;
}

.projectBanner__leftsec {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1em 1.5em;
	background: #033780;
	transition: 0.2s;
	cursor: pointer;
}

.projectBanner__leftsec::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 100%;
	background: #fff;
	left: auto;
	transition: 0.5s;
}

.projectBanner__leftsec--img {
	width: 2.375em;
	z-index: 1;
}

.projectBanner__leftsec--img svg path {
	transition: 0.2s;
}

.projectBanner__leftsec--head {
	font-size: 1em;
	font-weight: 700;
	padding: 0.5em 0;
	transition: 0.2s;
	z-index: 1;
}

.projectBanner__leftsec--arrow {
	display: flex;
	opacity: 0.5;
	transition: 0.2s;
	z-index: 1;
}

.projectBanner__leftsec--arrow .img-box {
	width: 1em;
	display: flex;
	align-items: center;
	margin-left: 0.5em;
	transform: rotate(-45deg);
	transition: 0.2s;
}

.projectBanner__leftsec--arrow svg path {
	transition: 0.2s;
}

.projectBanner__leftsec:hover::before {
	left: 0;
	width: 100%;
	transition: 0.5s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--img svg path {
	fill: #033780;
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--head {
	color: #033780;
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--arrow {
	opacity: 1;
	color: #033780;
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--arrow .img-box {
	transform: rotate(0);
	transition: 0.2s;
}

.projectBanner__leftsec:hover .projectBanner__leftsec--arrow svg path {
	fill: #033780;
	transition: 0.2s;
}

.projectBanner__rightsec {
	position: relative;
	cursor: pointer;
}

.projectBanner__rightsec img {
	display: block;
	height: 100%;
}

.projectBanner__rightsec .play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3.125em;
	height: 3.125em;
	background: #033780;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.projectBanner__rightsec .play-icon .play-icon-arrow {
	width: 1em;
	height: 1em;
	background: #fff;
	clip-path: polygon(0 0, 80% 48%, 0 100%, 0 100%);
	transition: clip-path 0.5s ease;
	transition-delay: 0.4s;
	margin-left: 0.375em;
}

.button-outer-circle {
	background: #033780;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

.has-scale-animation {
	animation: smallScale 2s infinite;
}

.has-delay-short {
	animation-delay: 0.5s;
}

@keyframes smallScale {
	from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(1.5);
		opacity: 0;
	}
}

.error {
	position: absolute;
	top: 100%;
	left: 0 !important;
	color: #db0029 !important;
	font-size: 0.9em !important;
	text-indent: 0 !important;
}

.form-box-wrapper .error {
	top: 80% !important;
}

.side-form-wrapper {
	border: 1px solid rgba(0, 0, 0, 0.1882352941);
	padding: 1em;
	border-radius: 0.4em;
}

.side-select {
	background-image: linear-gradient(45deg, transparent 50%, #033780 50%),
		linear-gradient(135deg, #033780 50%, transparent 50%),
		linear-gradient(to right, rgba(135, 206, 235, 0), rgba(135, 206, 235, 0)) !important;
	background-position: calc(100% - 20px) calc(1em + 2px),
		calc(100% - 15px) calc(1em + 2px), 100% 0 !important;
	background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
	background-repeat: no-repeat !important;
}

#video-wrap:not(.active) {
	opacity: 0;
	visibility: hidden;
}

#video-wrap .video-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

#video-wrap .video-container {
	width: 100%;
}

#video-wrap .close-video {
	background-color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 5%;
	top: 5%;
	width: 2.5em;
	height: 2.5em;
	border-radius: 100%;
	padding: 0;
	z-index: 150;
}

#video-wrap .close-video:after,
#video-wrap .close-video:before {
	content: "";
	width: 50%;
	height: 3px;
	background-color: #000;
	position: absolute;
	transition: all 0.3s ease-in-out;
}

#video-wrap .close-video:before {
	transform: rotate(42deg);
}

#video-wrap .close-video:after {
	transform: rotate(-42deg);
}

#video-wrap .close-video:focus {
	outline: 0;
}

#video-wrap .close-video:hover {
	background-color: #000;
}

#video-wrap .close-video:hover:after,
#video-wrap .close-video:hover:before {
	background-color: #fff;
}

#video-wrap iframe,
#video-wrap video {
	width: 100vw;
	height: 62.25vw;
	min-height: 100vh;
	min-width: 177.77vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#video-wrap.active {
	opacity: 1;
	visibility: visible;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	height: 100vh;
	display: flex;
	transition: all 0.3s ease-in-out;
	z-index: 9991;
	align-items: center;
	justify-content: center;
}






@media (max-width: 1400px) and (min-width: 1300px) and (min-height: 900px) {
	.services-main-wrap {
		padding: 6.25em 0 3em 0;
	}
}




.case-study-hero .hero-main-subheading {
	margin: 0;
}

.case-study-hero .hero-main-heading {
	font-size: 6em;
	color: #000;
}

@media (max-width: 576px) {
	.case-study-hero .hero-main-heading {
		font-size: 3em;
	}
}

.case-study-hero .hero-main-section-txt {
	color: #000;
}










@keyframes bgMove {
	0% {
		background-position: 0 100%;
	}

	to {
		background-position: 100% 100%;
	}
}





.serviceDevelopment {
	position: relative;
	padding: 10.625em 0 6.6875em 0;
	overflow: hidden;
}

@media (max-width: 991px) {
	.serviceDevelopment {
		padding: 3em 0;
	}
}

.serviceDevelopment::before {
	content: "";
	position: absolute;
	width: 25em;
	height: 25em;
	top: 14%;
	left: 0;
	transform: translate(-20%, 0);
	/* background-image: url(../svg/development-bg.svg); */
	background-size: cover;
	background-repeat: no-repeat;
	z-index: -1;
}

.serviceDevelopment__sliderWrapper {
	position: relative;
	padding: 5em 0 0 0;
}

@media (max-width: 576px) {
	.serviceDevelopment__sliderWrapper {
		padding: 2em 0 0 0;
	}
}

.serviceDevelopment__thumbWrapper {
	width: 60%;
}

@media (max-width: 991px) {
	.serviceDevelopment__thumbWrapper {
		width: 50%;
	}
}

@media (max-width: 576px) {
	.serviceDevelopment__thumbWrapper {
		width: 100%;
		padding: 0;
	}
}

.serviceDevelopment__galleryWrapper {
	width: 40%;
	height: 100%;
	padding: 0 7em;
}

@media (max-width: 991px) {
	.serviceDevelopment__galleryWrapper {
		width: 50%;
		padding: 0 2em;
	}
}

@media (max-width: 576px) {
	.serviceDevelopment__galleryWrapper {
		width: 100%;
		padding: 0 1em;
	}
}

@media (max-width: 576px) {
	.serviceDevelopment__galleryWrapper .generic-navigation-wrapper {
		margin: 0 auto;
	}
}

.serviceDevelopment__thumb {
	position: relative;
}

.serviceDevelopment__thumb::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: rgba(54, 54, 54, 0.3);
	bottom: 0;
	left: 0;
	transform: scale(1);
	transition: transform 0.25s;
}

.serviceDevelopment__thumb .swiper-slide {
	position: relative;
}

.serviceDevelopment__thumb .swiper-slide::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: #033780;
	bottom: 0;
	left: 0;
	transform: scale(0);
	transition: transform 0.25s;
}

.serviceDevelopment__thumb .swiper-slide .slide-img {
	position: relative;
	height: 16.4375em;
	overflow: hidden;
}

.serviceDevelopment__thumb .swiper-slide .slide-img img {
	height: 100%;
	object-fit: cover;
}

.serviceDevelopment__thumb .swiper-slide .slide-img .slide-count {
	position: absolute;
	bottom: -0.5em;
	right: 0.45em;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke: 1px #b9b9ba;
	font-size: 3.75em;
	font-family: sans-serif;
	font-weight: 700;
	z-index: 1;
}

.serviceDevelopment__thumb .swiper-slide .slide-head {
	color: #363636;
	font-size: 1.625em;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	padding: 0.75em 0;
	opacity: 0.6;
	transition: opacity 0.25s;
}

.serviceDevelopment__thumb .swiper-slide.swiper-slide-thumb-active::before {
	transform: scale(1);
	transition: transform 0.25s;
}

@media (max-width: 576px) {
	.serviceDevelopment__thumb .swiper-slide.swiper-slide-thumb-active::before {
		transform: scale(0);
	}
}

.serviceDevelopment__thumb .swiper-slide.swiper-slide-thumb-active .slide-head {
	opacity: 1;
	transition: opacity 0.25s;
}

@media (max-width: 576px) {
	.serviceDevelopment__gallery {
		padding: 2em 0 0 0;
	}
}

.serviceDevelopment__gallery .swiper-slide .descrip,
.serviceDevelopment__gallery .swiper-slide .head {
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	transition: 0.25s;
}

@media (max-width: 576px) {

	.serviceDevelopment__gallery .swiper-slide .descrip,
	.serviceDevelopment__gallery .swiper-slide .head {
		text-align: center;
	}
}

.serviceDevelopment__gallery .swiper-slide.swiper-slide-active .descrip,
.serviceDevelopment__gallery .swiper-slide.swiper-slide-active .head {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition: 0.25s;
	transition-delay: 0.45s;
}


.serviceBrands {
	position: relative;
}

.serviceBrands__menu {
	width: 100%;
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.serviceBrands__menuItem {
	width: 25%;
	border-right: 1px solid rgba(54, 54, 54, 0.3);
}

.serviceBrands__menuItem:last-child {
	border-right: none;
}

@media (max-width: 576px) {
	.serviceBrands__menuItem {
		width: 50%;
		border-bottom: 1px solid rgba(54, 54, 54, 0.3);
	}

	.serviceBrands__menuItem:nth-child(even) {
		border-right: none;
	}
}

.serviceBrands__menuItem--anchor {
	display: inline-block;
	width: 100%;
	color: #363636;
	font-weight: 600;
	font-size: 1.125em;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	text-align: center;
	padding: 1.5em 0;
	background-color: #fafafb;
	transition: 0.25s;
}

.serviceBrands__menuItem--anchor.active {
	background-color: #033780;
	color: #fff;
	transition: 0.25s;
}

.serviceBrands__menuItem--anchor:hover {
	background-color: rgba(227, 32, 74, 0.5);
	color: #fff;
	transition: 0.25s;
}

.serviceBrands__mainBox {
	position: relative;
	background-color: #f3f3f3;
}

.serviceBrands__mainBox .hero-main {
	padding: 0 0 2em 0;
}

.serviceBrands__mainBox--bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
}

.serviceBrands__mainBox--bg img {
	height: 100%;
	object-fit: cover;
}

.serviceBrands__tabBoxWrapper {
	position: relative;
	max-width: 100%;
	padding: 0 15%;
}

@media (max-width: 991px) {
	.serviceBrands__tabBoxWrapper {
		padding: 0 5em;
	}
}

@media (max-width: 576px) {
	.serviceBrands__tabBoxWrapper {
		padding: 0 1em;
	}
}

.servicesBrandsSlider {
	min-height: 32em;
}

.servicesBrandsSlider .swiper-slide {
	transform: scale(0.9);
	filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.25));
	border-radius: 0;
	overflow: hidden;
	transition: all 0.25s ease;
}

.servicesBrandsSlider .swiper-slide .slide-img {
	height: 32em;
	width: 90%;
	margin: 0 auto;
	transition: all 0.25s ease;
}

.servicesBrandsSlider .swiper-slide .slide-img img {
	transition: 0.2s;
}

.servicesBrandsSlider .swiper-slide.swiper-slide-active {
	transform: scale(1);
	border-radius: 3em;
	filter: none;
	transition: all 0.25s ease;
}

.servicesBrandsSlider .swiper-slide.swiper-slide-active .slide-img {
	transition: all 0.25s ease;
}

.servicesBrandsSlider .swiper-slide.swiper-slide-active .slide-img img {
	animation: zoom-move 25s;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	animation-delay: 1s;
}

.servicesBrandsSlider .swiper-slide.swiper-slide-active .mob-frame {
	opacity: 1;
	visibility: visible;
	transition: all 0.25s ease;
}

.servicesBrandsSlider .mob-frame {
	position: absolute;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	transition: all 0.25s ease;
}

.servicesBrandsSlider .mob-frame img {
	height: 100%;
}

@keyframes zoom-move {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-8em);
	}

	100% {
		transform: translateY(0);
	}
}

.servicesContent {
	position: relative;
	padding: 9.3125em 0 5.75em 0;
	background-color: #033780;
}

@media (max-width: 991px) {
	.servicesContent {
		padding: 3em 0;
	}
}

.go-to-background {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;
	opacity: 0.6;
}

.go-to-background img {
	object-fit: fill;
	height: 360px;
}



.go-to-right {
	width: 66.66%;
	padding: 0 0 0 12.5em;
}

@media (max-width: 991px) {
	.go-to-right {
		width: 100%;
		padding: 0;
	}
}

.go-to-right ul {
	font-size: 1.25em;
}

@media (max-width: 576px) {
	.go-to-right ul {
		padding-left: 1em;
		font-size: 0.8em;
	}
}

.go-to-right .head {
	font-size: 2.1875em;
	text-transform: capitalize;
	line-height: 1.379;
	margin: 0 0 1em 0;
}

@media (max-width: 991px) {
	.go-to-right .head {
		margin: 0 0 0.5em 0;
	}
}

.go-to-right .desc-wrap {
	padding: 0;
}

.go-to-right .detail-text {
	padding-right: 1.8em;
	margin: 0 0 2em 0;
	overflow-y: hidden;
	max-height: 21rem;
	transition: max-height 1s;
}

.go-to-right .detail-text.active {
	max-height: 100%;
}

.go-to-right .detail-text span p,
.go-to-right .detail-text span p span,
.go-to-right .detail-text span p h1,
.go-to-right .detail-text span p h2,
.go-to-right .detail-text span p strong {
	color: white !important;
}

.go-to-right .detail-text h3 {
	font-size: 1.5em;
	font-weight: 600;
}

.go-to-right .detail-text h4 {
	font-weight: 600;
}

.go-to-right .anchor-wrapper .arrow-anchor .img-box svg {
	transform: rotate(-270deg);
	transition: 0.25s;
}

.go-to-right .anchor-wrapper .arrow-anchor:hover .img-box svg {
	transform: rotate(90deg);
	transition: 0.25s;
}

.detail-text::-webkit-scrollbar {
	width: 5px;
}

.detail-text::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #fff;
	border-radius: 10px;
}

.detail-text::-webkit-scrollbar-thumb {
	background: #fff;
	border-radius: 10px;
}

.detail-text::-webkit-scrollbar-thumb:hover {
	background: #b30000;
}

.digital-recipe__row {
	padding: 3em;
	background: #000;
}

.digital-recipe .ToolSection_tools__list {
	width: 50%;
	padding: 0;
	list-style: none;
}

.whatsapp-section {
	position: relative;
}

.whatsapp-section::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	/* background: url(/assets/svg/bubble-chat.svg) no-repeat; */
	max-width: 11.6875em;
	max-height: 16.25em;
	height: 100%;
	width: 100%;
	background-size: cover;
}

@media (max-width: 991px) {
	.whatsapp-section::before {
		display: none;
	}
}

.whatsapp-section::after {
	content: "";
	position: absolute;
	right: 0;
	bottom: 0;
	/* background: url(/assets/svg/chat.svg) no-repeat; */
	max-width: 11.6875em;
	max-height: 15.25em;
	height: 100%;
	width: 100%;
	background-size: cover;
}

@media (max-width: 991px) {
	.whatsapp-section::after {
		display: none;
	}
}

.whatsapp-section .whatsapp-row {
	position: relative;
	padding: 5em 0;
}

@media (max-width: 991px) {
	.whatsapp-section .whatsapp-row {
		padding: 0;
	}
}

.whatsapp-section .whatsapp-row .hero-main-subheading {
	margin-bottom: 2.65em;
}

.whatsapp-section .whatsapp-row::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: #000;
	z-index: -1;
}

@media (max-width: 991px) {
	.whatsapp-section .whatsapp-row::after {
		top: unset;
		bottom: 0;
		width: 100%;
		height: 50%;
	}
}

.whatsapp-section .whatsapp-row::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
	background: #033780;
	z-index: -1;
}

@media (max-width: 991px) {
	.whatsapp-section .whatsapp-row::before {
		width: 100%;
		height: 50%;
	}
}

.whatsapp-section .whatsapp-row .left-col,
.whatsapp-section .whatsapp-row .right-col {
	padding: 0 2em;
}

@media (max-width: 991px) {

	.whatsapp-section .whatsapp-row .left-col,
	.whatsapp-section .whatsapp-row .right-col {
		padding: 4em 0;
	}
}



.future-tech {
	background: #0a0014;
}

.future-tech .hero-main {
	text-align: center;
	margin: 0 auto;
}

.future-tech .hero-main-section-txt {
	margin: 0 0 4.25em 0;
	font-size: 1.2em;
}

@media (max-width: 576px) {
	.future-tech .hero-main-section-txt {
		margin: 0 0 2em 0;
	}
}

.future-tech-logos {
	width: 100%;
}

.future-tech-logos:nth-child(odd) {
	margin-left: -80px;
}

.future-tech-logos ul {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
}

@media (max-width: 576px) {
	.future-tech-logos ul {
		flex-wrap: nowrap;
	}
}

.future-tech-logos ul li,
.future-tech-logos .logo-bg {
	background: #fff;
	width: 6em !important;
	height: 6em;
	padding: 1.5em;
	border-radius: 25%;
	margin: 0.75em;
}

@media (max-width: 576px) {

	.future-tech-logos ul li,
	.future-tech-logos .logo-bg {
		animation: scroll 40s linear infinite;
		display: flex;
		align-self: center;
		justify-content: center;
	}
}

.future-tech-logos ul li svg {
	width: 100%;
	height: 100%;
}

.future-tech-logos ul li img,
.future-tech-logos .logo-bg img {
	width: 100%;
	height: 100%;
}

@media (max-width: 576px) {

	.future-tech-logos ul li img,
	.future-tech-logos .logo-bg img {
		width: 50px;
		height: auto;
	}
}




.our-services {
	pointer-events: none;
}

.about-services-slider {
	height: 29.375em;
}

.about-services-slider .swiper-wrapper {
	-webkit-transition-timing-function: linear !important;
	-o-transition-timing-function: linear !important;
	transition-timing-function: linear !important;
}

.about-services-slider .swiper-slide {
	margin: 0 1.4375em;
}

.about-services-slider .strategy-wrapper {
	width: 19.875em;
}

.about-services-slider .marketing-management {
	width: 19.875em;
}

.about-services-slider .creativity-design {
	width: 30.125em;
}

.about-services-slider .consultancy-technology {
	width: 20.4375em;
}

.about-services-slider-card {
	position: relative;
	height: 100%;
	padding: 1.625em 0 0 1.625em;
	overflow: hidden;
}

.about-services-slider-card .img-box {
	position: absolute;
	bottom: 0;
	right: 0;
}

.about-services-slider-card.strategy {
	background: #ffdde4;
	width: 19.875em !important;
	height: 30.5em;
}

.about-services-slider-card.strategy .img-box {
	width: 19.25em;
	height: 12.5em;
}

.about-services-slider-card.marketing {
	background: #ffe0d0;
	width: 19.875em !important;
	height: 14.875em;
	margin: 0 0 1.5em 0;
}

.about-services-slider-card.marketing .img-box {
	width: 16.4375em;
	height: 14.875em;
}

.about-services-slider-card.management {
	background: #d7fdff;
	width: 19.875em !important;
	height: 14em;
}

.about-services-slider-card.management .img-box {
	width: 9.1875em;
	height: 9em;
}

.about-services-slider-card.creativity {
	background: #ffd4cd;
	width: 20.125em !important;
	height: 13.5em;
	margin: 0 0 1.5em 0;
}

.about-services-slider-card.creativity .img-box {
	width: 13.1875em;
	height: 13.5em;
	bottom: auto;
	top: 0;
}

.about-services-slider-card.design {
	background: #ffd3ee;
	width: 20.125em !important;
	height: 15.5em;
}

.about-services-slider-card.design .img-box {
	width: 22.1875em;
	height: 15.5em;
}

.about-services-slider-card.consultancy {
	width: 20.4375em !important;
	height: 13.5em;
	margin: 0 0 1.5em 0;
}

.about-services-slider-card.consultancy .img-box {
	width: 100%;
	height: 100%;
	z-index: -1;
}

.about-services-slider-card.consultancy .img-box img {
	height: 100%;
	object-fit: cover;
}

.about-services-slider-card.technology {
	width: 20.4375em !important;
	height: 15.5em;
}

.about-services-slider-card.technology .img-box {
	width: 100%;
	height: 100%;
	z-index: -1;
}

.about-services-slider-card.technology .img-box img {
	height: 100%;
	object-fit: cover;
}

.experties {
	padding: 0 0 10em 0;
}

@media (max-width: 576px) {
	.experties {
		padding: 3em 0;
	}
}

.experties .twocol-row {
	justify-content: space-between;
}

.experties__tabs-wrapper {
	padding: 0 10em 0 10em;
}

@media (max-width: 576px) {
	.experties__tabs-wrapper {
		padding: 0 2em;
	}
}







.experties__tabs-wrapper--detail-tab .img-box svg mask,
.experties__tabs-wrapper--detail-tab .img-box svg path {
	fill: #b90000;
}

.experties__tabs-wrapper--detail-tab .img-box svg rect {
	stroke: #b90000;
}

.experties__tabs-wrapper--detail-tab.active {
	display: block;
}

.experties__tabs-wrapper ul {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0;
	margin-left: -1.875em;
	margin-right: -1.875em;
	width: calc(100% + 3.75em);
}

@media (max-width: 1024px) {
	.experties__tabs-wrapper ul {
		width: 100%;
		margin: 0;
	}
}

@media (max-width: 576px) {
	.experties__tabs-wrapper ul {
		margin: 0;
		width: 100%;
	}
}

.experties__tabs-wrapper ul li {
	width: 33.33%;
	padding: 0 1.875em 1.875em 0;
}

@media (max-width: 576px) {
	.experties__tabs-wrapper ul li {
		width: 50%;
		padding: 0 0.5em 0.5em 0;
	}
}

.experties__tabs-wrapper--small-cards {
	padding: 1.75em 2.1875em;
}

@media (max-width: 576px) {
	.experties__tabs-wrapper--small-cards {
		padding: 1.25em;
	}
}



.experties__tabs-wrapper--small-cards.active {
	background: #0070c6;
	padding: 1.75em 2.1875em;
}

@media (max-width: 576px) {
	.experties__tabs-wrapper--small-cards.active {
		padding: 1.25em;
	}
}

.about-tech ul {
	flex-wrap: nowrap;
}

.about-tech ul li,
.about-tech .logo-bg {
	animation: scroll 70s linear infinite;
	display: flex !important;
	align-self: center;
	justify-content: center;
}

.about-tech ul li img,
.about-tech .logo-bg img {
	width: 50px;
	height: auto;
}



.our-culture {
	position: relative;
}

.our-culture-leftside {
	position: relative;
	width: 45%;
	cursor: pointer;
}

@media (max-width: 991px) {
	.our-culture-leftside {
		width: 100%;
	}
}

.our-culture-leftside .img-box {
	height: 54.125em;
}

@media (max-width: 991px) {
	.our-culture-leftside .img-box {
		height: 40em;
	}
}

@media (max-width: 576px) {
	.our-culture-leftside .img-box {
		height: 20em;
	}
}

.our-culture-leftside .img-box img {
	height: 100%;
	object-fit: cover;
}

.our-culture-leftside .line-heading {
	position: absolute;
	z-index: 5;
	top: 5em;
}

.our-culture-leftside .play-btn {
	position: absolute;
	width: 5.0625em;
	height: 5.0625em;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.our-culture-rightside {
	width: 55%;
}

@media (max-width: 991px) {
	.our-culture-rightside {
		width: 100%;
	}
}

.our-culture-rightside-wrap {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.our-culture-rightside .img-box {
	width: 50%;
	height: 27.0625em;
}

@media (max-width: 991px) {
	.our-culture-rightside .img-box {
		height: 20em;
	}
}

@media (max-width: 576px) {
	.our-culture-rightside .img-box {
		height: 15em;
	}
}

.our-culture-rightside .img-box img {
	height: 100%;
	object-fit: cover;
}

.happyEmplyees {
	position: relative;
	padding: 8.125em 0;
}

@media (max-width: 991px) {
	.happyEmplyees {
		padding: 3em 0;
	}
}

.happyEmplyees__leftsec {
	width: 60%;
	padding: 0 5em 0 0;
}

@media (max-width: 991px) {
	.happyEmplyees__leftsec {
		width: 100%;
		padding: 0;
	}
}

.happyEmplyees__leftsec .img-box {
	padding: 3.75em 0 0 0;
}

@media (max-width: 991px) {
	.happyEmplyees__leftsec .img-box {
		padding: 2em 0;
	}
}

.happyEmplyees__rightsec {
	position: relative;
	width: 40%;
	padding: 0 3.75em;
}

@media (max-width: 991px) {
	.happyEmplyees__rightsec {
		width: 100%;
	}
}



.happyEmplyees .generic-navigation-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.happyEmplyees .generic-navigation-wrapper .swiper-button-next,
.happyEmplyees .generic-navigation-wrapper .swiper-button-prev {
	top: 65%;
}

.hiddenStyle {
	display: none;
}

.apply {
	position: relative;
	color: #fff;
	z-index: 1;
	padding: 0.2em;
}

.apply::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #363636;
	z-index: -1;
	transition: width 0.5s ease-in-out;
}

@media (max-width: 991px) {
	.apply::before {
		display: none;
	}
}

.apply::after {
	position: absolute;
	content: "";
	bottom: 0;
	left: 0;
	width: 0%;
	height: 3px;
	border-radius: 10px;
	background: #fff;
	z-index: -1;
	transition: width 0.6s ease-in-out;
	animation: mymove 1s infinite;
}

@keyframes mymove {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

.apply:hover::before {
	width: 0;
}

.case-studies {
	position: relative;
}

.case-studies__tabs-wrapper {
	list-style: none;
	padding: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.case-studies__tabs-wrapper .button {
	transition: all 0.2s ease-in-out;
	-webkit-appearance: none;
	border-radius: 1.875em;
	font-family: inherit;
	appearance: none;
	background: 0 0;
	font-weight: 500;
	font-size: 1em;
	cursor: pointer;
	padding: 1.125em 3.125em;
	outline: 0;
	border: none;
	color: #363636;
	background: #f2f2f2;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	margin: 0 0.625em 0 0;
	display: flex;
	align-items: center;
}

.case-studies__tabs-wrapper .button.active,
.case-studies__tabs-wrapper .button:hover {
	background: #033780;
	color: #fff;
}

.case-studies__tabs-wrapper .button.mob-first-active {
	background: #f2f2f2;
	color: #363636;
}

.case-studies .grid-layout {
	margin-left: -3.375em;
	margin-right: -3.375em;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 4.375em 0 0;
}

@media (max-width: 991px) {
	.case-studies .grid-layout {
		margin-left: -1em;
		margin-right: -1em;
	}
}

@media (max-width: 576px) {
	.case-studies .grid-layout {
		margin: 0;
		grid-template-columns: repeat(1, 1fr);
		padding: 2.375em 0 0;
	}
}

.case-studies__card-wrapper {
	padding: 0 3.375em;
}

.case-studies__card-wrapper:nth-child(even) {
	margin: 5em 0 0;
}

@media (max-width: 991px) {
	.case-studies__card-wrapper:nth-child(even) {
		margin: 3em 0 0;
	}
}

@media (max-width: 576px) {
	.case-studies__card-wrapper:nth-child(even) {
		margin: 0;
	}
}

@media (max-width: 991px) {
	.case-studies__card-wrapper {
		padding: 0 1em;
	}
}

@media (max-width: 576px) {
	.case-studies__card-wrapper {
		padding: 0 0 1em 0;
	}
}

.case-studies__card-wrapper--card-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.625em 0 0 0;
}

@media (max-width: 991px) {
	.case-studies__card-wrapper--card-content {
		flex-wrap: wrap;
	}
}

.case-studies__card-wrapper--card-content .hero-main-subheading {
	font-size: 1.25em;
	text-transform: capitalize;
}

@media (max-width: 576px) {
	.case-studies__card-wrapper--card-content .anchor-wrapper a span {
		font-size: 0.75em;
	}
}

.case-studies .three-row-layout {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 576px) {
	.case-studies .three-row-layout {
		grid-template-columns: repeat(1, 1fr);
	}
}

.case-studies .three-row-layout .case-studies__card-wrapper {
	padding: 0 0.9375em 0;
	margin: 0;
}

@media (max-width: 576px) {
	.case-studies .three-row-layout .case-studies__card-wrapper {
		padding: 0;
	}
}

.case-studies .three-row-layout .case-studies__card-wrapper:nth-child(2) {
	padding-top: 5em;
}

@media (max-width: 991px) {
	.case-studies .three-row-layout .case-studies__card-wrapper:nth-child(2) {
		padding-top: 3em;
	}
}

@media (max-width: 576px) {
	.case-studies .three-row-layout .case-studies__card-wrapper:nth-child(2) {
		padding: 0;
	}
}

.case-studies .three-row-layout .case-studies__card-wrapper:nth-child(3n + 6) {
	margin-top: -5em;
}

@media (max-width: 576px) {
	.case-studies .three-row-layout .case-studies__card-wrapper:nth-child(3n + 6) {
		margin: 0;
	}
}

.case-studies .three-row-layout .case-studies__card-wrapper:nth-child(3n + 4) {
	margin-top: -5em;
}

@media (max-width: 576px) {
	.case-studies .three-row-layout .case-studies__card-wrapper:nth-child(3n + 4) {
		margin: 0;
	}
}

.case-studies .three-row-layout .case-studies__card-wrapper--card-content {
	padding: 1em 0 4.375em 0;
}

@media (max-width: 576px) {
	.case-studies .three-row-layout .case-studies__card-wrapper--card-content {
		padding: 1em 0 1em 0;
	}
}

.case-studies .hide {
	display: none;
}

.case-studies .child_cat_id {
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
}

.case-studies .child_cat_id .button {
	transition: all 0.2s ease-in-out;
	-webkit-appearance: none;
	border-radius: 0.2em;
	font-family: inherit;
	appearance: none;
	background: 0 0;
	font-weight: 500;
	font-size: 1em;
	cursor: pointer;
	padding: 1.125em 3.125em;
	outline: 0;
	border: none;
	color: #363636;
	background: #f2f2f2;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	margin: 0 1em;
	display: flex;
	align-items: center;
}

.case-studies .child_cat_id .button.active,
.case-studies .child_cat_id .button:hover {
	background: #033780;
	color: #fff;
}

.casestudy-hero .hero-main-heading {
	text-transform: uppercase;
}

.case-study-hero {
	background: 0 0;
	height: 31em;
	padding: 12.15em 0 0 0;
}

@media (max-width: 991px) {
	.case-study-hero {
		height: auto;
	}
}

@media (max-width: 576px) {
	.case-study-hero {
		padding: 5em 0 0 0;
	}
}

/* .case-study-hero:before {
	background: url(../Images/inner-banner.svg) no-repeat;
} */

.case-study-hero .container,
.case-study-hero .twocol-row {
	height: 100%;
	align-items: center;
}

.case-study-hero__left-side {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
}

.case-study-hero__right-side {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.rmal-hospitality {
	position: relative;
}

.rmal-hospitality .rmal-hospilaty-wrapper {
	padding: 5.625em 0 0 0;
}

@media (max-width: 576px) {
	.rmal-hospitality .rmal-hospilaty-wrapper {
		padding: 3em 0 0 0;
	}
}

.rmal-hospitality .hero-main-subheading {
	background: linear-gradient(264.19deg, #fff 14.71%, #fff 70.43%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

@media (max-width: 576px) {
	.rmal-hospitality .hero-main-subheading {
		padding: 0 1em;
		font-size: 1em;
	}
}

.rmal-hospitality .hero-main-subheading.gradient {
	background: linear-gradient(264.19deg, #6a5734 14.71%, #bb9145 70.43%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.rmal-hospitality .img-box img {
	display: block;
}

.rmal-hospitality .top-content {
	position: absolute;
	top: 5.5em;
	left: 50%;
	transform: translate(-50%, 0);
}

@media (max-width: 576px) {
	.rmal-hospitality .top-content {
		top: 3.5em;
	}
}

.jino-section {
	background: 0 0;
}

.jino-section .anchor-wrapper .arrow-anchor span {
	color: #033780;
	text-transform: uppercase;
	font-weight: 700;
}

.jino-section .anchor-wrapper .arrow-anchor .img-box svg {
	transform: rotate(-45deg);
}

.jino-section .anchor-wrapper .arrow-anchor:hover .img-box svg {
	transform: rotate(0);
}

.rmal-largeSection .img-box img {
	display: block;
}

.research-design {
	padding: 8.75em 0;
}

@media (max-width: 991px) {
	.research-design {
		padding: 3em 0;
	}
}


.our-brands {
	background: #e4e4e4;
	margin-bottom: 5.75em;
}

.our-brands__right-img {
	padding: 0 0 0 4rem;
}

.our-brands img {
	height: auto !important;
	width: 100%;
	object-fit: contain !important;
	padding: 0 0 0 6rem;
}

@media (max-width: 576px) {
	.our-brands {
		margin-bottom: 2em;
	}
}

.our-brands__content {
	height: 100%;
	padding: 6em;
}

@media (max-width: 991px) {
	.our-brands__content {
		padding: 3em 0 0 0;
	}
}

@media (max-width: 576px) {
	.our-brands__content {
		padding: 2em;
	}
}

.our-brands__content .generic--head p:last-child {
	margin-bottom: 0;
}



@media (max-width: 1024px) {
	.our-brands__right-img {
		padding: 10em 10em 1em 4em;
	}
}

@media (max-width: 991px) {
	.our-brands__right-img {
		padding: 3em;
	}
}

@media (max-width: 991px) {
	.our-brands__right-img .img-box {
		width: 50%;
		margin: 0 auto;
	}
}

@media (max-width: 576px) {
	.our-brands__right-img .img-box {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.our-brands .first-col .img-box {
		width: 50%;
		margin: 0 auto;
	}
}

#numberSlides {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 9999;
}

.about-casestudy {
	margin-top: 4em;
	padding-top: 4em;
	background-color: #0070c6;
}

.about-casestudy .generic--head__sec-para {
	color: white;
	font-weight: 600;
	font-size: 1.2rem;
	margin: 4rem;
}

@media (max-width: 991px) {
	.about-casestudy {
		padding-top: 3em;
	}
}

@media (max-width: 576px) {
	.about-casestudy {
		margin: 0;
	}
}

.about-casestudy .left-col {
	padding-right: 1.9205em;
}

@media (max-width: 991px) {
	.about-casestudy .left-col {
		padding-right: 1em;
	}
}

.about-casestudy .right-col {
	padding-left: 1.9205em;
}

@media (max-width: 991px) {
	.about-casestudy .right-col {
		padding-left: 1em;
	}
}

@media (max-width: 576px) {
	.about-casestudy .right-col {
		padding: 0;
		margin-top: 3em;
	}

	.about-casestudy .right-col .img-box {
		display: none;
	}
}

.about-casestudy .right-col .hero-main {
	width: 30.9375em;
	margin: 6.8761em auto 0 auto;
}

@media (max-width: 1024px) {
	.about-casestudy .right-col .hero-main {
		width: 100%;
		margin: 3em auto 0 auto;
	}
}

@media (max-width: 576px) {
	.about-casestudy .right-col .hero-main {
		margin: 0 auto;
	}
}

@media (max-width: 991px) {
	.about-casestudy .col-50 {
		width: 50%;
	}
}

@media (max-width: 576px) {
	.about-casestudy .col-50 {
		width: 100%;
	}
}

.grid-layout .col-left {
	display: flex;
}

@media (max-width: 991px) {
	.grid-layout .col-left {
		width: 60%;
	}
}

@media (max-width: 576px) {
	.grid-layout .col-left {
		width: 100%;
		margin-bottom: 0;
	}
}

.grid-layout .col-right {
	display: flex;
}

@media (max-width: 991px) {
	.grid-layout .col-right {
		width: 40%;
	}
}

@media (max-width: 576px) {
	.grid-layout .col-right {
		width: 100%;
	}
}

.grid-layout .leftimg {
	width: 38rem;
}

.grid-layout .midimg {
	width: 16rem;
	padding-left: 1.8rem;
}

@media (max-width: 576px) {
	.grid-layout .midimg {
		width: inherit;
		padding-left: 0.8rem;
	}
}

.grid-layout .rightimg {
	padding-left: 12rem;
}

@media (max-width: 1024px) {
	.grid-layout .rightimg {
		padding-left: 1.8rem;
	}
}

@media (max-width: 576px) {
	.grid-layout .rightimg {
		padding: 0;
		display: none;
	}
}

.grid-layout .img-box {
	transition: 0.5s;
	overflow: hidden;
}

.grid-layout .img-box img {
	transition: 0.5s;
}

.grid-layout .img-box:hover img {
	transform: scale(1.1);
}

.grid-layout-new .img-box {
	height: 100%;
}

.grid-layout-new .img-box img {
	height: 100%;
}

.grid-layout-new .hero-main {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2em;
	background: #033780;
}

.grid-layout-new .hero-main h2 {
	font-size: 3.8em;
	margin: 0 0 0.2em 0;
}

@media (max-width: 576px) {
	.grid-layout-new .hero-main h2 {
		font-size: 1.75em;
	}
}

@media (max-width: 991px) {
	.grid-layout-new .first-col {
		width: 30%;
	}
}

@media (max-width: 576px) {
	.grid-layout-new .first-col {
		width: 40%;
	}
}

@media (max-width: 991px) {
	.grid-layout-new .mid-col {
		width: 40%;
	}
}

@media (max-width: 576px) {
	.grid-layout-new .mid-col {
		width: 60%;
	}
}

@media (max-width: 991px) {
	.grid-layout-new .last-col {
		width: 30%;
	}
}

@media (max-width: 576px) {
	.grid-layout-new .last-col {
		display: none;
	}
}

.grid-layout-new .img-box {
	overflow: hidden;
}

.grid-layout-new .img-box img {
	transition: 0.5s;
}

.grid-layout-new .img-box:hover img {
	transform: scale(1.1);
}

@media (max-width: 576px) {
	.casestudy-strattegy .services-anchor span {
		font-size: 1em;
	}
}

.casestudy-form {
	position: relative;
	/* background: url(/assets/images/case-studies-details/form.jpg); */
	z-index: 1;
}

.casestudy-form::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(226.92deg,
			rgba(227, 32, 74, 0.84) 15.33%,
			rgba(0, 0, 0, 0.014) 55.21%);
	transform: matrix(-1, 0, 0, 1, 0, 0);
	opacity: 0.7;
	z-index: -1;
}

.casestudy-form .case-studies-form-wrapper .services-hero-form {
	width: 100%;
}

@media (min-width: 1100px) {
	.casestudy-form .case-studies-form-wrapper .services-hero-form {
		padding: 0 0 0 9.0625em;
	}
}

.casestudy-form .case-studies-form-wrapper .services-hero-form .main-form {
	background: #fff;
	padding: 3.75em 4.0625em;
}

@media (max-width: 576px) {
	.casestudy-form .case-studies-form-wrapper .services-hero-form .main-form {
		padding: 2em;
	}
}

@media (max-width: 1024px) {
	.casestudy-form .col-50 {
		width: 50%;
	}
}

.count-banner {
	position: relative;
}

@media (min-width: 1024px) {
	.count-banner {
		padding-bottom: 0;
	}
}

.count-banner .count-logo {
	max-width: 10em;
}

@media (max-width: 991px) {
	.count-banner .count-logo {
		margin: auto auto 2em auto;
	}
}

.count-banner .count-logo svg {
	display: flex;
	width: auto;
	height: 100%;
}

.count-banner .counter-wrapper-head {
	display: flex;
	flex-wrap: wrap;
}

.count-banner .col-50 {
	width: 50%;
}

.count-banner .counter-wrapper {
	width: 50%;
	padding-right: 4em;
}

@media (max-width: 1024px) {
	.count-banner .counter-wrapper {
		text-align: center;
	}
}

@media (max-width: 576px) {
	.count-banner .counter-wrapper {
		padding-right: 0.2em;
	}
}

.count-banner .counter-wrapper .xl-font {
	margin-bottom: 0.38em;
}

.count-banner .counter-wrapper p {
	position: relative;
}

.count-banner .counter-wrapper p::after {
	content: "";
	position: absolute;
	top: -10px;
	left: 0;
	background: #fff;
	height: 2px;
	width: 7.88em;
}

@media (max-width: 1024px) {
	.count-banner .counter-wrapper p::after {
		left: 50%;
		transform: translateX(-50%);
	}
}

.count-banner .left-col {
	padding: 0 4.6875em 0 3.125em;
}


.career-detail-form {
	background: rgba(255, 255, 255, 0.54);
	backdrop-filter: blur(4.5px);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	height: 100vh;
	display: none;
	justify-content: center;
	align-items: center;
}

.career-detail-form .form-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.career-detail-form .form-wrapper {
	background: #fff;
	box-shadow: 0 4px 12px 2px rgba(0, 0, 0, 0.1);
	padding: 2.6875em 5.1875em;
	width: 52.3125em;
	margin: 0 auto;
	position: relative;
}

.career-detail-form .sr-only {
	position: absolute;
	margin: -1px;
	width: 1px;
	height: 1px;
	border: 0;
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
}

.career-detail-form .btn {
	display: inline-block;
	padding: 0.5rem 1rem;
	border-radius: 0.25rem;
	color: #033780;
	border: 1px dashed #033780;
	box-shadow: 0 4px 13px 3px rgba(0, 0, 0, 0.06);
	border-radius: 300px;
	cursor: pointer;
}

@media (max-width: 576px) {
	.career-detail-form .btn {
		margin-bottom: 1em;
		text-align: center;
		width: 100%;
	}
}

.career-detail-form [type="file"]:focus + .btn {
	outline: 0;
}

.career-detail-form .upload .input-container {
	margin: 0;
	align-items: flex-end;
}

.career-detail-form .input-container {
	justify-content: center;
}

.career-detail-form .input-container .placeholder span {
	color: #033780;
}

.career-detail-form .cross-times {
	width: 1em;
	position: absolute;
	right: 5.3em;
	top: 3.17em;
	cursor: pointer;
}

.career-detail-form .col-50 {
	padding-right: 2.8125em;
}

.career-detail-form #getquoteform button {
	border-radius: 18.75em;
	border: none;
}

#resume-error {
	width: 100%;
	text-align: center;
}

.upload .error {
	width: 100%;
	text-align: center;
}

.upload .input-container {
	align-items: center !important;
}

.progress-section .left-col {
	width: 36%;
}

.progress-section .right-col {
	width: 64%;
	padding-left: 3.875em;
}

@media (max-width: 1399px) {
	.progress-section .right-col {
		padding: 0;
	}
}

.progress-section .progress-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.progress-section .progress {
	width: 50%;
	display: flex;
	align-content: center;
	margin-bottom: 3.75em;
}

@media (max-width: 375px) {
	.progress-section .progress {
		width: 100%;
	}
}

.progress-section .progress .progress-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 1.875em;
}

.progress-section .progress .progress-content h6 {
	margin: 0;
	color: #809fb8;
	font-weight: 600;
}

.progress-section .block {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 180px;
	height: 180px;
	border-radius: 50%;
}

.progress-section .block .box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.progress-section .block .box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: calc(100% - 50px);
	height: calc(100% - 50px);
	border-radius: 50%;
}

.progress-section .block .box::before {
	position: absolute;
	content: "";
	width: calc(100% + 28px);
	height: calc(100% + 28px);
	border-radius: 50%;
	border: 3px solid #d9e1e7;
}

.progress-section .block .box .number {
	margin: 0;
	font-size: 1.5em;
	font-weight: 700;
	color: #000;
}

.progress-section .block .svg {
	position: absolute;
	width: 100%;
	height: 100%;
	fill: none;
	transform: rotate(-90deg);
}

.progress-section .block .circle {
	stroke-width: 14px;
	stroke-dasharray: 503;
	stroke-dashoffset: 503;
	animation-duration: 2s;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
	transition: 2s stroke-dashoffset;
}





.status-box {
	min-height: 10em;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

@media (max-width: 767px) {
	.status-box {
		padding: 2em 0;
	}
}

.status-box ul {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	justify-content: center;
}

.status-box ul:hover .no-of-social-share {
	display: block;
	visibility: visible;
	opacity: 1;
}

@media (max-width: 767px) {
	.status-box ul {
		justify-content: center;
		margin: 0;
		padding: 0;
	}
}

@media (max-width: 992px) {
	.status-box li {
		margin-bottom: 1.5em;
	}
}

.status-box a {
	border-radius: 100px;
	border: 2px solid #033780;
	padding: 0.5em 1em;
	margin-right: 0.2em;
	color: #033780;
	transition: 0.5s;
	display: flex;
}

.status-box a:hover {
	text-decoration: none;
	opacity: 0.7;
}

.no-of-social-share {
	display: flex;
	width: 100%;
	height: 100%;
	visibility: hidden;
	opacity: 0;
	transition: 0.8s;
	padding-top: 1em;
}

.no-of-social-share ul {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	width: 100%;
}

.no-of-social-share li {
	width: 3em;
	height: 3em;
}

.no-of-social-share a {
	border: none;
	width: 100%;
	height: 100%;
	display: block;
	padding: 0.7em;
}

.no-of-social-share img {
	width: 100%;
	height: 100%;
}









.indicators-cont {
	display: flex;
	width: 100%;
	padding: 1.5em 0;
	border: solid 1px #e7e7e7;
	border-left: 0;
	margin: 0.75em 0 0 0;
	border-right: 0;
	justify-content: space-between;
}

.indicators-cont a {
	text-transform: uppercase;
	color: #033780;
	font-size: 1.2em;
	transition: 0.5s;
	text-decoration: none;
}

.indicators-cont a:hover {
	font-weight: 600;
	text-decoration: none;
	letter-spacing: 1px;
}

/* !Services and Service Detail from Pages */





.go-to-wrap {
	position: relative;
	z-index: 1;
}

/* !Arrow Anchor of Card */



/* !Utility Classes */
.img-box {
	width: 100%;
}

/* !Services and Services Details */
.text-gradient {
	background: linear-gradient(90deg, #033780, #fff, #033780, #fff);
	background-size: 300% 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
	animation: bgMove 3s linear infinite reverse;
	mix-blend-mode: normal;
}

.key-highlight-card .count {
	color: #033780;
	font-size: 5.25em;
	font-weight: 600;
}




/* !Media Queries */

/* 767px */

@media (max-width: 767px) {
	body {
		font-size: 14px;
	}



	.footer__top--qlinks-col:nth-child(1) {
		padding: 0 3em;
	}

	.footer__top--qlinks-col:nth-child(1) .left-descrip h2 {
		font-size: 1.75em;
	}

	.footer__top--qlinks-col:nth-child(2) {
		padding: 0 3em;
	}

	.home-hero {
		height: 70vh;
	}

	.home-hero__hero-caption--container {
		padding: 0 3em;
	}

	.badge .badge-wrapper .badge-card .badge-card-wrapper {
		margin: 0;
	}

	.badge .badge-wrapper .badge-card .badge-card-wrapper .badge-image {
		width: 12em;
	}

	.badge .badge-wrapper .badge-card::before {
		width: 88%;
		right: 2px;
	}

	.services {
		background: #fff;
	}

	.services__items,
	.services__wrap {
		height: 60vh;
	}

	.services .line-heading {
		margin: 0;
		padding: 2em 0;
		top: 0;
	}

	.service-item__head {
		width: 100%;
		margin-bottom: 4em;
		padding: 0 1em;
		text-align: center;
	}

	.service-item__img {
		width: 100%;
	}

	.services-thumb-slider-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		margin-bottom: 4em;
		padding: 10em 0 0 0;
		text-align: center;
	}

	.services-thumb-slider-wrapper .services-thumb-slider {
		height: 100%;
	}

	.services-thumb-slider-wrapper .services-thumb-slider .swiper-wrapper {
		display: grid;
	}

	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide .service-thumb__head {
		opacity: 1;
	}

	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-next .service-thumb__head,
	.services-thumb-slider-wrapper .services-thumb-slider .swiper-slide.swiper-slide-prev .service-thumb__head {
		opacity: 1;
	}

	.translateY-bottom {
		transform: translate(0, 0);
	}

	.home-hero__hero-banner--container {
		padding: 0 3em;
	}

	.hero--section__social-linkWrapper {
		display: none;
	}

	.collabCompanies__title {
		font-size: 1.75em;
	}



	.projectForm {
		padding: 0 3em;
	}

	.services-main-container {
		padding: 0 3em;
	}

	.inner-hero-heading .hero-main-heading {
		font-size: 3em;
	}

	.services-list-card {
		width: 50%;
	}

	.case-studies__tabs-wrapper {
		position: absolute;
		top: 0;
		background: #fff;
		width: 100%;
		z-index: 99;
		padding: 1em;
		left: 0;
		right: 0;
		box-shadow: 0 13px 8px rgba(0, 0, 0, 0.03);
	}

	.key-highlight-card .count {
		font-size: 1.5em;
	}

	.ourProcess__card .card-head {
		font-size: 1.625em;
	}

	.serviceBrands__tabBoxWrapper {
		padding: 0 3em;
	}

	.go-to-right .head {
		font-size: 1.625em;
	}

	.portfolio {
		padding: 7em 0 3em 0;
	}

	.currentOpenings .detail-wrapper {
		margin: 0 0 1em 0;
		flex-wrap: wrap;
	}

	.our-culture-leftside .line-heading {
		position: absolute;
		margin: 0;
		padding: 2em 0;
		top: 0;
	}

	.case-studies__tabs-wrapper .button {
		transition: none;
		flex: 0 0 100%;
		justify-content: space-between;
		position: relative;
	}

	.case-studies__tabs-wrapper .button.active {
		order: -1;
		background: #033780 !important;
		color: #fff !important;
	}

	.case-studies__tabs-wrapper .button.active::after {
		content: "";
		margin-left: 1rem;
		display: inline-block;
		margin-bottom: 3px;
		width: 0;
		height: 0;
		border: solid #f2f2f2;
		border-width: 0 2px 2px 0;
		padding: 3px;
		transform: rotate(45deg);
		transition: 0.5s;
		position: absolute;
		right: 2em;
	}

	.case-studies__tabs-wrapper .button:first-child {
		background: #033780;
		color: #fff;
	}

	.case-studies__tabs-wrapper .button:first-child::after {
		content: "";
		margin-left: 1rem;
		display: inline-block;
		margin-bottom: 3px;
		width: 0;
		height: 0;
		border: solid #f2f2f2;
		border-width: 0 2px 2px 0;
		padding: 3px;
		transform: rotate(45deg);
		transition: 0.5s;
		position: absolute;
		right: 2em;
	}

	.case-studies__tabs-wrapper .button:not(:first-child) {
		display: none;
	}

	.case-studies .child_cat_id .button {
		transition: none;
		flex: 0 0 100%;
		justify-content: space-between;
		position: relative;
	}

	.case-studies .child_cat_id .button.active {
		order: -1;
		background: #033780 !important;
		color: #fff !important;
	}

	.casestudy-form .case-studies-form-wrapper .services-hero-form {
		margin-top: 1em;
	}

	.casestudy-form .col-50 {
		width: 100%;
	}

	.count-banner .col-50 {
		width: 100%;
	}

	.count-banner .left-col {
		display: none;
	}

	.count-banner .right-col {
		padding: 1em 0;
	}

	.job-description__card {
		flex-wrap: wrap;
		padding: 1em;
	}

	#submit-button-career {
		margin-top: 0.5em;
	}

	.progress-section .left-col {
		width: 100%;
	}

	.progress-section .right-col {
		width: 100%;
	}

	.filter .case-studies__tabs-wrapper {
		position: relative !important;
		top: unset !important;
		width: 100%;
		z-index: 99;
		padding: 1em;
		left: unset !important;
		right: unset !important;
	}

	.filter .case-studies__tabs-wrapper li.active {
		order: 0 !important;
	}

	.filter .case-studies__tabs-wrapper li {
		display: block !important;
	}

	.mob-none {
		display: none;
	}
}



/* 1024px */
@media (max-width: 1024px) {
	.blog-list .img-box {
		height: 9em;
	}
}

/* *1199px */
@media (max-width: 1199px) {
	body {
		font-size: 12px;
		overflow-x: hidden;
	}






	.footer__top--qlinks-col:nth-child(1) {
		padding: 0 2em;
	}

	.footer__top--qlinks-col:nth-child(1) .left-descrip h2 {
		font-size: 2.25em;
	}

	.footer__top--qlinks-col:nth-child(2) {
		padding: 0 2em;
	}

	.home-hero {
		height: 60vh;
	}

	.home-hero .head {
		font-size: 4.5em;
	}

	.ourWork__Slider .swiper-slide .anchor-wrapper {
		opacity: 1;
	}

	.collabCompanies__title {
		width: 100%;
		font-size: 2.25em;
	}



	.worth-read__Slide--date {
		opacity: 1;
	}

	.worth-read__Slide--img {
		min-height: auto;
	}

	.getSocial__card--descrip {
		padding: 5em 2em 2.1875em 2.1875em;
	}

	.getSocial__card--descrip p {
		font-size: 0.6em;
	}

	.cursor {
		display: none;
	}

	.cursor-follower {
		display: none;
	}

	.services-hero {
		height: auto;
	}

	.services-main-mainWrap::before {
		width: 0;
		background-image: none;
	}

	.services-main-wrap {
		padding: 6.25em 0 3em 0;
	}

	.inner-hero-heading .hero-main-heading {
		font-size: 2.625em;
	}

	.services-hero-form {
		padding: 0;
	}

	.services-hero .main-form {
		padding: 2.5em 2em;
	}

	.key-highlight-card .count {
		font-size: 3em;
	}

	.serviceDevelopment__galleryWrapper {
		padding: 0 3em;
	}

	.ourProcess__card .card-arrow {
		width: 7.5em;
	}

	.ourProcess__card .card-head {
		font-size: 1.875em;
	}

	.servicesBrandsSlider {
		min-height: 28em;
	}

	.servicesBrandsSlider .swiper-slide .slide-img {
		height: 28em;
	}

	.go-to-right {
		padding: 0 0 0 3em;
	}

	.go-to-right .head {
		font-size: 2em;
	}

	.about-second-hero {
		overflow: hidden;
	}

	.about-second-hero-cardWrap-card .hero-main-subheading {
		margin: 0 0 1em;
	}

	.future-tech {
		overflow: hidden;
	}

	.experties__tabs-wrapper {
		padding: 0 3em;
	}

	.experties__tabs-wrapper--detail-tab .img-box {
		margin: 0 auto 2em;
	}

	.slider-portfolio-left .img-box {
		height: 22em;
	}

	.currentOpenings .designation-wrapper {
		width: 100%;
		margin: 0 0 0.5em 0;
	}

	.case-studies__tabs-wrapper .button {
		margin: 0 0.625em 1em 0;
	}

	.case-studies .child_cat_id .button {
		margin: 0 0.625em 1em 0;
	}

	.research-design__slider .swiper-slide::before {
		width: 7.125em;
		background-size: cover;
		display: none;
	}

	.grid-layout-new .hero-main h2 {
		font-size: 2.5em;
	}

	.count-banner .counter-wrapper {
		padding-right: 1em;
	}

	.progress-section .progress {
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.progress-section .progress .progress-content {
		margin-top: 1em;
	}

	.generic-navigation-wrapper.large-btn .swiper-button-next,
	.generic-navigation-wrapper.large-btn .swiper-button-prev {
		width: 3em;
		height: 3em;
	}

	.generic-navigation-wrapper.white-bg .swiper-button-next,
	.generic-navigation-wrapper.white-bg .swiper-button-prev {
		width: 3em;
		height: 3em;
	}

	.p-lr-4 {
		padding: 2em;
	}
}

@media (max-width: 1400px) and (min-width: 1300px) and (min-height: 900px) {
	body {
		font-size: 11.485px;
	}
}


@media (min-width: 1024px) {
	.container.padding-right-0 {
		padding-right: 0;
	}
}

@media (min-width: 1024px) {
	.container.padding-0 {
		padding: 0;
	}
}

@media (max-width: 576px) {
	.home footer {
		padding: 3em 0 4em 0;
	}
}

@media (max-width: 576px) {
	.banner-box {
		padding: 0 !important;
	}
}

@media (max-width: 576px) {
	.banner-heading {
		font-size: 22px !important;
	}
}

@media (max-width: 991px) {

	.generic-navigation-wrapper.large-btn .swiper-button-next.swiper-button-disabled,
	.generic-navigation-wrapper.large-btn .swiper-button-prev.swiper-button-disabled {
		opacity: 1;
		visibility: visible;
	}
}



.dg-flotaing-icons .img-box {
	padding: 1em;
	width: 4em;
	height: 4em;
}

.main-hero-social-link {
	width: 3.2em;
	height: 3.2em;
}

.main-hero-social-link .img-box svg {
	margin-top: -0.4em;
}


.notfound_text {
	align-items: center;
	display: flex;
	flex-flow: column;
	height: 100vh;
	justify-content: center;
}

.notfound_text p {
	display: flex;
	justify-content: center;
	margin-bottom: 1.5rem;
	text-align: center;
	font-size: 2em;
	margin: 0;
	color: black;
	font-family: "Times New Roman", Times, serif;
}

.notfound_text h4 span {
	color: #033780;
	display: block;
	font-size: 200px;
	letter-spacing: -12px;
	font-weight: 800;
	font-family: "Times New Roman", Times, serif;
}

.notfound_text h4 {
	font-size: 4em;
	font-family: "Times New Roman", Times, serif;
	text-align: center;
	margin: 0;
	color: black;
	font-weight: 800;
}

.head-btn {
	margin-top: 1em;
	display: flex;
}

.head-btn a {
	background: #033780;
	border-radius: 300px;
	padding: 0.6em 1.4em;
	text-decoration: none;
	color: white;
	border: 1px solid #033780;
	transition: 0.5s;
	font-size: 1.8em;
	font-family: "Times New Roman", Times, serif;

}

.head-btn a:hover {
	color: #033780;
	background: transparent;
}

@media (max-width:576px) {
	.home-hero {
		height: 90vh
	}
}